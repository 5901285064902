export const basicRegistrationFormFields = [
  {
    name: "fullName",
    label: "Full Name",
    fieldType: "input",
    placeHolder: "Enter Full Name",
    rules: [
      {
        required: true,
        message: "Please input your Full Name!",
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    placeHolder: "Enter Email",
    feedBack: true,
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail!",
      },
      {
        required: true,
        message: "Please input your E-mail!",
      },
    ],
    fieldType: "input",
  },
  {
    name: "phoneNumber",
    label: "Mobile Number",
    placeHolder: "Enter Mobile Number",
    rules: [
      {
        required: true,
        message: "Please input your Mobile Number!",
      },
    ],
    fieldType: "mobileInput",
  },
  {
    name: "password",
    label: "Password",
    fieldType: "password",
    placeHolder: "Enter Password",
    rules: [
      { required: true, message: "Please input your password!" },
      ({ getFieldValue }) => ({
        validator(_, value) {
          var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
          if (!value || re.test(value)) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(
              "At least One capital letter, one small letter one number one special character and minimum 8 characters"
            )
          );
        },
      }),
    ],
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Confirm Your Password",
    rules: [
      {
        required: true,
        message: "Please confirm your password!",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The new password that you entered do not match!")
          );
        },
      }),
    ],
    fieldType: "password",
  },
  {
    name: "agreement",
    fieldText: "I agree to terms and Conditions",
    placeHolder: "",
    rules: [
      {
        validator: (_, value) =>
          value
            ? Promise.resolve()
            : Promise.reject(new Error("Should accept agreement")),
      },
    ],
    fieldType: "singleCheckbox",
    style: { textAlign: "center" },
    valuePropName: "checked",
  },
];
