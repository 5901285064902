export const almostDoneFormFields = [
  // {
  //   name: "profilePic",
  //   label: "Upload Profile Pic",
  //   fieldType: "file",
  //   placeHolder: "",
  //   rules: [
  //     // {
  //     //   required: true,
  //     //   message: "Please upload your pic!",
  //     // },
  //   ],
  // },
  {
    name: "description",
    label: "Tell us About YourSelf",
    placeHolder: "Tell us About YourSelf",
    rules: [
      {
        required: true,
        message: "Please Tell us About YourSelf!",
      },
    ],
    fieldType: "textArea",
  },
];
