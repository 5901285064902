import React from "react";
import { Card, Carousel, Form, Input, Layout, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const SubmitOnSearchPage = () => {
  const navigate = useNavigate();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [form] = Form.useForm();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content
          style={{
            margin: "84px 46px 0",
          }}
        >
          <Card
            title="submitonsearchpage"
            bordered={false}
            style={{
              width: "auto",
            }}
          ></Card>
        </Content>
      </Layout>
    </Layout>
  );
};
