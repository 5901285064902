import { Col, Dropdown, Image, Menu, Row, Space, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import Title from "antd/es/skeleton/Title";
import React from "react";
import Logo from "../images/logo.jpeg";
import { Button, Carousel, Form, Input, Layout, theme } from "antd";
import { FaCrown } from "react-icons/fa";

import {
  AppstoreOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  HeartOutlined,
  HomeOutlined,
  KeyOutlined,
  LockOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  BellOutlined,
  ClockCircleOutlined,
  CrownFilled,
} from "@ant-design/icons";
import { getProfilePicAsURL } from "../utils/utils";
import { getUserDetailsFromLocalStorage } from "../helperFunctions/endpoints";
import { BsBell, BsBellFill } from "react-icons/bs";

export const CustomHeader = ({ navigate, title }) => {
  const { Text } = Typography;
  const userDetails = getUserDetailsFromLocalStorage();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    {
      key: "/dashboard",
      icon: React.createElement(HomeOutlined),
      label: "Dashboard",
    },
    {
      key: "/search",
      icon: React.createElement(SearchOutlined),
      label: "Search",
    },

    {
      label: "Profile",
      key: "ProfileSubMenu",
      icon: React.createElement(AppstoreOutlined),
      children: [
        {
          key: "/allprofiles",
          icon: React.createElement(UsergroupAddOutlined),
          label: "All Profiles",
        },
        {
          key: "/myprofile",
          icon: React.createElement(UsergroupAddOutlined),
          label: "My Profile",
        },
      ],
    },
    {
      label: "Settings",
      key: "SubMenu",
      icon: <SettingOutlined />,
      children: [
        getItem("Feedback", "/feedback", React.createElement(ReadOutlined)),

        getItem(
          "Delete Profile",
          "/deleteProfile",
          React.createElement(DeleteOutlined)
        ),
        getItem(
          "Terms & Conditions",
          "/termsconditions",
          React.createElement(ExclamationCircleOutlined)
        ),
        getItem(
          "Privacy Policy",
          "/privacypolicy",
          React.createElement(LockOutlined)
        ),
      ],
    },
  ];

  const profileitems = [
    {
      key: "/changepassword",
      label: "Change Password",
    },
    {
      key: "/donatenow",
      label: "Donate Now",
      onClick: () =>
        window.open(
          "https://donations.heartfulness.org/donation-general-fund/"
        ),
    },
    {
      key: "/logout",
      label: "Logout",
    },
  ];

  const helpcenteritems = [
    {
      key: "/mobilenumber",
      label: "9452613159",
    },
    {
      key: "/email",
      label: "connectinghearts.info@gmail.com",
    },
  ];
  const onItemClick = (item) => {
    navigate(item.key);
    return true;
  };

  const onProfileItemClick = async (item) => {
    if (item.key === "/logout") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("userDetails");
      navigate("/");
    } else if (item.key === "/changepassword") {
      navigate("/changepassword");
    }
    return true;
  };

  const onnotification = async () => {
    navigate("/notification");
  };

  return (
    <Header
      className="custom-header"
      style={{
        position: "sticky",
        padding: 0,
        display: "flex",
        top: 0,
        zIndex: 1,
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 12,
        }}
      >
        <Button
          type="link"
          className="sider-title"
          style={{
            padding: "5px 10px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <img
            src={Logo}
            alt="no_image"
            className="login-logo"
            style={{ marginRight: "0.3rem", height: "1.5rem", width: "1.5rem" }}
          />
          Connecting Hearts
        </Button>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 20,
        }}
      >
        <Button
          type="primary"
          size="small"
          className="border-Radius-5px"
          style={{ marginRight: 10 }}
          icon={<CrownFilled />}
        >
          Upgrade Now
        </Button>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <Button
          type="link"
          className="sider-title"
          style={{
            padding: "5px 10px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => navigate("/search")}
        >
          <SearchOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <Button
          type="link"
          className="sider-title"
          style={{
            padding: "5px 10px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => navigate("/notifications")}
        >
          <BellOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 30,
        }}
        onClick={onnotification}
      >
        <BellOutlined
          style={{
            color: "white",
            fontSize: 24,
          }}
        />
      </div>

      <Dropdown
        menu={{
          items: helpcenteritems,
          selectable: true,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <QuestionCircleOutlined
            style={{
              color: "white",
              fontSize: 24,
            }}
          />
          ;
        </div>
      </Dropdown>

      <Dropdown
        menu={{
          items: profileitems,
          selectable: true,
          onClick: onProfileItemClick,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <img
            src={getProfilePicAsURL(userDetails?._id, userDetails?.profilePic)}
            onError={(e) => {
              e.target.src = Logo;
            }}
            alt=""
            height={50}
            width={50}
            style={{ borderRadius: 50, objectFit: "cover" }}
          />
          ;
        </div>
      </Dropdown>
    </Header>
  );
};
