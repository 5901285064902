export const srcmformFields = [
  {
    name: "srcmIdNumber",
    label: "SCRM/heartfulness Id Number",
    placeHolder: "Enter SCRM/heartfulness Id Number",
    rules: [
      {
        required: true,
        message: "Please input your Scrm Id Number!",
      },
    ],
    fieldType: "input",
  },
  {
    name: "satsangCenterName",
    label: "Satsang center name/city",
    placeHolder: "Enter Satsang center name/city",
    rules: [
      {
        required: true,
        message: "Please input your Satsang center name/city!",
      },
    ],
    fieldType: "input",
  },
  {
    name: "preceptorsName",
    label: "Preceptor's Name(frequently visited)",
    placeHolder: "Enter Preceptor's Name",
    rules: [
      {
        required: true,
        message: "Please input your Preceptor's Name!",
      },
    ],
    fieldType: "input",
  },
  {
    name: "preceptorsContactNumber",
    label: "Preceptor's Mobile Number",
    placeHolder: "Enter Preceptor's Mobile Number",
    rules: [
      {
        required: true,
        message: "Please input your Mobile Number!",
      },
    ],
    fieldType: "numberInput",
  },
  {
    name: "preceptorsEmail",
    label: "Preceptor's Email",
    placeHolder: "Enter Preceptor's Email",
    feedBack: true,
    rules: [
      {
        type: "email",
        message: "The input is not valid E-mail!",
      },
    ],
    fieldType: "input",
  },
];
