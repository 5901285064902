import {
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  SearchOutlined,
  SendOutlined,
  StopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useEffect } from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { IoCall } from "react-icons/io5";
import { BiChat } from "react-icons/bi";

const { Text } = Typography;
const { Search } = Input;

const ProfileActionsAccepted = ({
  userdata,
  acceptDeclineClick,
  handleContactClick,
  shortlistClick,
  blockClick,
  handleChatClick,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
      className="profile-view-action-background"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: 480,
        }}
      >
        {!userdata?.isInterestDeclined ? (
          <ButtonWithIcon
            label={"Decline"}
            icon={<CloseCircleOutlined className="button-size-selected" />}
            onClick={() => acceptDeclineClick(userdata, "reject")}
          />
        ) : (
          <ButtonWithIcon
            label={"Accept"}
            icon={
              <SendOutlined
                className="button-size"
                onClick={() => acceptDeclineClick(userdata, "accept")}
              />
            }
          />
        )}

        <ButtonWithIcon
          label={"Contact"}
          icon={<IoCall className="button-size" />}
          onClick={handleContactClick}
        />
        {shortlistClick &&
          (userdata?.isShortlisted ? (
            <ButtonWithIcon
              label={"Shortlisted"}
              icon={<TeamOutlined lined className="button-size-selected" />}
              onClick={shortlistClick}
            />
          ) : (
            <ButtonWithIcon
              label={"Shortlist"}
              icon={<TeamOutlined className="button-size" />}
              onClick={shortlistClick}
            />
          ))}
        {userdata?.isBlocked ? (
          <ButtonWithIcon
            label={"Unblock"}
            icon={<StopOutlined className="button-size-selected" />}
            onClick={blockClick}
          />
        ) : (
          <ButtonWithIcon
            label={"Block"}
            icon={<StopOutlined className="button-size" />}
            onClick={blockClick}
          />
        )}

        <ButtonWithIcon
          label={"Chat"}
          icon={<BiChat className="button-size" />}
          onClick={handleChatClick}
        />
      </div>
    </div>
  );
};
export default ProfileActionsAccepted;
