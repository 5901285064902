import { Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import React from "react";

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const MobileNumberInput = ({
  size = "large",
  name,
  onChangeHandler,
  countryCodeOptions,
}) => {
  const countryCodes = (
    <Form.Item
      name="countryCode"
      rules={[
        {
          required: true,
          message: "Please select country code",
        },
      ]}
      noStyle
    >
      <Select
        filterOption={filterOption}
        showSearch
        options={countryCodeOptions}
        dropdownStyle={{ width: "10rem" }}
        onChange={(option) => onChangeHandler("countryCode", option)}
      >
        {countryCodeOptions?.map((option, i) => {
          return (
            <Option key={i} value={option?.value}>
              {option?.label}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <Input
      className="border-Radius-5px"
      size={size}
      addonBefore={countryCodes}
      type="number"
      name={name}
      onChange={(e) => onChangeHandler(name, e.target.value)}
    />
  );
};

export default MobileNumberInput;
