import React, { useEffect, useState } from "react";
import {
  Card,
  Carousel,
  Col,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Tooltip,
  Button,
  Divider,
  Typography,
  Progress,
  Modal,
} from "antd";
import Logo from "../images/logo.jpeg";
import NoProfileImage from "../images/no-profile.png";
import { CustomSider } from "../genericComponents/CustomSider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getDateFormatted,
  getErrorImgForOtherProfile,
  getErrorImgForProfile,
  getLabelfromLookup,
  getProfilePicAsURL,
  getStateAndCityLabels,
} from "../utils/utils";
import { FaUserGroup } from "react-icons/fa6";

import {
  RightCircleOutlined,
  HeartOutlined,
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  SendOutlined,
  TeamOutlined,
  ContactsFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  getDetailsView,
  getLookupDataFromLocalStorage,
  getPersonalDetails,
  getUserDetailsFromLocalStorage,
  getblockprofile,
  getignoreprofile,
  getshortlist,
  getunblockprofile,
  getunignoreprofile,
  getunlockProfile,
  getunshortlist,
  getvisitprofile,
  sendInterest,
  unsendInterest,
  updateInterest,
} from "../helperFunctions/endpoints";
import { findLabelByValueAndKey } from "../utils/utils";
import { GiHeartBeats } from "react-icons/gi";
import { LiaHeartbeatSolid } from "react-icons/lia";
import NavBar from "../genericComponents/CustomNav";
import { ProfileImage } from "../genericComponents/UserProfileCard";
import ButtonWithIcon from "../genericComponents/ButtonWithIcon";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { BiChat, BiLock } from "react-icons/bi";
import { IoCall } from "react-icons/io5";
import { toast } from "react-toastify";
import ProfileActionsDefault from "../genericComponents/ProfileActionsDefault";
import ProfileActionsAcceptDecline from "../genericComponents/ProfileActionsAcceptDecline";
import ProfileActionsSingleButton from "../genericComponents/ProfileActionsSingleButton";
import ProfileActionsAccepted from "../genericComponents/ProfileActionsAccepted";
const { Header, Content, Footer } = Layout;

const createImageUrl = (userId, fileName) => {
  return `${process.env.REACT_APP_API_URL}profile/file/${userId}/${fileName}`;
};

export const ProfileView = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfiledata(id);
    updateProfileView();
  }, [id]);

  const [userdata, setUserData] = useState([]);
  const [myUserData, setMyUserData] = useState([]);
  const [cityLabels, setCityLabels] = useState();
  const [userPersonaldata, setUserPersonaldata] = useState([]);
  const [isMyProfileView, setIsMyProfileView] = useState(true);
  const location = useLocation();
  const { state } = location;
  const [lookupData, setLookupData] = useState(getLookupDataFromLocalStorage());
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const screenName = state?.screenName || "default";
  const [activeTab, setActiveTab] = useState("basicdetails");

  const getProfiledata = async (id) => {
    getDetailsView(id).then(async (res) => {
      if (res?.code === "CH200") {
        setUserData(res?.data?.miscellaneous);
        const labels = await getStateAndCityLabels(
          res?.data?.miscellaneous?.country,
          res?.data?.miscellaneous?.state,
          res?.data?.miscellaneous?.city
        );
        setCityLabels(labels);
        setUserPersonaldata(res?.data);
        if (screenName === "interestSent") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: true }));
        } else if (screenName === "iDeclined") {
          setUserData((prevData) => ({ ...prevData, isIDeclined: true }));
        } else if (screenName === "blockedProfile") {
          setUserData((prevData) => ({ ...prevData, isBlocked: true }));
        } else if (screenName === "acceptedMe") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestDeclined: false,
          }));
        }
      }
    });
  };

  const updateProfileView = async () => {
    const userData = getUserDetailsFromLocalStorage();
    setMyUserData(userData);
    console.log(userData?._id, id);
    if (userData?._id !== id) {
      setIsMyProfileView(false);
      getvisitprofile(id).then((res) => {
        if (res?.code === "CH200") {
          console.log(res);
        }
      });
    }
  };

  const interestClick = () => {
    if (userdata?.isInterestSent) {
      unsendInterest({ receiver_id: userdata?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: false }));
          toast.success(res?.message);
        }
      });
    } else {
      sendInterest({ targetId: userdata?.clientID }).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isInterestSent: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const updateInterestClick = (profileItem, status) => {
    const reqbody = {
      _id: profileItem?.clientID,
      status: status,
    };
    updateInterest(reqbody).then(async (res) => {
      if (res?.code === "CH200") {
        if (status === "accept") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestReceived: true,
            isInterestDeclined: false,
            isIDeclined: false,
          }));
        } else if (status === "reject") {
          setUserData((prevData) => ({
            ...prevData,
            isInterestReceived: false,
            isInterestDeclined: true,
            isIDeclined: true,
          }));
        }
        toast.success(res?.message);
      }
    });
  };

  const shortlistClick = () => {
    if (userdata?.isShortlisted) {
      getunshortlist(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isShortlisted: false }));
          toast.success(res?.message);
        }
      });
    } else {
      getshortlist(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isShortlisted: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const handleContactClick = () => {
    if (userPersonaldata?.contact?.phoneNumber) {
      setIsUnlockModalOpen(true);
    } else {
      Modal.confirm({
        title: "Do you want to unlock this profile?",
        icon: <ExclamationCircleFilled />,
        content: "(This will cost you 1 heart coin!)",
        async onOk() {
          const resp = await getunlockProfile(userdata?.clientID);
          if (resp?.code === "CH200") {
            getProfiledata(id);
            setIsUnlockModalOpen(true);
          } else if (resp?.code === "CH400") {
            toast.error(resp?.err?.msg);
            if (resp?.err?.redirectToMembership) navigate("/membership");
          }
        },
        onCancel() {},
      });
    }
  };

  const handleChatClick = () => {
    toast.success("Chat feature coming soon!");
  };

  const blockClick = () => {
    if (userdata?.isBlocked) {
      getunblockprofile(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isBlocked: false }));
          toast.success(res?.message);
        }
      });
    } else {
      getblockprofile(userdata?.clientID).then((res) => {
        if (res?.code === "CH200") {
          setUserData((prevData) => ({ ...prevData, isBlocked: true }));
          toast.success(res?.message);
        }
      });
    }
  };

  const tabListNoTitle = [
    {
      key: "basicdetails",
      label: "Basic Details",
    },
    {
      key: "family",
      label: "Family",
    },
    {
      key: "kundali",
      label: "Kundali",
    },
    !isMyProfileView
      ? {
          key: "match",
          label: "Match",
        }
      : {},
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const tabData = {
    basicdetails: (
      <div>
        <div style={{ padding: "8px" }}>
          <Col>{"HEARTS-" + userdata?.heartsId}</Col>
          <Row>
            {findLabelByValueAndKey(userPersonaldata?.basic?.cast, "casts")}
          </Row>
          <Row>
            {findLabelByValueAndKey(userPersonaldata?.basic?.height, "height")}
          </Row>
          <Row>
            {cityLabels?.city}, {cityLabels?.state}, {cityLabels?.country}
          </Row>
        </div>
        <div
          style={{
            borderColor: "#f1c40f",
            backgroundColor: "#f1c40f2e",
            border: "1px",
            padding: "8px",
            borderRadius: "10px",
          }}
        >
          <Col className="col-title-color">Critical Field</Col>
          <Col>{getDateFormatted(userPersonaldata?.critical?.dob)}</Col>
          <Col>
            {findLabelByValueAndKey(
              userPersonaldata?.critical?.maritalStatus,
              "maritalStatus"
            )}
          </Col>
        </div>
        <div style={{ padding: "8px" }}>
          <Col className="col-title-color">About Me</Col>
          <Col>{userPersonaldata?.about?.description}</Col>
        </div>
        <div style={{ padding: "8px" }}>
          <Col className="col-title-color">Educational Details</Col>
          <Row>
            <Col span={12}>Qualification</Col>
            {findLabelByValueAndKey(
              userPersonaldata?.education?.qualification,
              "qualification"
            )}
          </Row>
        </div>
        <div style={{ padding: "8px" }}>
          <Col className="col-title-color">Career Details</Col>
          <Row>
            <Col span={12}>Employed In</Col>
            {findLabelByValueAndKey(
              userPersonaldata?.career?.employed_in,
              "employed_in"
            )}
          </Row>

          <Row>
            <Col span={12}>occupation </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.career?.occupation,
              "occupation"
            )}
          </Row>
          <Row>
            <Col>
              Income:&emsp;&emsp; &emsp;&emsp;{" "}
              {findLabelByValueAndKey(
                userPersonaldata?.career?.income,
                "income"
              )}
            </Col>
          </Row>
        </div>
      </div>
    ),
    family: (
      <div>
        <div style={{ padding: "8px" }}>
          <Row>
            <Col span={12}>Family Type </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.familyType,
              "familyType"
            )}
          </Row>
          <Row>
            <Col span={12}>Family Values </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.familyValues,
              "familyValues"
            )}
          </Row>
          <Row>
            <Col span={12}> Family Income </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.familyIncome,
              "familyIncome"
            )}
          </Row>
          <Row>
            <Col span={12}> Father Occupation </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.fathersOccupation,
              "fathersOccupation"
            )}
          </Row>
          <Row>
            <Col span={12}> Mother Occupation </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.mothersOccupation,
              "mothersOccupation"
            )}
          </Row>

          <Row>
            <Col span={12}>Brother </Col>
            {findLabelByValueAndKey(
              parseInt(userPersonaldata?.family?.brothers),
              "siblings"
            )}
          </Row>

          <Row>
            <Col span={12}>Married Brother </Col>
            {findLabelByValueAndKey(
              parseInt(userPersonaldata?.family?.marriedBrothers),
              "siblings"
            )}
          </Row>

          <Row>
            <Col span={12}>Sisters </Col>
            {findLabelByValueAndKey(
              parseInt(userPersonaldata?.family?.sisters),
              "siblings"
            )}
          </Row>

          <Row>
            <Col span={12}>Married Sisters</Col>
            {findLabelByValueAndKey(
              parseInt(userPersonaldata?.family?.marriedSisters),
              "siblings"
            )}
          </Row>

          <Row>
            <Col span={12}>Gotra </Col>
            {userPersonaldata?.family?.gothra}
          </Row>

          <Row>
            <Col span={12}>Living With Parent </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.livingWithParents,
              "livingWithParents"
            )}
          </Row>

          <Row>
            <Col span={12}>My family based out of </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.family?.familyBasedOutOf,
              "family_based_out_of"
            )}
          </Row>
        </div>
      </div>
    ),
    kundali: (
      <div>
        <div style={{ padding: "8px" }}>
          <Col className="col-title-color">Kundali & Astro</Col>

          <Row>
            <Col span={12}>Rashi </Col>
            {findLabelByValueAndKey(userPersonaldata?.kundali?.rashi, "rashi")}
          </Row>

          <Row>
            <Col span={12}>Nakshatra </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.kundali?.nakshatra,
              "nakshatra"
            )}
          </Row>
          <Row>
            <Col span={12}>Manglik </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.kundali?.manglik,
              "manglik"
            )}
          </Row>

          <Row>
            <Col span={12}>Horoscopes </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.kundali?.horoscope,
              "horoscopes"
            )}
          </Row>
          <Col className="col-title-color">Life Style</Col>

          <Row>
            <Col span={12}>Dietary Habits </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.dietaryHabits,
              "dietaryHabits"
            )}
          </Row>
          <Row>
            <Col span={12}>Driking Habits </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.drinkingHabits,
              "drinkingHabits"
            )}
          </Row>
          <Row>
            <Col span={12}>Smoking Habits </Col>
            {userPersonaldata?.lifeStyleData?.smokingHabits}
          </Row>
          <Row>
            <Col span={12}>Favorite Movies </Col>
            {userPersonaldata?.lifeStyleData?.movies}
          </Row>
          <Row>
            <Col span={12}>Language </Col>
            {getLabelfromLookup(
              userPersonaldata?.lifeStyleData?.languages,
              lookupData,
              "motherTongue"
            )}
          </Row>
          <Row>
            <Col span={12}>Food I Cook </Col>
            {userPersonaldata?.lifeStyleData?.foodICook}
          </Row>
          <Row>
            <Col span={12}>Favorite Music </Col>
            {userPersonaldata?.lifeStyleData?.favMusic}
          </Row>
          <Row>
            <Col span={12}>Hobbies </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.hobbies,
              "hobbies"
            )}
          </Row>
          <Row>
            <Col span={12}>Interest </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.interest,
              "interests"
            )}
          </Row>
          <Row>
            <Col span={12}>Favorite Books </Col>
            {userPersonaldata?.lifeStyleData?.books}
          </Row>
          <Row>
            <Col span={12}>Dress </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.dress,
              "dressStyle"
            )}
          </Row>
          <Row>
            <Col span={12}>Sports </Col>
            {findLabelByValueAndKey(
              userPersonaldata?.lifeStyleData?.sports,
              "sports"
            )}
          </Row>
          <Row>
            <Col span={12}>Favorite cuisine </Col>
            {userPersonaldata?.lifeStyleData?.cuisine}
          </Row>
          <Row>
            <Col span={12}>Favorite Read </Col>
            {userPersonaldata?.lifeStyleData?.favRead}
          </Row>
          <Row>
            <Col span={12}>Favorite Tv Show </Col>
            {userPersonaldata?.lifeStyleData?.favTVShow}
          </Row>
          <Row>
            <Col span={12}>Vacation Destination </Col>
            {userPersonaldata?.lifeStyleData?.vacayDestination}
          </Row>
        </div>
      </div>
    ),
    match: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "8px",
            marginTop: "1rem",
            width: 220,
            height: 110,
            background: "#f8f8fc",
            borderRadius: "10px",
            justifyContent: "center",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {userdata?.clientID && (
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                userdata?.clientID,
                userdata?.profilePic
              )}
              errorImage={getErrorImgForOtherProfile()}
              width={60}
              height={100}
              style={{ transform: "rotate(345deg)", borderRadius: "10px" }}
            />
          )}
          <Progress
            type="circle"
            strokeWidth={20}
            size={50}
            style={{ zIndex: 2 }}
            strokeColor={"#FF004C"}
            percent={userPersonaldata?.matchPercentage}
          />
          {myUserData?._id && (
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                myUserData?._id,
                myUserData?.profilePic
              )}
              errorImage={getErrorImgForProfile(myUserData)}
              width={60}
              height={100}
              style={{ transform: "rotate(15deg)", borderRadius: "10px" }}
            />
          )}
        </div>
        <div style={{ padding: "8px" }}>
          {userPersonaldata?.matchData?.map((data) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 2,
                alignItems: "Start",
                gap: 2,
                marginBottom: 10,
              }}
            >
              <Text style={{ width: 280 }}>
                <b>{data?.label}</b>: <br />({data?.value})
              </Text>
              {data?.isMatched && (
                <CheckCircleFilled
                  style={{
                    color: "green",
                    fontSize: 24,
                  }}
                />
              )}
              {!data?.isMatched && (
                <CloseCircleFilled style={{ color: "grey", fontSize: 24 }} />
              )}
            </div>
          ))}
        </div>
      </div>
    ),
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Card
              style={{
                width: "100vw",
                maxWidth: 480,
                borderRadius: "10px",
                height: 490,
              }}
              cover={
                <div style={{ margin: "auto" }}>
                  <Carousel
                    dotPosition="bottom"
                    style={{ width: "100%", maxWidth: 500 }}
                  >
                    {userdata?.clientID &&
                      userdata?.profilePic?.length > 0 &&
                      userdata?.profilePic?.map((data) => (
                        <ProfileImage
                          imgSrc={createImageUrl(userdata?.clientID, data?.id)}
                          errorImage={getErrorImgForOtherProfile()}
                          width={"100vw"}
                          maxWidth={500}
                          height={450}
                        />
                      ))}
                    {userdata?.profilePic?.length === 0 && (
                      <ProfileImage
                        imgSrc={getErrorImgForOtherProfile()}
                        errorImage={getErrorImgForOtherProfile()}
                        width={"100vw"}
                        maxWidth={500}
                        height={450}
                      />
                    )}
                  </Carousel>
                </div>
              }
              bodyStyle={{ padding: 0 }}
              tabList={tabListNoTitle}
              activeTabKey={activeTab}
              onTabChange={onTabChange}
              tabProps={{
                size: "middle",
                style: {
                  borderRadius: "10px",
                  position: "absolute",
                  bottom: 0,
                  left: "40%",
                  transform: "translateX(-40%)",
                },
              }}
            >
              <div
                style={{
                  marginTop: 50,
                  marginBottom: 120,
                  backgroundColor: "#fff",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {tabData[activeTab]}
              </div>
            </Card>
          </div>
          {screenName === "iDeclined" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isIDeclined}
              defaultLabel={"Accept Again"}
              selectedLabel={"Decline"}
              handleClick={() => updateInterestClick(userdata, "reject")}
              screenName={screenName}
              handleSelectedClick={() =>
                updateInterestClick(userdata, "accept")
              }
            />
          )}
          {screenName === "interestSent" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isInterestSent}
              defaultLabel={"Cancel Interest"}
              selectedLabel={"Send Interest"}
              handleClick={interestClick}
              screenName={screenName}
              handleSelectedClick={interestClick}
            />
          )}
          {screenName === "blockedProfile" && (
            <ProfileActionsSingleButton
              isSelected={userdata?.isBlocked}
              defaultLabel={"Blocked"}
              selectedLabel={"Block"}
              handleClick={blockClick}
              screenName={screenName}
              handleSelectedClick={blockClick}
            />
          )}
          {screenName === "interestReceived" && (
            <ProfileActionsAcceptDecline
              userdata={userdata}
              acceptClick={updateInterestClick}
              declineClick={updateInterestClick}
            />
          )}
          {screenName === "acceptedMe" && (
            <ProfileActionsAccepted
              userdata={userdata}
              acceptDeclineClick={updateInterestClick}
              handleContactClick={handleContactClick}
              blockClick={blockClick}
              handleChatClick={handleChatClick}
            />
          )}
          {screenName === "default" && !isMyProfileView && (
            <ProfileActionsDefault
              userdata={userdata}
              interestClick={interestClick}
              handleContactClick={handleContactClick}
              shortlistClick={shortlistClick}
              blockClick={blockClick}
              handleChatClick={handleChatClick}
            />
          )}
        </Content>
        <Modal
          title="Basic Modal"
          open={isUnlockModalOpen}
          onCancel={() => setIsUnlockModalOpen(false)}
          footer={null}
        >
          <p>Name: {userPersonaldata?.contact?.name}</p>
          <p>
            Mobile Number:{" "}
            <a href={`tel:${userPersonaldata?.contact?.phoneNumber}`}>
              {userPersonaldata?.contact?.phoneNumber}
            </a>
          </p>
          <p>
            Email:{" "}
            <a href={`mailto:${userPersonaldata?.contact?.email}`}>
              {userPersonaldata?.contact?.email}
            </a>
          </p>
          <p>
            Alt Mobile Number:{" "}
            <a href={`tel:${userPersonaldata?.contact?.altMobileNumber}`}>
              {userPersonaldata?.contact?.altMobileNumber}
            </a>
          </p>
        </Modal>
      </Layout>
    </Layout>
  );
};
