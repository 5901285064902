export const partnerPreferencesAgeFormFields = [
  {
    name: "minAge",
    label: "Min. Age",
    placeHolder: "Select Min Age",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your !",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("maxAge") ||
            getFieldValue("maxAge") >= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The min Age should be less than max age!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "age",
    style: { width: "50%" },
  },
  {
    name: "maxAge",
    label: "Max. Age",
    placeHolder: "Select Max Age",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your father occupation!",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("minAge") ||
            getFieldValue("minAge") <= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The max age should be greater than min age!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "age",
    style: { width: "50%" },
  },
];

export const partnerPreferencesHeightFormFields = [
  {
    name: "minHeight",
    label: "Min. Height",
    placeHolder: "Select min height",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your Family Income!",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("maxHeight") ||
            getFieldValue("maxHeight") >= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The min height should be less than max height!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "height",
    style: { width: "50%" },
  },
  {
    name: "maxHeight",
    label: "Max. Height",
    placeHolder: "Select max height",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your father occupation!",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("minHeight") ||
            getFieldValue("minHeight") <= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The max height should be greater than min height!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "height",
    style: { width: "50%" },
  },
];

export const partnerPreferencesIncomeFormFields = [
  {
    name: "minIncome",
    label: "Min. Income",
    placeHolder: "Select min Income",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your Family Income!",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("maxIncome") ||
            getFieldValue("maxIncome") >= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The min income should be less than max income!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "income",
    style: { width: "50%" },
  },
  {
    name: "maxIncome",
    label: "Max. Income",
    placeHolder: "Select max Income",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your father occupation!",
      // },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            !getFieldValue("minIncome") ||
            getFieldValue("minIncome") <= value
          ) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The max income should be greater than min income!")
          );
        },
      }),
    ],
    fieldType: "singleSelect",
    lookups: "income",
    style: { width: "50%" },
  },
];

export const partnerPreferencesFormFields = [
  {
    name: "country",
    label: "Country",
    placeHolder: "Select country",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Family Income!",
    //   },
    // ],
    fieldType: "multiSelect",
    apiLookups: "country",
  },
  {
    name: "residentialStatus",
    label: "Residential Status",
    placeHolder: "Select Residential Status",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your father occupation!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "residentialStatus",
  },
  {
    name: "occupation",
    label: "Occupation",
    placeHolder: "Select Occupation",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your gothra!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "occupation",
  },
  {
    name: "religion",
    label: "Religion",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "checkbox",
    lookups: "religion",
  },
  {
    name: "maritalStatus",
    label: "Marital Status",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "checkbox",
    lookups: "maritalStatus",
  },
  {
    name: "motherTongue",
    label: "Mother Tongue",
    placeHolder: "Select Mother Tongue",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "motherTongue",
  },
  {
    name: "caste",
    label: "Caste",
    placeHolder: "Select Caste",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "casts",
    nestedLookups: "religion",
  },

  {
    name: "education",
    label: "Education",
    placeHolder: "Select Education",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "highestEducation",
  },
  {
    name: "horoscope",
    label: "Horoscope",
    placeHolder: "Select Horoscope",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "multiSelect",
    lookups: "horoscopes",
  },
  {
    name: "manglik",
    label: "Manglik",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "checkbox",
    lookups: "manglik",
  },
];
