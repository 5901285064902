import {
  CloseCircleOutlined,
  DownOutlined,
  SearchOutlined,
  SendOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useEffect } from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { IoCall } from "react-icons/io5";
import { BiChat } from "react-icons/bi";

const { Text } = Typography;
const { Search } = Input;

const ProfileActionsDefault = ({
  userdata,
  interestClick,
  handleContactClick,
  shortlistClick,
  blockClick,
  handleChatClick,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
      className="profile-view-action-background"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: 480,
        }}
      >
        {userdata?.isInterestSent ? (
          <ButtonWithIcon
            label={"Interest Sent"}
            icon={<SendOutlined className="button-size-selected" />}
            onClick={interestClick}
          />
        ) : (
          <ButtonWithIcon
            label={"Send Interest"}
            icon={
              <SendOutlined className="button-size" onClick={interestClick} />
            }
          />
        )}

        <ButtonWithIcon
          label={"Contact"}
          icon={<IoCall className="button-size" />}
          onClick={handleContactClick}
        />
        {userdata?.isShortlisted ? (
          <ButtonWithIcon
            label={"Shortlisted"}
            icon={<TeamOutlined lined className="button-size-selected" />}
            onClick={shortlistClick}
          />
        ) : (
          <ButtonWithIcon
            label={"Shortlist"}
            icon={<TeamOutlined className="button-size" />}
            onClick={shortlistClick}
          />
        )}
        {userdata?.isBlocked ? (
          <ButtonWithIcon
            label={"Unblock"}
            icon={<CloseCircleOutlined className="button-size-selected" />}
            onClick={blockClick}
          />
        ) : (
          <ButtonWithIcon
            label={"Block"}
            icon={<CloseCircleOutlined className="button-size" />}
            onClick={blockClick}
          />
        )}

        <ButtonWithIcon
          label={"Chat"}
          icon={<BiChat className="button-size" />}
          onClick={handleChatClick}
        />
      </div>
    </div>
  );
};
export default ProfileActionsDefault;
