import React, { useEffect } from "react";
import "../index.css";
import verificationImage from "../images/verification.png";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";

function Verification() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLastScreenActivity("underVerification");
  }, []);

  const onLoginClick = () => {
    localStorage.clear();
    navigate("/", { replace: true, state: { backRoute: null } });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "4rem",
      }}
    >
      <img src={verificationImage} alt="Verification Pending" />
      <h1>Verification Pending</h1>
      <p style={{ fontSize: "1.2rem" }}>
        Your ID is under verification. It might take 15 to 20 days for
        verification. You can use the services once it is verified.
      </p>
      <Button
        type="primary"
        block
        className="border-Radius-5px block-button-text height-100p"
        onClick={() => onLoginClick()}
      >
        Back to Login
      </Button>
    </div>
  );
}

export default Verification;
