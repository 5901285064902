import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Layout,
  Row,
  theme,
  Tooltip,
  Typography,
  Notification,
  Badge,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomHeader } from "../genericComponents/CustomHeader";
import { toast } from "react-toastify";
import {
  getCountryLookups,
  getInterests,
  getInterestsRecv,
  getLookups,
  getMyUnlockedProfile,
  getUserDetails,
  getallignoredprofile,
  getallprofiles,
  getdailyrecommendations,
  getignoreprofile,
  getjustjoined,
  getmyblockedprofile,
  getmymembershipdetails,
  getmyshortlistedprofiles,
  getprofiledeclined,
  getprofilevisitors,
  getshortlist,
  getusersdeclinedmyintrest,
  getuserssentintrest,
  sendInterest,
  setUserDetailsInLocalStorage,
} from "../helperFunctions/endpoints";
import Title from "antd/es/typography/Title";
import GoldBatchImage from "../images/goldbatch.png";
import {
  NewUserProfileCard,
  ProfileImage,
  UserProfileCard,
  UserSmallListProfileCard,
} from "../genericComponents/UserProfileCard";
import {
  RightCircleOutlined,
  HeartOutlined,
  EyeOutlined,
  EditOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  ProfileNavScreen,
  findLabelByValueAndKey,
  getErrorImgForPreference,
  getErrorImgForProfile,
  getProfilePicAsURL,
} from "../utils/utils";
import { useLocation } from "react-router-dom";
import { FaUserGroup } from "react-icons/fa6";
import { CustomSider } from "../genericComponents/CustomSider";
import { Header } from "antd/es/layout/layout";
import NavBar from "../genericComponents/CustomNav";
import HorizontalScrollItems from "../genericComponents/HorizontalScrollItems";

const { Content } = Layout;

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Text } = Typography;

  const [justJoinedProfiles, setJustJoinedProfiles] = useState([]);
  const [allProfilesData, setAllProfilesData] = useState([]);
  const [dailyRecommendationData, setDailyRecommendationData] = useState([]);
  const [profileVisitorsData, setProfileVisitorsData] = useState([]);
  const userId = useSelector((state) => state.userDetails.userId);
  const [userDetails, setUserDetails] = useState({});

  //notification objects
  const [interestRecvData, setInterestRecvData] = useState([]);
  const [interestSentData, setInterestSentData] = useState([]);
  const [iDeclinedProfiles, setIDeclinedProfiles] = useState([]);
  const [unlockedProfiles, setunlockedProfiles] = useState([]);
  const [theyDeclined, settheyDeclined] = useState([]);
  const [shortlisted, setshortlisted] = useState([]);
  const [ignored, setignored] = useState([]);
  const [blocked, setblocked] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [notificationCount, setNotificationCount] = useState({});
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const bannerImages = [
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/1`,
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/2`,
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/3`,
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/4`,
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/5`,
    `${process.env.REACT_APP_API_URL}dashboard/getSliderPics/6`,
  ];

  useEffect(() => {
    getCountryLookups({ dispatch });
    getLookups({ dispatch });
    getUserForUI(userId);
    justJoined();
    getAllProfilesForUI();
    getDailyRecommedationForUI();
    getProfileVisitorsForUI();
    getInterestSentAPI();
    getInterestRecvAPI();
    getunlockedProfilesAPI();
    getIdeclinedProfilesAPI();
    getTheyDeclinedAPI();
    getShotlistedAPI();
    getIgnoredAPI();
    getBlockedAPI();
    getMembershipDetailsForUI();
  }, []);

  const getMembershipDetailsForUI = async () => {
    const membershipDetails = await getmymembershipdetails();
    setMembershipData(membershipDetails?.membershipData);
  };

  React.useEffect(() => {
    const total =
      (notificationCount?.interestReceived || 0) +
      (notificationCount?.interestSent || 0) +
      (notificationCount?.iDeclined || 0) +
      (notificationCount?.unlocked || 0) +
      (notificationCount?.theyDeclined || 0) +
      (notificationCount?.shortlisted || 0) +
      (notificationCount?.ignored || 0) +
      (notificationCount?.blocked || 0);
    setTotalNotificationCount(total);
  }, [notificationCount]);

  const justJoined = () => {
    getjustjoined().then((res) => {
      if (res?.code === "CH200") {
        setJustJoinedProfiles(res?.filteredProfiles);
      }
    });
  };
  const { state } = useLocation();

  const getUserForUI = (userId) => {
    getUserDetails({ userId }).then((res) => {
      if (res?.code === "CH200") {
        setUserDetailsInLocalStorage(res?.data);
        setUserDetails(res?.data);
      }
    });
  };

  const getProfileVisitorsForUI = () => {
    getprofilevisitors().then((res) => {
      setProfileVisitorsData(res);
    });
  };

  const interestClick = (profileItem) => {
    sendInterest({ targetId: profileItem?.clientID }).then((res) => {
      if (res?.code === "CH200") {
        toast.success(res?.message);
        getAllProfilesForUI();
      }
    });
  };

  const shortlistClick = (profileItem) => {
    getshortlist(profileItem?.clientID).then((res) => {
      if (res?.code === "CH200") {
        toast.success(res?.message);
        getAllProfilesForUI();
      }
    });
  };

  const ignoreClick = (profileItem) => {
    getignoreprofile(profileItem?.clientID).then((res) => {
      if (res?.code === "CH200") {
        toast.success(res?.message);
        getAllProfilesForUI();
      }
    });
  };

  const onInterestClick = (screenCode, title, data) => {
    navigate("/allprofiles", {
      state: { screenCode: screenCode, title: title, data: data },
    });
  };

  const getAllProfilesForUI = () => {
    getallprofiles("dashboard/getAllProfiles").then((res) => {
      if (res?.code === "CH200") {
        setAllProfilesData(res?.filteredProfiles);
      }
    });
  };
  const getDailyRecommedationForUI = () => {
    getdailyrecommendations().then((res) => {
      if (res?.code === "CH200") {
        setDailyRecommendationData(res?.filteredProfiles);
      }
    });
  };

  //notification functions
  const getInterestSentAPI = () => {
    getuserssentintrest().then((res) => {
      if (res?.code === "CH200") {
        setInterestSentData(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        interestSent: res?.notificationCount,
      }));
    });
  };
  const getunlockedProfilesAPI = () => {
    getMyUnlockedProfile().then((res) => {
      if (res?.code === "CH200") {
        setunlockedProfiles(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        unlocked: res?.notificationCount,
      }));
    });
  };
  const getIdeclinedProfilesAPI = () => {
    getprofiledeclined().then((res) => {
      if (res?.code === "CH200") {
        setIDeclinedProfiles(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        iDeclined: res?.notificationCount,
      }));
    });
  };

  const getTheyDeclinedAPI = () => {
    getusersdeclinedmyintrest().then((res) => {
      if (res?.code === "CH200") {
        settheyDeclined(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        theyDeclined: res?.notificationCount,
      }));
    });
  };

  const getShotlistedAPI = () => {
    getmyshortlistedprofiles().then((res) => {
      if (res?.code === "CH200") {
        setshortlisted(res?.shortlistedProfilesData);
      }
      setNotificationCount((prev) => ({
        ...prev,
        shortlisted: res?.notificationCount,
      }));
    });
  };

  const getInterestRecvAPI = () => {
    getInterestsRecv().then((res) => {
      if (res?.code === "CH200") {
        setInterestRecvData(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        interestReceived: res?.notificationCount,
      }));
    });
  };
  const getIgnoredAPI = () => {
    getallignoredprofile().then((res) => {
      if (res?.code === "CH200") {
        setignored(res?.ignoreListData);
      }
      setNotificationCount((prev) => ({
        ...prev,
        ignored: res?.notificationCount,
      }));
    });
  };
  const getBlockedAPI = () => {
    getmyblockedprofile().then((res) => {
      if (res?.code === "CH200") {
        setblocked(res?.filteredProfiles);
      }
      setNotificationCount((prev) => ({
        ...prev,
        blocked: res?.notificationCount,
      }));
    });
  };

  const acceptanceClick = (shotlisted) => {
    navigate("/allprofiles", {
      state: {
        title: "shotlisted",
        data: shotlisted,
        screenCode: ProfileNavScreen.InterestReceivedScreen,
      },
    });
  };
  const justJoinedClick = () => {
    navigate("/profileslist/justjoined", {
      state: { title: "Just Joined", data: justJoinedProfiles },
    });
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const Shortlisted = () => {
    navigate("/myshortlisted");
  };
  const EditProfile = () => {
    navigate("/myprofile");
  };
  const Visitors = () => {
    navigate("/myvisitors");
  };
  const PartnerPreferance = () => {
    navigate("/partnerpreference/edit");
  };

  return (
    <Layout>
      <NavBar
        navigate={navigate}
        notificationObjCount={notificationCount}
        notificationCount={totalNotificationCount}
      />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 100,
              width: "100%",
              background: "#e90248",
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              color: "white",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 20,
                alignItems: "center",
              }}
              onClick={() => navigate("/myprofile")}
            >
              {userDetails?._id && (
                <img
                  src={getProfilePicAsURL(
                    userDetails?._id,
                    userDetails?.profilePic
                  )}
                  onError={(e) => {
                    e.target.src = getErrorImgForProfile(userDetails);
                  }}
                  alt=""
                  height={50}
                  width={50}
                  style={{
                    borderRadius: 50,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              )}
              Your Profile
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 20,
              }}
              onClick={() => navigate("/partnerpreference/edit")}
            >
              {userDetails?._id && (
                <img
                  src={getErrorImgForPreference(userDetails)}
                  alt=""
                  height={50}
                  width={50}
                  style={{
                    borderRadius: 50,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              )}
              Looking For
            </div>
          </div>
          <div
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflow: "auto",
            }}
          >
            <Card
              className="info-card"
              style={{
                marginTop: 10,
                width: "100%",
                padding: 8,
                marginTop: 0.2,
              }}
              onClick={() => navigate("/membership")}
            >
              {membershipData?.planName && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={GoldBatchImage}
                    alt="no-img"
                    style={{
                      display: "flex",
                      justifycontent: "Center",
                      alignitems: "center",
                    }}
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    &nbsp;&nbsp;Active Package
                    <br />
                    <b>{membershipData?.planName} plan</b>
                  </div>
                </div>
              )}
              {!membershipData?.planName && (
                <span
                  style={{ cursor: "pointer", fontSize: 18, fontWeight: 900 }}
                >
                  <b>Activate Your Plan</b>
                </span>
              )}
            </Card>
            <div className="div-flex-row flex-center">
              <Card
                style={{ fontSize: 18, fontWeight: 900 }}
                className="info-card"
                onClick={() => navigate("/acceptance")}
              >
                <b>0</b> <br />
                Acceptance
              </Card>
              <Card
                style={{ fontSize: 18, fontWeight: 900 }}
                className="info-card"
                onClick={justJoinedClick}
              >
                <b>{justJoinedProfiles?.length}</b> <br />
                Just Joined
              </Card>
            </div>
            <div className="div-flex-row flex-center">
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Carousel
                    autoplay
                    dotPosition="bottom"
                    style={{
                      width: "100%",
                      maxWidth: 500,
                    }}
                  >
                    {bannerImages?.map((image, index) => {
                      return (
                        <div>
                          <img
                            src={image}
                            className="home-banner-img"
                            alt="Image 1"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </Col>
              </Row>
            </div>
            {interestRecvData?.length > 0 && (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={4} className="title">
                  Interest Received {interestRecvData?.length}
                  <Button
                    type="link"
                    onClick={() => navigate("/profileslist/interestreceived")}
                    style={{ float: "right" }}
                  >
                    View All
                  </Button>
                </Title>
                <div className="scroll-horizontally">
                  {interestRecvData?.map((profile, index) => {
                    profile.isInterestReceived = true;
                    return (
                      index < 5 && (
                        <NewUserProfileCard
                          profileItem={profile}
                          navigate={navigate}
                          buttonText={"View Interest"}
                          screenName={"interestReceived"}
                        />
                      )
                    );
                  })}
                </div>
              </Col>
            )}
            {dailyRecommendationData?.length > 0 && (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={4} className="title">
                  Daily Recommendation {dailyRecommendationData?.length}
                  <Button
                    type="link"
                    onClick={() =>
                      navigate("/profileslist/dailyrecommendations")
                    }
                    style={{ float: "right" }}
                  >
                    View All
                  </Button>
                </Title>
                <HorizontalScrollItems
                  items={dailyRecommendationData}
                  id="dailyRecommendations"
                  navigate={navigate}
                  interestClick={interestClick}
                  shortlistClick={shortlistClick}
                  ignoreClick={ignoreClick}
                />
              </Col>
            )}
            {profileVisitorsData?.length > 0 && (
              <Col xl={24} lg={24} md={24} sm={12} xs={24}>
                <Title level={4} className="title">
                  Profile Visitors {profileVisitorsData?.length}
                  <Button
                    type="link"
                    onClick={() => navigate("/profileslist/profilevisitors")}
                    style={{ float: "right" }}
                  >
                    View All
                  </Button>
                </Title>
                <HorizontalScrollItems
                  items={profileVisitorsData}
                  id="profileVisitors"
                  navigate={navigate}
                  buttonText={"View Visitor"}
                />
              </Col>
            )}

            <Col style={{ marginTop: 20 }}>
              <Title level={4} className="title">
                All Profiles {allProfilesData?.length}
                <Button
                  type="link"
                  onClick={() => navigate("/profileslist/all")}
                  style={{ float: "right" }}
                >
                  View All
                </Button>
              </Title>
              <HorizontalScrollItems
                items={allProfilesData}
                id="allProfiles"
                navigate={navigate}
                interestClick={interestClick}
                shortlistClick={shortlistClick}
                ignoreClick={ignoreClick}
              />
            </Col>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
