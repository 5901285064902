import {
  CloseCircleOutlined,
  DownOutlined,
  SearchOutlined,
  SendOutlined,
  StopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useEffect } from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { IoCall } from "react-icons/io5";
import { BiChat } from "react-icons/bi";

const { Text } = Typography;
const { Search } = Input;

const ProfileActionsAcceptDecline = ({
  userdata,
  acceptClick,
  declineClick,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
      className="profile-view-action-background"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: 480,
        }}
      >
        {userdata?.isInterestReceived ? (
          <ButtonWithIcon
            label={"Accepted"}
            icon={<SendOutlined className="button-size-selected" />}
            onClick={() => declineClick(userdata, "reject")}
          />
        ) : (
          <ButtonWithIcon
            label={"Accept"}
            icon={
              <SendOutlined
                className="button-size"
                onClick={() => acceptClick(userdata, "accept")}
              />
            }
          />
        )}

        {userdata?.isInterestDeclined ? (
          <ButtonWithIcon
            label={"Declined"}
            icon={<StopOutlined className="button-size-selected" />}
            onClick={() => acceptClick(userdata, "accept")}
          />
        ) : (
          <ButtonWithIcon
            label={"Decline"}
            icon={
              <StopOutlined
                className="button-size"
                onClick={() => declineClick(userdata, "reject")}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
export default ProfileActionsAcceptDecline;
