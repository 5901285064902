import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  about: null,
};
export const aboutMeSlice = createSlice({
  name: "aboutMe",
  initialState,
  reducers: {
    updateAboutMeData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
export const { updateAboutMeData } = aboutMeSlice.actions;
export default aboutMeSlice.reducer;
