import { Button, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const ButtonWithIcon = ({ label, icon, onClick }) => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    onClick={onClick}
  >
    {icon}
    <Text className="text-white">{label}</Text>
  </div>
);
export default ButtonWithIcon;
