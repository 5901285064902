import React, { useEffect, useState } from "react";

const SelectTabList = ({ list, onChangeHandler, name, defaultValueGetter }) => {
  const [selectedItem, setSelectedItem] = useState(defaultValueGetter[name]);
  useEffect(() => {
    setSelectedItem(defaultValueGetter[name]);
  }, [defaultValueGetter]);
  return (
    <div className="tab-container">
      {list?.map((tab, i) => {
        return (
          <div
            key={i}
            className={`tab-item-round ${
              tab?.value === selectedItem ? "selected-tab-item" : ""
            }`}
            onClick={() => {
              setSelectedItem(tab.value);
              onChangeHandler(name, tab.value);
            }}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

export default SelectTabList;
