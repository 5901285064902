import React from "react";
import {
  Button,
  Card,
  Cascader,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  Select,
  Slider,
  TreeSelect,
  Upload,
  theme,
} from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import Checkbox from "antd/es/checkbox/Checkbox";
import { CustomHeader } from "../genericComponents/CustomHeader";
const { Header, Content, Footer } = Layout;

export const MyShortlisted = () => {
  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomHeader title={"MyShortlisted"} navigate={navigate} />
      <Content
        style={{
          margin: "24px 16px 0",
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: 360,
          }}
        ></div>
      </Content>
    </Layout>
  );
};
