import { Button, Typography } from "antd";
import React from "react";
import { NewUserProfileCard } from "./UserProfileCard";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
const HorizontalScrollItems = ({ items, ...allProps }) => (
  <>
    <div className="scroll-left-button">
      <Button
        type="primary"
        shape="circle"
        icon={<ArrowLeftOutlined />}
        onClick={() => {
          const scrollDiv = document.getElementById(
            `scroll-horizontally-${allProps?.id}`
          );
          scrollDiv.scrollLeft -= 400;
        }}
      />
    </div>
    <div
      className="scroll-horizontally"
      id={`scroll-horizontally-${allProps?.id}`}
    >
      {items?.map((profile, index) => {
        return (
          index < 5 && (
            <NewUserProfileCard profileItem={profile} {...allProps} />
          )
        );
      })}
    </div>
    <div className="scroll-right-button">
      <Button
        type="primary"
        shape="circle"
        icon={<ArrowRightOutlined />}
        onClick={() => {
          const scrollDiv = document.getElementById(
            `scroll-horizontally-${allProps?.id}`
          );
          scrollDiv.scrollLeft += 400;
        }}
      />
    </div>
  </>
);
export default HorizontalScrollItems;
