import axios from "axios";
import { getFromLocalStorage } from "./localStorage";

export const getHeaders = async (contentType) => {
  return {
    "Content-Type": contentType || "application/json",
    Authorization: "Bearer " + (await getFromLocalStorage("access_token")),
  };
};

const AjaXpress = async ({
  method = "GET",
  query = "",
  body = {},
  slug = "",
  contentType = "application/json",
  timeout = 100000,
  headers,
  responseType = "",
}) => {
  try {
    let url = process.env.REACT_APP_API_URL + slug + query;
    let myHeaders = headers || (await getHeaders(contentType));
    const api = axios.create({
      baseURL: url,
      timeout,
      headers: { ...myHeaders },
      responseType: responseType,
    });
    const response = await api.request({
      method,
      url,
      data: body,
    });
    return { data: response.data, response: response, err: false, status: 200 };
  } catch (error) {
    return {
      data: error?.response?.data,
      err: true,
      status: error?.response?.status,
    };
  }
};

export default AjaXpress;
