import { Button, Modal } from "antd";
import React, { useState } from "react";
import OtpInput from "react-otp-input";

const OtpModal = ({
  isModalOpen,
  subTitle,
  otp,
  setOtp,
  confirmOTP,
  loading,
}) => {
  return (
    <div>
      <Modal
        title="Verify OTP"
        open={isModalOpen}
        width={"fit-content"}
        className="border-Radius-5px"
        closeIcon={null}
        footer={null}
      >
        <div style={{ marginBottom: "0.5rem" }}>{subTitle}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span style={{ width: "10px" }}></span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "3px",
              fontSize: "1.5rem",
              color: "black",
              fontWeight: "400",
              caretColor: "black",
            }}
          />
        </div>
        <Button
          type="primary"
          block
          className="border-Radius-5px block-button-text height-100p marginTop-1rem"
          onClick={confirmOTP}
          loading={loading}
        >
          Confirm
        </Button>
      </Modal>
    </div>
  );
};

export default OtpModal;
