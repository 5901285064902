import React from "react";
import { Card, Carousel, Form, Input, Layout, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
import { Icon } from "antd";

const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const HelpCenter = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [form] = Form.useForm();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <div>
            <Text className="midtitle">{state?.title || "Help Center"}</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",

                marginTop: 10,
              }}
              src="https://img.freepik.com/free-vector/flat-customer-support-illustration_23-2148899114.jpg?size=338&ext=jpg&ga=GA1.1.2082370165.1716940800&semt"
              alt="HelpCenter"
            />
          </div>
          <br />
          <div>
            <Card
              style={{
                width: "100%",
                height: 80,
              }}
            >
              <a href="tel:+919452613159">
                {" "}
                <b>
                  <p> Phone : +91-9452613159</p>
                </b>
              </a>
            </Card>
            <br />
            <Card
              style={{
                width: "100%",
                height: 80,
                alignContent: "center",
              }}
            >
              <a href="mailto:connectinghearts.info@gmail.com">
                <b>
                  <p>Email:connectinghearts.info@gmail.com</p>
                </b>
              </a>
            </Card>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
