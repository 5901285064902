import {
  CloseCircleOutlined,
  DownOutlined,
  SearchOutlined,
  SendOutlined,
  StopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useEffect } from "react";
import ButtonWithIcon from "./ButtonWithIcon";
import { IoCall } from "react-icons/io5";
import { BiChat } from "react-icons/bi";

const { Text } = Typography;
const { Search } = Input;

const ProfileActionsSingleButton = ({
  isSelected,
  defaultLabel,
  selectedLabel,
  handleSelectedClick,
  handleClick,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
      className="profile-view-action-background"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: 480,
        }}
      >
        {isSelected ? (
          <ButtonWithIcon
            label={defaultLabel}
            icon={<StopOutlined className="button-size-selected" />}
            onClick={handleSelectedClick}
          />
        ) : (
          <ButtonWithIcon
            label={selectedLabel}
            icon={
              <SendOutlined className="button-size" onClick={handleClick} />
            }
          />
        )}
      </div>
    </div>
  );
};
export default ProfileActionsSingleButton;
