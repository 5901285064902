export const loginFormFields = [
  // {
  //   name: "phoneNumber",
  //   label: "Mobile Number",
  //   placeHolder: "Enter Mobile Number",
  //   fieldType: "mobileInput",
  //   rules: [
  //     {
  //       required: true,
  //       message: "Please input your Mobile Number!",
  //     },
  //   ],
  // },
  {
    name: "password",
    label: "Password",
    fieldType: "password",
    placeHolder: "Enter Your Password",
    rules: [{ required: true, message: "Please input your password!" }],
  },
];
