import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Verification from "./SignUp/Verification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "antd";
import Login from "./Login/Login";
import SignupIndex from "./SignUp/SignupIndex";
import PersonalDetails from "./SignUp/PersonalDetails";
import SrcmDetails from "./SignUp/SrcmDetails";
import SocialDetails from "./SignUp/SocialDetails";
import CareerDetails from "./SignUp/CareerDetails";
import FamilyDetails from "./SignUp/FamilyDetails";

import AlmostDoneForm from "./SignUp/AlmostDoneForm";
import { HomePage } from "./Home/Home";

import { SearchByProfileIDPage } from "./Search/SearchByProfileID";
import { AllprofilesPage } from "./Profiles/AllProfilesPage";

import { ProfileVisitors } from "./ProfileVisitors/ProfileVisitors";
import { Membership } from "./Membership/Membership";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { DeleteProfile } from "./DeleteProfile/DeleteProfile";
import { Feedback } from "./Feedback/Feedback";
import { HelpCenter } from "./HelpCenter/HelpCenter";
import { TermsConditions } from "./TermsConditions/TermsConditions";
import { PrivacyPolicy } from "./PrivacyPolicy/PrivacyPolicy";
import { validateToken } from "./helperFunctions/endpoints";
import { SubmitOnSearchPage } from "./sumbitonsearchprofile/SubmitOnSearchPage";

import { SearchPage } from "./Search/Search";

import { MyProfile } from "./myProfile/MyProfile";
import { Notification } from "./notification/Notification";
import { MyVisitors } from "./MyVisitors/MyVisitors";
import { MyShortlisted } from "./MyShortlisted/MyShortlisted";

import { ProfileView } from "./ProfileView/profileviewpage";
import SignupPartnerPreferences from "./SignUp/SignupPartnerPreferences";
import { useDispatch } from "react-redux";
import LifeStyle from "./SignUp/LifeStyle";
import { Acceptance } from "./acceptance/Acceptance";
import { ProfilesList } from "./Profiles/ProfilesList";
import ForgotPassword from "./Login/ForgotPassword";
import EditProfile from "./myProfile/EditProfile";
import { AddProfilePic } from "./AddProfilePic/AddProfilePic";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    validateToken({ navigate, dispatch });
  }, []);

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#FF004C",
            borderRadius: 2,
            colorBgContainer: "#f5f5f5",
            colorCard: "#ffffff",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/registration" element={<SignupIndex />} />
          <Route path="/personaldetails" element={<PersonalDetails />} />
          <Route path="/personaldetails/:id" element={<PersonalDetails />} />
          <Route path="/editprofile/:id" element={<EditProfile />} />
          <Route path="/srcmform" element={<SrcmDetails />} />
          <Route path="/careerdetails" element={<CareerDetails />} />
          <Route path="/careerdetails/:id" element={<CareerDetails />} />
          <Route path="/socialdetails" element={<SocialDetails />} />
          <Route path="/socialdetails/:id" element={<SocialDetails />} />
          <Route path="/familydetails" element={<FamilyDetails />} />
          <Route path="/familydetails/:id" element={<FamilyDetails />} />
          <Route path="/lifestyle" element={<LifeStyle />} />
          <Route path="/addprofilepic" element={<AddProfilePic />} />
          <Route
            path="/partnerpreference"
            element={<SignupPartnerPreferences />}
          />
          <Route path="/profilepic" element={<AlmostDoneForm />} />
          <Route
            path="/partnerpreference/:id"
            element={<SignupPartnerPreferences />}
          />
          <Route path="/underVerification" element={<Verification />} />
          <Route path="/dashboard" element={<HomePage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/helpcenter" element={<HelpCenter />} />

          <Route path="/submitonsearchpage" element={<SubmitOnSearchPage />} />
          <Route
            path="/searchbyprofileid"
            element={<SearchByProfileIDPage />}
          />

          <Route path="/allprofiles" element={<AllprofilesPage />} />
          <Route path="/profileslist/:id" element={<ProfilesList />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/acceptance" element={<Acceptance />} />
          <Route path="/profilevisitors" element={<ProfileVisitors />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/deleteprofile" element={<DeleteProfile />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />

          <Route path="/myshortlisted" element={<MyShortlisted />} />
          <Route path="/myvisitors" element={<MyVisitors />} />
          <Route path="/profileview/:id" element={<ProfileView />} />
        </Routes>
        <ToastContainer />
      </ConfigProvider>
    </div>
  );
};

export default App;
