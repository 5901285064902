import { Button, Col, Form, Typography, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import Progressbar from "../genericComponents/Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { personalDetailsFormFields } from "../constants/personalDetailsFormFields";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { setCities, setStates } from "../store/slices/lookupSlice";
import AjaXpress from "../helperFunctions/api";
import { updatePersonalData } from "../store/slices/personalDetailSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CaretLeftOutlined } from "@ant-design/icons";
import NavBar from "../genericComponents/CustomNav";
import { CustomSider } from "../genericComponents/CustomSider";
import { updateLastScreenActivity } from "../helperFunctions/endpoints";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fields, setFields] = useState(personalDetailsFormFields);
  const lookupData = useSelector((state) => state.lookup.lookupData);
  const countries = useSelector((state) => state.lookup.countries);
  const states = useSelector((state) => state.lookup.states);
  const cities = useSelector((state) => state.lookup.cities);
  const personalDetails = useSelector((state) => state.personalDetails);
  const [loading, setLoading] = useState({ get: false, set: false });
  const { Header, Content, Footer } = Layout;

  let { id } = useParams();

  const getPersonalDetails = async () => {
    setLoading((loading) => {
      return { ...loading, get: true };
    });
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `personalDetails`,
      });
      if (response?.response?.status == 200) {
        let data = response?.data?.data;
        let obj = {
          gender: data?.gender,
          dob: data?.dob ? dayjs(data.dob) : null,
          height: data?.height,
          country: data?.country,
          state: data?.state,
          city: data?.city,
          residentialStatus: data?.residentialStatus,
          education: data?.education?.qualification,
          otherPgDegree: data?.education?.otherUGDegree,
          employedIn: data?.employed_in,
          occupation: data?.occupation,
          income: data?.income,
          maritalStatus: data?.maritalStatus,
          haveChildren: data?.haveChildren,
          motherTongue: data?.motherTongue,
          religion: data?.religion,
          caste: data?.cast,
          horoscope: data?.horoscope,
          manglik: data?.manglik,
        };
        Object.keys(obj)?.forEach((item) => {
          handleFormChanges(item, obj[item]);
          if (item === "country" && obj["country"]) {
            getStateLookups(obj["country"]);
          }
          if (item === "state" && obj["state"]) {
            getCityLookus(obj["state"]);
          }
        });
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, get: false };
    });
  };

  useEffect(() => {
    getPersonalDetails();
    window.scrollTo(0, 0);
    updateLastScreenActivity("personaldetails");
  }, []);

  useEffect(() => {
    if (Object.keys(lookupData).length) {
      let data = [...fields];
      data = fields.map((field) => {
        return {
          ...field,
          defaultValueGetter: personalDetails,
          ...(field?.apiLookups === "country" && {
            options: countries,
          }),
          ...(field?.apiLookups === "state" && {
            options: states,
          }),
          ...(field?.apiLookups === "city" && {
            options: cities,
          }),
          ...(field?.lookups?.length && {
            options: lookupData[field?.lookups],
          }),
        };
      });
      setFields(data);
    }
  }, [lookupData, countries, states, cities, personalDetails]);

  const onFormChangeHandler = async (field) => {
    try {
      if (field.hasOwnProperty("country")) {
        getStateLookups(field.country);
      }
      if (field.hasOwnProperty("state")) {
        getCityLookus(field.state);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStateLookups = async (country) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getStateLookup/${country}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setStates(response?.data[0]?.states));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCityLookus = async (state) => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: `lookup/getCityLookup/${state}`,
      });
      if (response?.response?.status == 200) {
        dispatch(setCities(response?.data[0]?.cities));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
    if (name === "country") {
      getStateLookups(value);
    } else if (name === "state") {
      getCityLookus(value);
    }
    dispatch(
      updatePersonalData({
        key: name,
        value: name === "dob" ? dayjs(value).format() : value,
      })
    );
  };

  const addPersonalDetails = async () => {
    setLoading((loading) => {
      return { ...loading, set: true };
    });
    try {
      let response = await AjaXpress({
        method: "PATCH",
        body: {
          gender: personalDetails.gender,
          dob: personalDetails.dob,
          height: personalDetails.height,
          employed_in: personalDetails.employedIn,
          country: personalDetails.country,
          state: personalDetails.state,
          city: personalDetails.city,
          motherTongue: personalDetails.motherTongue,
          religion: personalDetails.religion,
          cast: personalDetails.caste,
          horoscope: personalDetails.horoscope,
          manglik: personalDetails.manglik,
          income: personalDetails.income,
          residentialStatus: personalDetails.residentialStatus,
          maritalStatus: personalDetails.maritalStatus,
          occupation: personalDetails.occupation,
          haveChildren: personalDetails.haveChildren,
          castNoBar: personalDetails.castNoBar,
          education: {
            qualification: personalDetails.education,
            otherUGDegree: personalDetails.otherPgDegree,
          },
        },
        slug: "personalDetails",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);
        if (id) {
          navigate("/myprofile");
        } else {
          navigate("/careerdetails");
        }
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading((loading) => {
      return { ...loading, set: false };
    });
  };
  const handleNext = (values) => {
    addPersonalDetails();
  };

  const handlePrev = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userDetails");
    navigate("/registration");
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  return (
    <Layout>
      {!!id && <NavBar navigate={navigate} />}
      <Layout>
        {!!id && <CustomSider navigate={navigate} />}
        <Content className="content-wrapper">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={16} xl={12}>
              <div className="login-margin" style={{ marginBottom: "3rem" }}>
                {!id && <Progressbar percent={20} />}
                <div className="sub-title">Fill In your Personal Details</div>
                <div style={{ padding: "1rem" }}>
                  Provide additional information like your Date of Birth etc
                </div>
                <Form
                  layout="vertical"
                  name="loginForm"
                  form={form}
                  onFinish={handleNext}
                  onValuesChange={onFormChangeHandler}
                  initialValues={{
                    gender: personalDetails?.gender,
                    dob: personalDetails?.dob
                      ? dayjs(personalDetails.dob)
                      : null,
                    height: personalDetails.height,
                    country: personalDetails.country,
                    state: personalDetails.state,
                    city: personalDetails.cities,
                    residentialStatus: personalDetails.residentialStatus,
                  }}
                >
                  {fields?.map((Field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Item
                          label={Field.label}
                          name={Field.name}
                          rules={Field.rules}
                          style={Field.style ? Field.style : {}}
                          {...(Field.feedBack && {
                            hasFeedback: Field.feedBack,
                          })}
                          {...(Field.valuePropName && {
                            valuePropName: Field.valuePropName,
                          })}
                        >
                          {formFieldRenderer(Field, handleFormChanges)}
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="border-Radius-5px block-button-text height-100p"
                      loading={loading.set}
                    >
                      {id ? "Update" : "Next"}
                    </Button>
                  </Form.Item>
                </Form>
                {!id && (
                  <Button
                    type="text"
                    block
                    className="border-Radius-5px block-button-text height-100p back-button"
                    onClick={() => handlePrev()}
                  >
                    <CaretLeftOutlined />
                    Back
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PersonalDetails;
