"use client";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import lookupReducer from "./slices/lookupSlice"; // Change the import to get the reducer
import personalDetailsReducer from "./slices/personalDetailSlice";
import srcmReducer from "./slices/srcmSlice";
import aboutMeReducer from "./slices/aboutMeSlice";
import familyDetailsReducer from "./slices/familyDetailSlice";
import partnerPreferenceReducer from "./slices/partnerPreferenceSlice";
import userReducer from "./slices/userSlice";

const rootReducer = combineReducers({
  lookup: lookupReducer, // Use the reducer from lookupSlice
  personalDetails: personalDetailsReducer,
  srcmData: srcmReducer,
  aboutMeData: aboutMeReducer,
  familyDetails: familyDetailsReducer,
  partnerPreferences: partnerPreferenceReducer,
  userDetails: userReducer,
  // Add all your other reducers here
});

export const store = configureStore({
  reducer: rootReducer,
});
