import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  profileS3Link: "",
  profilePicId: "",
  description: "",
};
export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setProfilePicDetails: (state, action) => {
      state.profilePicId = action.payload.id;
      state.profileS3Link = action.payload.s3Link;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
  },
});
export const { setUserId, setProfilePicDetails, setDescription } =
  userSlice.actions;
export default userSlice.reducer;
