import React, { useState } from "react";
import { Button, Col, Form, Row } from "antd";
import Title from "antd/es/typography/Title";
import Logo from "../images/logo.jpeg";
import { basicRegistrationFormFields } from "../constants/basicRegistrationFormFields";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import OtpModal from "../genericComponents/OtpModal";
import AjaXpress from "../helperFunctions/api";
import { useDispatch } from "react-redux";
import { setCountries, setLookup } from "../store/slices/lookupSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setDescription,
  setProfilePicDetails,
  setUserId,
} from "../store/slices/userSlice";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  generateOtp,
  getCountryLookups,
  getLookups,
  getUserDetails,
} from "../helperFunctions/endpoints";

const SignupIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const handleLogin = (values) => {
    setLoading(true);
    generateOtp(values).then((res) => {
      setLoading(false);
      if (res?.err) {
        toast.error(res?.err);
      } else {
        setOtp(res?.otp);
        alert("For any assistance call +91 9452613159");
        setShowOtpModal(true);
      }
    });
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
  };

  const verifyOTP = async () => {
    setVerifyLoading(true);
    try {
      let response = await AjaXpress({
        method: "POST",
        body: {
          phoneNumber: form.getFieldValue("phoneNumber"),
          otp: otp,
        },
        slug: "auth/verifyOTP",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);
        localStorage.setItem("access_token", response?.data?.token);
        setShowOtpModal(false);
        await signup();
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
    setVerifyLoading(false);
  };

  const signup = async () => {
    try {
      let response = await AjaXpress({
        method: "POST",
        body: {
          email: form.getFieldValue("email"),
          password: form.getFieldValue("password"),
          confirm_password: form.getFieldValue("confirmPassword"),
          name: form.getFieldValue("fullName"),
        },
        slug: "auth/signup",
      });
      if (response?.response?.status == 200) {
        toast.success(response.data.message);
        setShowOtpModal(false);
        await getUserDetails();
        await getLookups({ dispatch });
        await getCountryLookups({ dispatch });
        navigate("/personaldetails");
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={24} lg={14} xl={12}>
        <div
          className="login-image"
          style={{
            display: "block",
            backgroundImage:
              "url(https://d3nn873nee648n.cloudfront.net/1200x1800-new/20354/SM1015626.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={10} xl={12}>
        <div style={{ margin: "3rem" }}>
          <div>
            <Title level={3} className="title">
              <img
                key="rootloader"
                src={Logo}
                alt="no_image"
                className="login-logo"
              />
              Sign Up
            </Title>
            <div className="sub-title">Let us Help you find your Soulmate</div>
            <div style={{ padding: "1rem" }}>
              Fill in your basic details which will be used for login and
              communications
            </div>
          </div>
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleLogin}
            initialValues={{
              countryCode: "",
            }}
          >
            {basicRegistrationFormFields.map((Field, index) => {
              return (
                <React.Fragment key={index}>
                  <Form.Item
                    label={Field.label}
                    name={Field.name}
                    rules={Field.rules}
                    style={Field.style ? Field.style : {}}
                    {...(Field.feedBack && { hasFeedback: Field.feedBack })}
                    {...(Field.valuePropName && {
                      valuePropName: Field.valuePropName,
                    })}
                  >
                    {formFieldRenderer(Field, handleFormChanges)}
                  </Form.Item>
                </React.Fragment>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
                loading={loading}
              >
                Register Now
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="text"
            block
            className="border-Radius-5px block-button-text height-100p back-button"
            onClick={() => navigate("/")}
          >
            <CaretLeftOutlined />
            Back
          </Button>
        </div>
      </Col>

      <OtpModal
        otp={otp}
        setOtp={setOtp}
        isModalOpen={showOtpModal}
        confirmOTP={verifyOTP}
        loading={verifyLoading}
        subTitle={"Check your Phone and Fill the OTP"}
      />
    </Row>
  );
};

export default SignupIndex;
