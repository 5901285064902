import { Progress } from "antd";
import React from "react";

const Progressbar = ({
  colors = {
    "0%": "#FFE7EF",
    "100%": "#FEC6D8",
  },
  percent,
}) => {
  return (
    <>
      <Progress
        percent={percent}
        strokeColor={colors}
        showInfo={false}
        trailColor="#ccc"
        style={{
          padding: "5px",
          position: "sticky",
          top: 0,
          zIndex: "99",
        }}
      />
    </>
  );
};

export default Progressbar;
