export const personalDetailsFormFields = [
  {
    name: "gender",
    label: "Gender",
    fieldType: "genderTabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
  },
  {
    name: "dob",
    label: "Date of Birth",
    placeHolder: "",
    rules: [
      {
        required: true,
        message: "Please input your Date of Birth!",
      },
    ],
    fieldType: "datePicker",
  },
  {
    name: "height",
    label: "Height",
    placeHolder: "Select Height",
    rules: [
      {
        required: true,
        message: "Please input your Height!",
      },
    ],
    fieldType: "singleSelect",
    lookups: "height",
  },
  {
    name: "country",
    label: "Country",
    placeHolder: "Select Country",
    rules: [
      {
        required: true,
        message: "Please input your Country!",
      },
    ],
    fieldType: "singleSelect",
    apiLookups: "country",
  },
  {
    name: "state",
    label: "State",
    placeHolder: "Select State",
    rules: [
      {
        required: true,
        message: "Please input your State!",
      },
    ],
    fieldType: "singleSelect",
    apiLookups: "state",
  },
  {
    name: "city",
    label: "City",
    placeHolder: "Select City",
    rules: [
      {
        required: true,
        message: "Please input your City!",
      },
    ],
    fieldType: "singleSelect",
    apiLookups: "city",
  },
  {
    name: "residentialStatus",
    label: "Residential Status",
    placeHolder: "Select Residential Status",
    rules: [
      {
        required: true,
        message: "Please input your residential status!",
      },
    ],
    fieldType: "singleSelect",
    lookups: "residentialStatus",
  },
];
export const editPersonalDetailsFormFields = [
  {
    name: "religion",
    label: "Religion",
    fieldType: "singleSelect",

    placeHolder: "Select Religion",

    lookups: "religion",
  },
  {
    name: "motherTongue",
    label: "Mother Tongue",
    placeHolder: "Select Mother Tongue",

    fieldType: "singleSelect",
    lookups: "motherTongue",
  },
  {
    name: "country",
    label: "Country",
    placeHolder: "Select Country",

    fieldType: "singleSelect",
    apiLookups: "country",
  },
  {
    name: "state",
    label: "State",
    placeHolder: "Select State",

    fieldType: "singleSelect",
    apiLookups: "state",
  },
  {
    name: "city",
    label: "City",
    placeHolder: "Select City",

    fieldType: "singleSelect",
    apiLookups: "city",
  },

  {
    name: "income",
    label: "Income",
    fieldType: "singleSelect",
    placeHolder: "Enter your Income",

    lookups: "income",
  },
  {
    name: "cast",
    label: "Caste",
    placeHolder: "Select Caste",
    fieldType: "singleSelect",
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const castNoBar = getFieldValue("castNoBar");
          if (castNoBar || value) {
            return Promise.resolve();
          }
          if (!value) {
            return Promise.reject(new Error("Please select Caste!"));
          }
        },
      }),
    ],
    lookups: "casts",
    nestedLookups: "religion",
  },
  {
    name: "height",
    label: "Height",
    placeHolder: "Select Height",

    fieldType: "singleSelect",
    lookups: "height",
  },
];
export const editAboutFormFields = [
  {
    name: "description",
    label: "Tell us About YourSelf",
    placeHolder: "Tell us About YourSelf",

    fieldType: "textArea",
  },
  {
    name: "managedBy",
    label: "Profile Managed By",
    placeHolder: " Profile Managed By",

    fieldType: "singleSelect",
    lookups: "managedBy",
  },
  {
    name: "disability",
    label: "Disability",
    placeHolder: "Select Disability",

    fieldType: "singleSelect",
    lookups: "disability",
  },
  {
    name: "bodyType",
    label: "Body type",
    placeHolder: "Select Body type",

    fieldType: "singleSelect",
    lookups: "bodyType",
  },
  {
    name: "thalassemia",
    label: "Thalassemia",
    placeHolder: "Select Thalassemia",

    fieldType: "singleSelect",
    lookups: "thalassemia",
  },
  {
    name: "hivPositive",
    label: "HIV Positive",
    placeHolder: "Select HIV Positive",

    fieldType: "singleSelect",
    lookups: "single_decision",
  },
];
export const editEducationFormFields = [
  {
    name: "aboutEducation",
    label: "  About My Education",
    placeHolder: "About My Education",

    fieldType: "textArea",
  },
  {
    name: "qualification",
    label: "Highest Degree",
    placeHolder: "About My Highest Degree ",

    fieldType: "singleSelect",
    lookups: "highestEducation",
  },
  {
    name: "otherUGDegree",
    label: "Other UG Degree",
    fieldType: "input",
    placeHolder: "Enter Degree Name",
  },
  {
    name: "school",
    label: "School",
    fieldType: "input",
    placeHolder: "Enter School",
  },
];
export const editCareerFormFields = [
  {
    name: "aboutMyCareer",
    label: "  About My Career",
    placeHolder: "About My Career",

    fieldType: "textArea",
  },
  {
    name: "employed_in",
    label: "Employed In",
    placeHolder: "Select Employed In ",

    fieldType: "singleSelect",

    lookups: "employed_in",
  },
  {
    name: "occupation",
    label: "Occupation",
    placeHolder: "Select occupation",

    fieldType: "singleSelect",
    lookups: "occupation",
  },
  {
    name: "organisationName",
    label: "  Organisation",
    placeHolder: " Organisation",

    fieldType: "input",
  },
  {
    name: "interestedInSettlingAbroad",
    label: "Interested In Settling Abroad",
    placeHolder: "Select Interested Settling In Abroad",

    fieldType: "singleSelect",
    lookups: "single_decision",
  },
];
export const editFamilyFormFields = [
  {
    name: "aboutMyFamily",
    label: "  About My Family",
    placeHolder: "About My Family",
    rules: [],
    fieldType: "textArea",
  },
  {
    name: "familyStatus",
    label: "Family Status",
    fieldType: "singleSelect",

    placeHolder: "Select Family Status",

    lookups: "familyStatus",
  },
  {
    name: "familyType",
    label: "FamilyType",
    fieldType: "singleSelect",

    placeHolder: "Select family Type",

    lookups: "familyType",
  },
  {
    name: "familyValues",
    label: "Family Values",
    fieldType: "singleSelect",

    placeHolder: "Select family Values",

    lookups: "familyValues",
  },
  {
    name: "familyIncome",
    label: "Family Income",
    placeHolder: "Enter your Family Income",

    fieldType: "singleSelect",
    lookups: "income",
  },
  {
    name: "fatherOccupation",
    label: "Father Occupation",
    placeHolder: "Select Father Occupation",

    fieldType: "singleSelect",
    lookups: "fathersOccupation",
  },
  {
    name: "motherOccupation",
    label: "Mother Occupation",
    placeHolder: "Select Mother Occupation",

    fieldType: "singleSelect",
    lookups: "mothersOccupation",
  },
  {
    name: "brothers",
    label: "Brother(s)",
    fieldType: "singleSelect",

    placeHolder: "Select Brother",

    lookups: "siblings",
  },
  {
    name: "marriedBrothers",
    label: "Married Brothers",
    fieldType: "singleSelect",

    placeHolder: "Select Married Brother",

    lookups: "siblings",
  },
  {
    name: "sisters",
    label: "Sister(s)",
    fieldType: "singleSelect",

    placeHolder: "Select Sister",

    lookups: "siblings",
  },
  {
    name: "marriedSisters",
    label: "Married Sisters",
    fieldType: "singleSelect",

    placeHolder: "Select Married Sister",

    lookups: "siblings",
  },
  {
    name: "gothra",
    label: "Gothra",
    placeHolder: "Enter Gothra",
    fieldType: "input",
    lookups: "gothra",
  },
  {
    name: "livingWithParents",
    label: "I am Living with Parents",
    placeHolder: "Select",

    fieldType: "singleSelect",
    lookups: "livingWithParents",
  },
  {
    name: "familyBasedOutOf",
    label: "My Family Based Out of",
    placeHolder: "Select",

    fieldType: "singleSelect",
    apiLookups: "country",
  },
];
export const editContactDetailsFormFields = [
  {
    name: "email_id",
    label: "  Email_Id",
    placeHolder: "Email Id",

    fieldType: "input",
  },
  {
    name: "phoneNumber",
    label: "  Phone Number",
    placeHolder: "Phone number",

    fieldType: "input",
  },

  {
    name: "altMobileNumber",
    label: " Alternate Mobile Number",
    placeHolder: "Enter Mobile Number",

    fieldType: "input",
  },
  {
    name: "alternateEmail",
    label: " Alternate Email Id",
    placeHolder: "Enter Email Id",

    fieldType: "input",
  },
  {
    name: "landline",
    label: "Landline No",
    placeHolder: "Enter Landline No",

    fieldType: "input",
  },
];
export const editHoroscopeFormFields = [
  {
    name: "rashi",
    label: "Rashi",
    placeHolder: "Select Rashi",

    fieldType: "singleSelect",
    lookups: "rashi",
  },
  {
    name: "nakshatra",
    label: "Nakshatra",
    placeHolder: "Nakshatra",

    fieldType: "singleSelect",
    lookups: "nakshatra",
  },

  {
    name: "country",
    label: "Country Of Birth",
    placeHolder: "Select Country Of Birth",

    fieldType: "singleSelect",
    apiLookups: "country",
  },
  {
    name: "state",
    label: "State Of Birth",
    placeHolder: "Select State Of Birth",

    fieldType: "singleSelect",
    apiLookups: "state",
  },
  {
    name: "city",
    label: "City Of Birth",
    placeHolder: "Select City Of Birth",

    fieldType: "singleSelect",
    apiLookups: "city",
  },
  {
    name: "timeOfBirth",
    label: "Time Of Birth",
    placeHolder: "Select Time Of Birth",

    fieldType: "input",
    lookups: "timeOfBirth",
  },
  {
    name: "horoscope",
    label: "Horoscope",
    placeHolder: "Select Horoscope",

    fieldType: "singleSelect",
    lookups: "horoscopes",
  },

  {
    name: "manglik",
    label: "Manglik",
    fieldType: "singleSelect",
    placeHolder: "Select",

    lookups: "manglik",
  },
];
export const editLifeStyleFormFields = [
  {
    name: "dietaryHabits",
    label: "Dietary Habits",
    placeHolder: "Dietary Habits",

    fieldType: "singleSelect",
    lookups: "dietaryHabits",
  },
  {
    name: "drinkingHabits",
    label: "Drinking Habits",
    placeHolder: "Drinking Habits",

    fieldType: "singleSelect",
    lookups: "drinkingHabits",
  },
  {
    name: "smokingHabits",
    label: "Smoking Habits",
    placeHolder: "Smoking Habits",
    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "ownAHouse",
    label: "Own  a House?",
    placeHolder: "Own House?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "ownACar",
    label: "Own A Car?",
    placeHolder: "Own Car?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "openToPets",
    label: "Open To Pets?",
    placeHolder: "Open To Pets?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "languages",
    label: "Language Known",
    placeHolder: " Language Known",

    fieldType: "multiSelect",
    lookups: "motherTongue",
  },
  {
    name: "hobbies",
    label: "Hobbies",
    placeHolder: "Hobbies",

    fieldType: "multiSelect",
    lookups: "hobbies",
  },
  {
    name: "interest",
    label: "Interests",
    placeHolder: "interests",

    fieldType: "multiSelect",
    lookups: "interests",
  },

  {
    name: "foodICook",
    label: "Food I Cook",

    placeHolder: "Food I Cook",

    fieldType: "input",
  },
  {
    name: "favMusic",
    label: "Favourite Music",
    placeHolder: "Favourite Music",

    fieldType: "multiSelect",
    lookups: "music",
  },
  {
    name: "favRead",
    label: "Favourite Read",
    fieldType: "input",
    placeHolder: "Favourite Read",
  },
  {
    name: "dress",
    label: "Dress Style",
    placeHolder: "Dress Style",

    fieldType: "multiSelect",
    lookups: "dressStyle",
  },

  {
    name: "sports",
    label: "Sports",
    placeHolder: "Sports",

    fieldType: "multiSelect",
    lookups: "sports",
  },
  {
    name: "books",
    label: "Favourite Books",
    placeHolder: "Favourite Books",

    fieldType: "multiSelect",
    lookups: "books",
  },
  {
    name: "cuisine",
    label: "Favourite Cuisine",
    placeHolder: "Favourite Cuisine",

    fieldType: "multiSelect",
    lookups: "cuisines",
  },
  {
    name: "movies",
    label: "Favourite Movies",
    fieldType: "input",
    placeHolder: "Favourite Movies",
  },
  {
    name: "favTVShow",
    label: " Favourite TV Show",
    fieldType: "input",
    placeHolder: "Favourite TV Show",
  },
  {
    name: "vacayDestination",
    label: "Vacation Destination",
    fieldType: "input",
    placeHolder: "Vacation Destination",
  },
];
