export const familyDetailsFormFields = [
  {
    name: "familyStatus",
    label: "Family Status",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "familyStatus",
  },
  {
    name: "familyValues",
    label: "Family Values",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "familyValues",
  },
  {
    name: "familyType",
    label: "FamilyType",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "familyType",
  },
  {
    name: "familyIncome",
    label: "Family Income",
    placeHolder: "Enter your Family Income",

    fieldType: "singleSelect",
    lookups: "income",
  },
  {
    name: "fatherOccupation",
    label: "Father Occupation",
    placeHolder: "Select Father Occupation",

    fieldType: "singleSelect",
    lookups: "fathersOccupation",
  },
  {
    name: "motherOccupation",
    label: "Mother Occupation",
    placeHolder: "Select Mother Occupation",

    fieldType: "singleSelect",
    lookups: "mothersOccupation",
  },
  {
    name: "brothers",
    label: "Brother(s)",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "siblings",
  },
  {
    name: "marriedBrothers",
    label: "Married Brothers",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "siblings",
  },
  {
    name: "sisters",
    label: "Sister(s)",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "siblings",
  },
  {
    name: "marriedSisters",
    label: "Married Sisters",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your Full Name!",
    //   },
    // ],
    lookups: "siblings",
  },
  {
    name: "gothra",
    label: "Gothra",
    placeHolder: "Enter Gothra",
    fieldType: "input",
    lookups: "gothra",
  },
  {
    name: "livingWith",
    label: "I am Living with Parents",
    placeHolder: "Select",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "singleSelect",
    lookups: "livingWithParents",
  },
  {
    name: "familyBased",
    label: "My Family Based Out of",
    placeHolder: "Select",
    // rules: [
    //   {
    //     required: true,
    //     message: "Please input your mother occupation!",
    //   },
    // ],
    fieldType: "singleSelect",
    apiLookups: "country",
  },
];
