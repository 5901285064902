import React, { useEffect, useState } from "react";
import {
  Badge,
  Avatar,
  Space,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Typography,
  Form,
  Input,
  Layout,
  List,
  Row,
  theme,
} from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import Icon from "@ant-design/icons/lib/components/Icon";
import {
  getInterestsRecv,
  getMyUnlockedProfile,
  getallignoredprofile,
  getblockprofile,
  getignoreprofile,
  getmyblockedprofile,
  getprofiledeclined,
  getusersdeclinedmyintrest,
  getuserssentintrest,
} from "../helperFunctions/endpoints";
import { useLocation } from "react-router-dom";
import NavBar from "../genericComponents/CustomNav";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BsArrowCounterclockwise } from "react-icons/bs";
import theyDeclinedimg from "../images/theydeclined.png";
import shortProfileIconimg from "../images/short-profile-icon.png";
import ignoredProfileIconimg from "../images/ignore-profile-icon.png";
import blockedProfileIconimg from "../images/block-profile-icon.png";
import InterestRecIconimg from "../images/interest-rec-icon.png";
import DeclineIconimg from "../images/decline-icon.png";
import Acceptanceimg from "../images/accept-icon.png";

const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const Notification = () => {
  const { state } = useLocation();
  const { Text } = Typography;
  const navigate = useNavigate();
  const [interestRecvData, setInterestRecvData] = useState([]);
  const [interestSentData, setInterestSentData] = useState([]);
  const [ideclinedProfiles, setideclinedProfiles] = useState([]);
  const [unlockedProfiles, setunlockedProfiles] = useState([]);
  const [theyDeclined, settheyDeclined] = useState([]);
  const [shortlisted, setshortlisted] = useState([]);
  const [ignored, setignored] = useState([]);
  const [blocked, setblocked] = useState([]);
  const { notificationObjCount } = state || {};

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    // getInterestSentAPI();
    // getInterestRecvAPI();
    // getunlockedProfilesAPI();
    // getIdeclinedProfilesAPI();
    // getTheyDeclinedAPI();
    // getShotlistedAPI();
    // getIgnoredAPI();
    // getBlockedAPI();
    console.log("--->>>>notificationObjCount", notificationObjCount);
  }, []);
  const onInterestClick = (title, data) => {
    navigate("/allprofiles", { state: { title: title, data: data } });
  };

  const getInterestSentAPI = () => {
    getuserssentintrest().then((res) => {
      if (res?.code === "CH200") {
        setInterestSentData(res?.filteredProfiles);
      }
    });
  };
  const getunlockedProfilesAPI = () => {
    getMyUnlockedProfile().then((res) => {
      if (res?.code === "CH200") {
        setunlockedProfiles(res?.filteredProfiles);
      }
    });
  };
  const getIdeclinedProfilesAPI = () => {
    getprofiledeclined().then((res) => {
      if (res?.code === "CH200") {
        setideclinedProfiles(res?.filteredProfiles);
      }
    });
  };

  const getTheyDeclinedAPI = () => {
    getusersdeclinedmyintrest().then((res) => {
      if (res?.code === "CH200") {
        settheyDeclined(res?.filteredProfiles);
      }
    });
  };

  const getShortlistedAPI = () => {
    getusersdeclinedmyintrest().then((res) => {
      if (res?.code === "CH200") {
        setshortlisted(res?.shortlistedProfilesData);
      }
    });
  };

  const getInterestRecvAPI = () => {
    getInterestsRecv().then((res) => {
      if (res?.code === "CH200") {
        setInterestRecvData(res?.filteredProfiles);
      }
    });
  };
  const getIgnoredAPI = () => {
    getallignoredprofile().then((res) => {
      if (res?.code === "CH200") {
        setignored(res?.ignoreListData);
      }
    });
  };
  const getBlockedAPI = () => {
    getmyblockedprofile().then((res) => {
      if (res?.code === "CH200") {
        setblocked(res?.filteredProfiles);
      }
    });
  };
  // const menu = (props:{interestApi:})

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/interestreceived")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",
                  marginRight: 20,
                }}
              >
                <img
                  src={InterestRecIconimg}
                  height={24}
                  width={24}
                  style={{
                    marginTop: -5,
                    borderRadius: 50,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
                &nbsp; &nbsp; Interests Received
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="center"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.interestReceived || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/interestsent")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={Acceptanceimg}
                  height={24}
                  width={24}
                  style={{
                    marginTop: -5,
                    borderRadius: 50,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
                &nbsp; &nbsp; Interests Sent
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="center"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.interestSent || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/unlockedprofiles")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={InterestRecIconimg}
                  height={25}
                  width={25}
                  style={{
                    marginTop: -5,
                    borderRadius: 50,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
                &nbsp; &nbsp; Unlocked Profiles
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.unlocked || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/ideclined")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={DeclineIconimg}
                  style={{ width: 24, height: 24, marginTop: -5 }}
                />
                &nbsp; &nbsp; I Declined
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",

                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.iDeclined || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/theydeclined")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={theyDeclinedimg}
                  style={{ width: 24, height: 24, marginTop: -5 }}
                />
                &nbsp; &nbsp; They Declined
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.theyDeclined || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/shortlist")}
          >
            {" "}
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={shortProfileIconimg}
                  style={{ width: 24, height: 24, marginTop: -5 }}
                />
                &nbsp; &nbsp; Shortlisted Profiles
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.shortlisted || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/ignored")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={ignoredProfileIconimg}
                  style={{ width: 24, height: 24, marginTop: -5 }}
                />
                &nbsp; &nbsp; Ignored Profiles
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.ignored || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{ marginLeft: "10px", marginTop: 30 }}
            onClick={() => navigate("/profileslist/blocked")}
          >
            <Col span={14}>
              <strong
                style={{
                  display: "flex",
                  flexDirection: "center",

                  marginRight: 20,
                }}
              >
                <img
                  src={blockedProfileIconimg}
                  style={{ width: 24, height: 24, marginTop: -5 }}
                />
                &nbsp; &nbsp; Blocked Profiles
              </strong>
            </Col>
            <Col span={10}>
              <Space
                size="middle"
                style={{
                  display: "flex",
                  alignContent: "left",
                  marginLeft: "80%",
                }}
              >
                <Badge
                  count={notificationObjCount?.blocked || ""}
                  color="green"
                ></Badge>
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};
