export const careerDetailsFormFieldsOne = [
  {
    name: "education",
    label: "Education",
    fieldType: "singleSelect",
    defaultValueGetter: "",
    placeHolder: "Select Education Qualification",
    rules: [
      {
        required: true,
        message: "Please input your education qualification!",
      },
    ],
    lookups: "highestEducation",
  },
  {
    name: "otherPgDegree",
    label: "Other UG Degree",
    fieldType: "input",
    placeHolder: "Enter Degree Name",
    rules: [
      // {
      //   required: true,
      //   message: "Please input your other degrees!",
      // },
    ],
  },
];

export const careerDetailsFormFieldsTwo = [
  {
    name: "employedIn",
    label: "Employed In",
    fieldType: "tabList",
    defaultValueGetter: "",
    placeHolder: "",
    rules: [
      {
        required: true,
        message: "Please input your Employement status!",
      },
    ],
    lookups: "employed_in",
  },
  {
    name: "occupation",
    label: "Occupation",
    placeHolder: "Select Occupation",
    rules: [
      {
        required: true,
        message: "Please input your occupation!",
      },
    ],
    fieldType: "singleSelect",
    lookups: "occupation",
  },
  {
    name: "income",
    label: "Income",
    fieldType: "singleSelect",
    placeHolder: "Enter your Income",
    rules: [
      {
        required: true,
        message: "Please input your income!",
      },
    ],
    lookups: "income",
  },
];
