const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};
export async function getFromLocalStorage(key) {
  try {
    const item = await asyncLocalStorage.getItem(key);
    if (!item) return null;
    return item;
  } catch (error) {
    console.error("Error retrieving data from localStorage:", error);
    return null;
  }
}
export async function setInLocalStorage(key, data) {
  try {
    await asyncLocalStorage.setItem(key, data);
    return "Ok";
  } catch (error) {
    console.error("Error storing data in localStorage:", error);
    throw new Error(error);
  }
}
export async function removeInLocalStorage(key) {
  try {
    await localStorage.removeItem(key);
    return "Ok";
  } catch (error) {
    console.error("Error Removing data from localStorage:", error);
    throw new Error(error);
  }
}
