import React from "react";
import { Card, Carousel, Layout, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
const { Header, Content, Footer } = Layout;

export const TermsConditions = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div>
            <Text className="midtitle">
              {state?.title || "Terms Conditions"}
            </Text>
          </div>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <p>
              <br />
              <br />
              <b>Terms and Conditions </b>
              <br />
              <br />
              This document is an electronic record in terms of the Information
              Technology Act, 2000 and rules there under pertaining to
              electronic records as applicable and amended. This electronic
              record is generated by a computer system and does not require any
              physical or digital signatures. This document is published in
              accordance with the provisions of Rule 3 (1) of the Information
              Technology (Intermediaries Guidelines) Rules, 2011 that require
              publishing the rules and regulations, privacy policy and the terms
              and conditions for access or usage of CONNECTING HEARTS.
              <br />
              <br />
              PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY
              BEFORE USING CONNECTING HEARTS. ACCESSING, BROWSING OR OTHERWISE
              USING THE APPLICATION IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL
              <br />
              <br />
              THESE TERMS AND CONDITIONS (.Agreement.). If you do not want to be
              bound by the Terms and Conditions, you must not use Connecting
              hearts Services. The Terms and Conditions also include the
              applicable policies which are incorporated herein by way of
              reference and as amended from time to time (the.Terms and
              conditions.).
              <br />
              <br />
              In these Terms, references to "Connecting hearts member. shall
              mean the end user accessing Connecting hearts services its
              contents or using the Connecting hearts Services offered.
              Connecting hearts member also includes persons such as father,
              mother, brother, sister, relative or a friend (.Registrant.) of
              the prospective bride/bridegroom. The terms You. and.User. shall
              be interchangeably used for member. CIN: U93090UP2020PTC133241, a
              company under the provisions of companies act, 2013 and having its
              registered office at PLOT NO. CN-21 IIM ROAD ALLU NAGAR DIGURIA,
              Pincode-226020.
              <br />
              <br />
              <b>Scope</b>
              <br />
              <br />
              You understand and acknowledge that Connecting hearts acts as an
              Intermediary. as defined under clause (1) sub-clause (w) of
              Section 2 of the Information Technology Act, 2000.
              <br />
              <br />
              Connecting hearts acts as a platform to enable any user to
              themselves register on it (by filling the mandatory fields and
              optional fields, if any) to voluntarily search for profile(s) from
              the database of Connecting hearts already registered users, for
              seeking prospective lawful matrimonial alliances for themselves.
              However, you must have a valid/ operational mobile phone number
              and an email id. The profiles in the database of Connecting hearts
              are classified broadly on the basis of language and region for the
              ease and convenience of its member/customer.
              <br />
              <br />
              Connecting hearts Members are provided with free/paid access for
              searching profiles from the database of Connecting hearts, as per
              the partner preference set by you (on the Applications - (App) and
              you can shortlist the profiles in which you are interested.
              <br />
              <br />
              <b>Eligibility</b>
              <br />
              <br />
              Connecting hearts membership are rights of admission are reserved
              solely for Members Globally
              <br />
              <br />
              Further in capacity as Connecting hearts member you confirm that
              you are:
              <br />
              <br />
              18 years or above (if you are a woman) or 21 years or above (if
              you are a man);
              <br />
              <br />
              If you have applied for Divorce, you may register or our App
              (connecting hearts) by stating. Awaiting Divorce..
              <br />
              <br />
              If you are a resident of any other Country, you are legally
              competent to marry as per the local rules applicable to your
              country and you shall comply with the Indian laws for marrying a
              person of Indiar Origin
              <br />
              <br />
              In case you are a registrant of the prospective bride / groom and
              has created profile in Connectin hearts App on behalf of them or
              is accessing the Connecting hearts App on behalf of them implies
              that you have taken their consent for their profile creation in
              Connecting hearts and for accessing the Connecting hearts App.
              <br />
              <br />
              <b>Registration</b>
              <br />
              <br />
              We expect that you would complete the online registration process
              with fairness and honesty in furnishing true, accurate, current,
              complete information and with providing recent photos of you which
              will help you to meet your parameters. We expect you to read the
              relevant column before keying in the details or selecting the
              option available or uploading the photo. You are requeste not to
              key in details of the profile in field other than the applicable
              field (including mentioning id.s) of other platforms / App or
              repeating your details in another fields, after filling them once
              in the relevan fields or others photographs. In order to serve you
              better if Connecting hearts requires additional details you agree
              to provide it.
              <br />
              <br />
              If at any point of time Connecting hearts comes to know, or is so
              informed by third party or has reasor to believe that any
              information provided by you for registration (including photos) or
              <br />
              <br />
              otherwise is found to be untrue, inaccurate, or incomplete,
              Connecting hearts shall have full right to suspend or terminate
              (without any notice) your Connecting hearts membership and forfeit
              any amount paid by you towards Connecting hearts membership fee
              and refuse to provide Connecting hearts service to you thereafter.
              <br />
              <br />
              Connecting hearts also reserves the right to block the
              registration of your profile on App, if any, in the case of your
              contact details/links being entered in irrelevant fields or if
              there are errors in any data entered by the Connecting hearts
              members in their profile.
              <br />
              <br />
              Registration of duplicate profiles of the same person is not
              allowed in Connecting hearts App. Connecting hearts shall have
              full right to suspend or terminate (without any notice) such
              duplicate profile.
              <br />
              <br />
              You acknowledge and confirm that your registration with Connecting
              hearts and the usage of Connecting hearts also reserves the right
              to block the registration of your profile on App. In the case of
              your contact details/links being entered in irrelevant fields or
              if there are errors in any data entered by the Connecting hearts
              members in their profile services is with the bonafide intention
              of marriage and not otherwise. Connecting hearts Membership is
              restricted strictly to the registered Connecting hearts individual
              member only. Organizations, companies, businesses and/ or
              individuals carrying on similar or competitive business cannot
              become Members of Connecting hearts and nor use the Connecting
              hearts Service or Connecting hearts members data for any
              commercial purpose, and Connecting hearts reserves its right to
              initiate appropriate legal actior for breach of these obligation.
              <br />
              <br />
              <b>Account Security</b>
              <br />
              <br />
              You are responsible for safeguarding the confidentiality of your
              Connecting hearts login credentials such as your user id,
              password, OTP, etc., and for restricting access to your mobile to
              prevent unauthorized access to your account. We, as a Company do
              not ask for Password and you are cautioned not to share your
              password to any persons. You agree to accept responsibility for
              all activities that occur in your account.
              <br />
              <br />
              <b>Role and Responsibility of Connecting hearts</b>
              <br />
              <br />
              Connecting hearts reproduces your details once you register on our
              App on as is as available. basis and also share your profile with
              other registered Connecting hearts members within app
              <br />
              <br />
              Connecting Heart's obligation is only to provide an interface to
              its registered members to search their prospect themselves without
              any assistance.
              <br />
              <br />
              The profile search conducted by any Connecting hearts member and
              the matches shown thereof are automatically gene*99*rated by
              Connecting hearts, and based on the partner preference set by you.
              In the event of Connecting hearts member changing their partner
              preference on the App, then the automated system generated
              prospect results of the App may also undergo corresponding change.
              <br />
              <br />
              <b>results of the App may also undergo corresponding change.</b>
              <br />
              <br />
              Connecting hearts does not prohibit any Connecting hearts member
              from sending interest to your profile or communicating to you
              based on their partner preference. But you are at the liberty to
              deny their interest or proceed further if you are interested.
              <br />
              <br />
              Connecting hearts cannot guarantee or assume responsibility for
              any specific results from the use of the data available from the
              Connecting hearts service or from other matrimonial app.
              <br />
              <br />
              Connecting hearts shall safeguard sensitive user information using
              security standards, authentication mechanisms, access controls and
              encryption techniques.
              <br />
              <br />
              Connecting hearts cannot guarantee the complete protection of user
              data while it is in transit, or prevent any tampering of the data
              by a third party with malicious intent before the data reaches the
              Connecting hearts servers.
              <br />
              <br />
              Connecting hearts do not authenticate/ endorse any information of
              any profile and hence you as a user need to verify the credentials
              and information provided by other users.
              <br />
              <br />
              <b> Role and Responsibility of Connecting hearts Member</b>
              <br />
              <br />
              You, shall safeguard your profile information by creating a strong
              password during profile creation with combination of alphabets,
              both upper and lower case and numbers.
              <br />
              <br />
              Any information / data required by Connecting hearts for using
              it's services shall be provided by the Connecting hearts Member,
              as and when so sought by connecting hearts.
              <br />
              <br />
              You are requested to verify the credentials of the prospect,
              exercise due care and caution regarding their profile information
              which includes marital status, educational qualifications,
              financial status, occupation, character, health status, etc. and
              satisfy yourself before making a choice of your match. Connecting
              hearts shall not be liable for short coming due to any
              misrepresentations made by any of its Connecting hearts members.
              <br />
              <br />
              To get better search results, Connecting hearts Members are
              expected to provide latest photograph which should not be more
              than 3 (three) months old. Providing old photographs/ photographs
              of others, inaccurate / false information shall be treated as
              violation of terms and conditions and Connecting hearts shall
              retain their right under clause 2 (b) of this terms and
              conditions.
              <br />
              <br />
              Connecting hearts members are expected to disclose their health
              records during profile enrolment which includes any pre-existing
              illness, physical disability etc. Non - disclosure at the time of
              enrolment shall be treated as violation of the terms and
              conditions and Connecting hearts shall retain their right under
              clause 2 (b) of this terms and conditions.
              <br />
              <br />
              <b>
                {" "}
                Connecting hearts Members are advised to refrain from:
              </b>{" "}
              <br />
              <br />
              Entering into any financial transactions with prospects Connecting
              hearts Members shall not seek financial help or provide financial
              help from / to the other Connecting hearts Members.
              <br />
              <br />
              Using abusive language when they communicate with the other
              Connecting hearts <br />
              <br />
              Being discriminative or using racial comments etc.
              <br />
              <br />
              Sharing of confidential and personal data with eac other but not
              limited to sharing of bank details, etc.
              <br />
              <br />
              Entering into physical relationship with any prospect before
              marriage.
              <br />
              <br />
              Violating any law for the time being in force.
              <br />
              <br />
              Vii. From mentioning details of other matrimonial services while
              sending personalized messages
              <br />
              <br />
              Connecting hearts members are expected to Mail us at
              connectinghearts.info@gmail.com for understanding the safety
              measures on partner search and awareness towards online safety.
              <br />
              <br />
              Connecting hearts Members are expected to be cautious of prospects
              who ask for favours, money etc. or call from multiple phone
              numbers, only interact over phone, doesn.t come forward for face
              to face meeting (physically or through video calls) and don't
              involve family and friends in match making.
              <br />
              <br />
              The Connecting hearts members agrees that for getting effective
              search results of prospects on App you will regularly log in to
              the profile maintaine in the App and send expression of interest
              which is an automated messages to prospects as a free Connecting
              hearts member, and in the event you have availed paid Connecting
              hearts package, you can to show your expression of interest/
              replies. Connecting hearts member also may review the expression
              of interest sent to you / read the messages sent to you by
              prospects and may respond suitably.
              <br />
              <br />
              You also agree that while accessing / using the App, you will be
              guided by the terms and condition of the App including but not
              limited to the use of feature of App like chat instantly with
              prospect, enhancing privacy settings (photo/horoscope/ phone
              number) or for viewing social and professional profile of members
              on their Facebook, LinkedIn, etc
              <br />
              <br />
              If the Connecting hearts Member fails to update in Connecting
              hearts app, any change in the particulars furnished in their
              profile/ then the Connecting hearts membership may be suspendec by
              Connecting hearts at its sole discretion.
              <br />
              <br />
              Connecting hearts member shall, in case of finalization of
              his/her/their Registrant's marriage, delete their profile by
              themselves or intimate Connecting hearts for removal/deletion of
              their profile.
              <br />
              <br />
              Connecting hearts Members agree to use secure devices, software
              and networks in a private place for accessing the Connecting
              hearts services
              <br />
              <br />
              Connecting hearts member shall not use any kind of Bots like web
              robots, Chabot or any other automated processes/programs/scripts
              to use, communicate or respond in Connecting hearts App.
              <br />
              <br />
              Connecting hearts Members shall not probe, scan or test the
              vulnerabilities of the Connecting hearts App or any network
              connected to the App nor breach the security measures instituted
              by connecting hearts.
              <br />
              <br />
              You shall also not make any payment / transfer any amount to
              account of Connecting hearts employees. All payments shall be made
              only to Connecting hearts account.
              <br />
              <br />
              You also agree that while accessing / using the App, you will be
              guided by the terms and condition of the App including but not
              limited to the use of feature of App like chat instantly with
              prospect, enhancing privacy settings (photo/horoscope/ phone
              number) or for viewing social and professional profile of members
              on their Facebook, LinkedIn, etc
              <br /> <br />
              If the Connecting hearts Member fails to update in Connecting
              hearts app, any change in the particulars furnished in their
              profile/ then the Connecting hearts membership may be suspendec by
              Connecting hearts at its sole discretion.
              <br /> <br />
              Connecting hearts member shall, in case of finalization of
              his/her/their Registrant's marriage, delete their profile by
              themselves or intimate Connecting hearts for removal/deletion of
              their profile.
              <br /> <br />
              Connecting hearts Members agree to use secure devices, software
              and networks in a private place for accessing the Connecting
              hearts services
              <br /> <br />
              Connecting hearts member shall not use any kind of Bots like web
              robots, Chabot or any other automated processes/programs/scripts
              to use, communicate or respond in Connecting hearts App.
              <br /> <br />
              Connecting hearts Members shall not probe, scan or test the
              vulnerabilities of the Connecting hearts App or any network
              connected to the App nor breach the security measures instituted
              by connecting hearts.
              <br /> <br />
              You shall also not make any payment / transfer any amount to
              account of Connecting hearts employees. All payments shall be made
              only to Connecting hearts account.
              <br /> <br />
              R. WE (Connecting hearts) DO NOT ENCOURAGE HOROSCOPE AND KUNDALI.
              <br /> <br />
              Medium of Communication to Connecting hearts Members
              <br /> <br />
              A. Connecting hearts member hereby consents for receiving
              communication including promotional communications through any
              medium of communications such as electronic mails, calls, SMS or
              through WhatsApp messages from Connecting hearts
              <br /> <br />
              B. Connecting hearts by its automatic system sends to its member
              (s) profile (s) of the prospect by way of
              images/documents/messages/ links.Connecting hearts member confirms
              that the mobile number submitted to it for verification or
              alternative number if any provided, is not registered with the Do
              Not Disturb / National Customer Preference Register and they shall
              not initiate any complaint. Connecting hearts Member further
              confirms that even if Connecting hearts Member is registered with
              the telecom service provider under the category Do Not Disturb /
              National Customer Preference Register the calls from Connecting
              hearts either to the verified mobile number or alternative number
              if any provided shall not be treated as promotional calls.
              <br /> <br />
              <b> Confidentiality</b>
              <br /> <br />
              7%
              <br /> <br />
              Any feedback you provide to Connecting hearts shall be deemed to
              be non-confidential. Connecting hearts shall be free to use such
              feedback/information on an unrestricted basis. Further, by
              submitting the feedback, you represent and warrant that (i) your
              feedback does not contain confidential or proprietary information
              of yourself or third parties; (ii) Connecting hearts member is not
              under any obligation of confidentiality, express or implied, with
              respect to the feedback; (iii) you are not entitled to any
              compensation or reimbursement of any kind from Connecting hearts
              for the feedback under any circumstances.
              <br /> <br />
              <b> Privacy of Membership</b>
              <br /> <br />
              To protect your privacy and understand our practices as amended
              from time to time, please read and follow our Privacy Policy which
              also governs your visit to connecting hearts, the personal
              information / data provided to us by you during the course of
              usage of Connecting hearts will be treated as confidential and in
              accordance with the Privacy policy and applicable laws and
              regulations. If you object to your information being transferred
              or used, please do not use the app.
              <br /> <br />
              <b>Disputes between Members</b>
              <br /> <br />
              Connecting hearts Members are solely responsible for the
              communications (through any medium) with prospect or vice versa.
              Connecting hearts expressly disclaims any responsibility or
              liability for any monetary transaction(s) or exchange(s) or
              interaction(s) or passing of information(s) etc. between any
              Connecting hearts members inter se via e-mail, chat, interaction,
              WhatsApp or any othe medium of communication between Connecting
              <br /> <br />
              <b> hearts members App</b>
              <br />
              <br />
              Connecting hearts has no obligation, to monitor any such disputes
              arising between the Connecting hearts members, and Connecting
              hearts shall not be party to any such dispute/litigation etc.
              <br />
              <br />
              Connecting hearts is not a broker or the agent of any Connecting
              hearts member, and Connecting hearts does not partake in the
              exchange of any kind of discussion between the Connecting hearts
              members and prospects or the results of their discussion.
              <br />
              <br />
              <b> Limitation of liability</b>
              <br />
              <br />
              SRCM/Heartfulness, Connecting hearts or its Office bearers shall
              under no circumstances be liable or responsible to the Connecting
              hearts member or his/her authorized Representative or Registrant
              or any third party for any direct, indirect special, exemplary,
              incidental, or consequential damages of any character including,
              without limitation, damages resulting from the use of our App/
              Third Party Website / Connecting hearts services.
              <br />
              <br />
              Connecting hearts informs you that the exchange of profile(s)
              through or by Connecting hearts should not in any way be construed
              as a matrimonial offer and/or recommendation and / or advice or
              guarantee given to the Connecting heart member, from/ or by
              connecting hearts SRCM/ Heartfulness.
              <br />
              <br />
              Notwithstanding anything to the contrary contained herein,
              connecting heart's liability to you for any cause whatsoever, and
              regardless of the form of the action, will at all times be limited
              to the amount paid, if any, by you to connecting hearts, for any
              specific Connecting hearts paid package, and no further.
              <br />
              <br />
              Connecting hearts SRCM/Heartfulness will not be liable in case of
              any wrong/improper match made due to any reason.
              <br />
              <br />
              SRCM/Heartfulness, Connecting hearts or its office bearers shall
              under no circumstances be liable, if any, for the Connecting
              hearts member entering into financial transaction with any other
              Connecting hearts Member or any third party.
              <br />
              <br />
              Connecting hearts or its office bearers shall under no
              circumstances be liable, if any, for any Connecting hearts members
              not responding/ reciprocating when you approach them for
              matrimonial alliance
              <br />
              <br />
              <b> Class Action Suits</b>
              <br />
              <br />
              You acknowledge and confirm that you will not bring or participate
              in any class action or other class proceeding in connection with
              any dispute with connecting hearts or SRCM/Heartfulness. Further
              neither you nor Connecting hearts or SRCM/Heartfulness agrees to
              class arbitration
              <br />
              <br />
              <b>General</b>
              <br />
              <br />
              In the event you file a false complaint against another prospect
              on our App and consequently we have suspended/deleted that
              prospects profile based on your complaint, then we reserve our
              right to initiate appropriate legal (Civil/Criminal) action
              against you and claim any and all costs expenses from you, for
              such irresponsible/ misrepresentation/illegal/unlawful action. We
              also reserve our right to suspend your profile and forfeit any and
              all amounts paid by you for the Connectin hearts services as per
              clause 2 (b) of these terms and conditions.
              <br />
              <br />
              Notwithstanding anything contained herein, Connecting hearts
              reserves the absolute right to delete, in any manner as it deems
              fit, any content c any profile listing placed on connecting heart
              app (once such instance come to Connecting hearts notice) in order
              to ensure, that proper consent has been obtained by you, prima
              facie accuracy and the prevailing laws in force for the time
              being, especially those relating to providing any obscene,
              libelous, blasphemous, slanderous, defamatory or invasive of
              another person's (deceased or alive) right of privacy or
              publicity, or that may reasonably be deemed to be harmful, vulgar,
              pornographic, abusive, harassing, threatening, hateful,
              objectionable with respect to race, religion creed, nationality,
              gender or otherwise unfit for reproduction; or suggests or
              encourages unfair or illegal/indecent, unlawful activity.
              <br />
              <br />
              Connecting hearts also reserves the right to block/delete /
              suspend the profile which might be offensive, illegal or that
              might violate the rights, harm or threaten the safety of our
              office bearers/ employees (including undue communication with any
              employee) and/or other registered prospects or using our
              Connecting hearts App as a means of communication.
              <br />
              <br />
              Once your paid membership expires, you cannot avail the unexpired
              balance phone call count/ unexpired SMS. Similarly, you cannot
              access the already viewed Connecting hearts member(s) contact
              information unless you renew your accoun within 30 days
              <br />
              <br />
              However on renewal, the unexpired phone call / SMS shall be
              carried forward to your account from the last day of expiry.
              <br />
              <br />
              In case of conflict between the terms and condition of App and
              terms and conditions of any other website including other
              websites, the terms and condition of Connecting hearts App shall
              prevail fo the service provided through this App.
              <br />
              <br />
              <b>Disclaimer</b>
              <br />
              <br />
              YOU UNDERSTAND AND AGREE THAT THE CONNECTINGHEARTS SERVICES ARE
              PROVIDED TO YOU ON AN.AS IS. AND.AS AVAILABLE. BASIS. WITHOUT
              LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW,
              CONNECTINGHEARTS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAF PURPOSE, OR NON-
              <br />
              <br />
              <b>INFRINGEMENT</b>
              <br />
              <br />
              Connecting hearts does not warrant that the App, its servers, or
              e-mail sent from Connecting hearts are free of viruses or other
              harmful components. Connecting hearts will not be liable for any
              damages of any kind arising from the use of this App, including,
              but not limited to direct, indirect, incidental, punitive, and
              consequential damages.
              <br />
              <br />
              Connecting hearts does not give any implied or explicit guarantee
              or warranty of marriage or alliance by you choosing to register on
              our App an using Connecting hearts services (both paid and free).
              <br />
              <br />
              Notwithstanding anything contrary contained anywhere, under no
              circumstance Connecting hearts shall be held responsible or liable
              whatsoever or howsoever, arising out of, relating to or connected
              with:
              <br />
              <br />
              Any act or Omission done by Connecting hearts payment
              gateway/alliance partner etc.;
              <br />
              <br />
              Any untrue or incorrect information submitted by you or on your
              behalf;
              <br />
              <br />
              Any decision taken by you or on your behalf or any consequences
              thereof, based on any information provided by any other user
              (including suspension/ deletion of the profile from connecting
              hearts);
              <br />
              <br />
              Any unauthorized or illegal act done by any third party relating
              to or connected with any informatior submitted by you or on your
              behalf;
              <br />
              <br />
              V. Any cybercrime attempted or committed by anyone and
              <br />
              <br />
              Any incident of force-majeure or .Act of God..
              <br />
              <br />
              Any issue already stated in these terms and conditions including
              limitation of liability clause of these terms and conditions.
              <br />
              <br />
              Connecting hearts or SRCM/Heartfulness shall no be liable for the
              outcome of during any interaction in a meeting, call, sms, chat,
              email or social media posts at any point of time
              <br />
              <br />
              Any issues relating to any technical malfunction of any
              telecommunication network, software, hardware failures, network
              congestion, denial of service, failure due to spamming or any
              combination of the above.
              <br />
              <br />
              You expressly agree that your use of this App is at your sole
              risk.
              <br />
              <br />
              We are not liable to you for any damage or alteration to your
              equipment including but not limited to computer equipment,
              hand-held device or mobile telephones as a result of the
              installation or use of the app. We are not liable to you for any
              damage or alteration to your equipment including but not limited
              to computer equipment, hand-held device or mobile telephones as a
              result of the installation or use of the app.
              <br />
              <br />
              We suggest Connecting hearts Member to register with your mobile
              number which is not being used by other Finance or Banking
              application or Service in any such case Connecting hearts is not
              liable if any thing happens.
              <br />
              <br />
              <b> Indemnity</b>
              <br />
              <br />
              By using our Connecting hearts services you agree to defend,
              indemnify, and hold harmless connecting hearts, its subsidiaries,
              affiliates, Directors, officers, agents, and other partners and
              <br />
              <br />
              Employees, fully indemnified and harmless from any loss, damage,
              liability, claim, or demand, including reasonable attorney.s fees,
              made by any person through improper use of the service provided by
              connecting hearts. This defence and indemnification obligation
              will survive in perpetuity.
              <br />
              <br />
            </p>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
