import React, { useEffect, useState } from "react";

import {
  Button,
  Layout,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  Col,
  Row,
  Divider,
  TreeSelect,
} from "antd";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Slider } from "antd";

import { CustomHeader } from "../genericComponents/CustomHeader";
import {
  getsearchbyprofileid,
  searchProfile,
} from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
import NavBar from "../genericComponents/CustomNav";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { CustomSider } from "../genericComponents/CustomSider";
const { Content } = Layout;
export const SearchPage = () => {
  const navigate = useNavigate();

  const lookupData = useSelector((state) => state.lookup.lookupData);
  const countries = useSelector((state) => state.lookup.countries);

  const [searchProfileID, setSearchProfileID] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(lookupData);
  }, [lookupData]);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const submit = async (values) => {
    console.log(values);
    if (
      !values.countries?.length &&
      !values.religion?.length &&
      !values.motherTongue?.length &&
      !values.maritalStatus?.length &&
      !values.age.min &&
      !values.age.max &&
      !values.height.min &&
      !values.height.max &&
      !values.income.min &&
      !values.income.max &&
      !values.age.min &&
      !values.age.max &&
      !values.preference
    ) {
      toast.error("Please fill atleast one field");
      return;
    }

    console.log(values);
    const searchResponse = await searchProfile(values);
    if (searchResponse?.code === "CH200") {
      console.log(searchResponse);
      if (searchResponse?.filteredProfiles?.length > 0) {
        navigate("/allprofiles", {
          state: {
            title: "Search Results",
            data: searchResponse?.filteredProfiles,
          },
        });
      } else {
        toast.error("No Profiles Found, Please try different search criteria");
      }
    }
  };

  const onSearch = async () => {
    const searchProfile = await getsearchbyprofileid(searchProfileID);
    if (searchProfile?.code === "CH200" && searchProfile?.filteredProfile) {
      navigate("/profileview/" + searchProfile?.filteredProfile?.clientID);
    } else {
      toast.error(searchProfile?.err || "Something went wrong");
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper ml-10 mr-10">
          <Row type="flex" align="middle" justify={"center"}>
            <Col xs={24} sm={24} md={20} lg={16} xl={24}>
              <div>
                Search By Profile ID
                <Input
                  style={{
                    marginTop: 20,
                  }}
                  size="medium"
                  label="Search By Profile Id"
                  placeholder={"Hearts ID"}
                  name="searchByProfileId"
                  value={searchProfileID}
                  onChange={(e) => setSearchProfileID(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "_") {
                      e.preventDefault();
                    }
                  }}
                />
                <Button
                  style={{
                    marginTop: 20,
                  }}
                  block
                  type="primary"
                  onClick={() => onSearch()}
                >
                  Search
                </Button>
              </div>
              <Divider />

              <Form
                form={form}
                name="search"
                onFinish={submit}
                {...formItemLayout}
                variant="filled"
                layout="vertical"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Form.Item
                  label="Country"
                  name="country"

                  // rules={[
                  //   {
                  //     // required: true,
                  //     message: "Please input!",
                  //   },
                  // ]}
                >
                  <Select mode="multiple" placeholder={"Country"} allowClear>
                    {countries?.map((element) => {
                      return (
                        <Select.Option value={element.value}>
                          {element.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Religion" name="religion">
                  <Select mode="multiple" placeholder={"Religion"} allowClear>
                    {lookupData?.religion?.map((element) => {
                      return (
                        <Select.Option value={element.value}>
                          {element.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Mother Tongue" name="motherTongue">
                  <Select
                    mode="multiple"
                    placeholder={"Mother Tongue"}
                    allowClear
                  >
                    {lookupData?.motherTongue?.map((element) => {
                      return (
                        <Select.Option value={element.value}>
                          {element.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Marital Status" name="maritalStatus">
                  <Select
                    mode="multiple"
                    placeholder={"Merital Status"}
                    allowClear
                  >
                    {lookupData?.maritalStatus?.map((element) => {
                      return (
                        <Select.Option value={element.value}>
                          {element.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <div className="flex-center">
                  <Form.Item label="Min Age" name={["age", "min"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Min Age"}
                      allowClear
                    >
                      {lookupData?.age?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Max Age" name={["age", "max"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Max Age"}
                      allowClear
                    >
                      {lookupData?.age?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex-center">
                  <Form.Item label="Min Income" name={["income", "min"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Min Income"}
                      allowClear
                    >
                      {lookupData?.income?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Max Income" name={["income", "max"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Max Income"}
                      allowClear
                    >
                      {lookupData?.income?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex-center">
                  <Form.Item label="Min Height" name={["height", "min"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Min Height"}
                      allowClear
                    >
                      {lookupData?.height?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Max Height" name={["height", "max"]}>
                    <Select
                      className="form-item-width"
                      placeholder={"Max Height"}
                      allowClear
                    >
                      {lookupData?.height?.map((element) => {
                        return (
                          <Select.Option value={element.value}>
                            {element.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item
                  wrapperCol={{
                    offset: 6,
                    span: 16,
                  }}
                >
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      justifyContent: "center",
                      marginRight: 150,
                      gap: 20,
                    }}
                  >
                    <Button
                      type="default"
                      onClick={() => {
                        form.resetFields();
                      }}
                    >
                      Clear All
                    </Button>

                    <Button type="primary" htmlType="submit">
                      Search
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};
