import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Menu,
  Modal,
  Space,
  Typography,
  Avatar,
  Badge,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  HeartOutlined,
  HomeOutlined,
  KeyOutlined,
  LockOutlined,
  MenuOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  BellOutlined,
  LogoutOutlined,
  ArrowLeftOutlined,
  ExclamationCircleFilled,
  CrownFilled,
  EditOutlined,
} from "@ant-design/icons";
import { getUserDetailsFromLocalStorage } from "../helperFunctions/endpoints";
import { useLocation } from "react-router-dom";
import { getErrorImgForProfile, getProfilePicAsURL } from "../utils/utils";
import { CustomHeader } from "./CustomHeader";

const NavBar = ({ navigate, notificationObjCount, notificationCount }) => {
  const { Text } = Typography;
  const [visible, setVisible] = useState(false);
  const userDetails = getUserDetailsFromLocalStorage();
  const [modal, contextHolder] = Modal.useModal();
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    if (window.location.pathname == "/myprofile") {
      setIsProfilePage(true);
    }
    if (window.location.pathname == "/dashboard") {
      setIsHomePage(true);
    }
  }, [window.location]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,

      type,
    };
  }

  const location = useLocation();
  const { confirm } = Modal;

  const items = [
    {
      key: "/dashboard",
      icon: React.createElement(HomeOutlined),
      label: "Home",
    },

    {
      key: "/search",
      icon: React.createElement(SearchOutlined),
      label: "Search",
    },
    // {
    //   key: "/searchbyprofileid",
    //   icon: React.createElement(FileSearchOutlined),
    //   label: "Search By Profile ID",
    // },
    {
      key: "/allprofiles",
      icon: React.createElement(UsergroupAddOutlined),
      label: "All Profiles",
    },

    {
      key: "/dailyrecommendation",
      icon: React.createElement(SettingOutlined),
      label: "Daily Recommendation",
    },
    {
      key: "/profilevisitors",
      icon: React.createElement(FileProtectOutlined),
      label: "Profile Visitors",
    },
    {
      key: "/membership",
      icon: React.createElement(CrownFilled),
      label: "Membership",
    },
    getItem(
      "Change Password",
      "/changepassword",
      React.createElement(KeyOutlined)
    ),
    getItem(
      "Delete Profile",
      "/deleteProfile",
      React.createElement(DeleteOutlined)
    ),
    {
      type: "divider",
    },
    getItem("Feedback", "/feedback", React.createElement(ReadOutlined)),
    getItem(
      "Help Center",
      "/helpcenter",
      React.createElement(QuestionCircleOutlined)
    ),

    getItem(
      "Terms & Conditions",
      "/termsconditions",
      React.createElement(ExclamationCircleOutlined)
    ),
    getItem(
      "Privacy Policy",
      "/privacypolicy",
      React.createElement(LockOutlined)
    ),
    {
      key: "/donatenow",
      icon: React.createElement(HeartOutlined),
      label: "Donate Now",
      onClick: () => {
        window.open(
          "https://donations.heartfulness.org/donation-general-fund/",
          "_blank"
        );
      },
    },
    {
      key: "logout",
      icon: React.createElement(LogoutOutlined),
      label: "Logout",
      children: null,
      type: "item",
      onClick: async () => {
        confirm({
          title: "Are you sure you want to logout?",
          icon: <ExclamationCircleFilled />,

          onOk() {
            console.log("OK");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userDetails");
            window.location.href = "/";
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      },
    },
  ];
  const onItemClick = (item) => {
    setVisible(false);
    if (item.key === "logout") return true;
    if (item.key === "/dailyrecommendation") {
      navigate("/profileslist/dailyrecommendations");
      return true;
    }
    if (item.key === "/profilevisitors") {
      navigate("/profileslist/profilevisitors");
      return true;
    }
    if (item.key === "/allprofiles") {
      navigate("/profileslist/all");
      return true;
    }
    navigate(item.key);
    return true;
  };

  return (
    <nav className="navbar">
      <div className="navbar-title">
        Connecting Hearts
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "20px",
          }}
        >
          {isProfilePage && (
            <div
              style={{ display: "flex" }}
              onClick={() => navigate("/profileview/" + userDetails?._id)}
            >
              <Button
                className="menu"
                type="primary"
                icon={<EyeOutlined style={{ fontSize: "20px" }} />}
              />
            </div>
          )}

          {isHomePage && (
            <>
              {" "}
              <div
                style={{ display: "flex" }}
                onClick={() => navigate("/search")}
              >
                <Button
                  className="menu"
                  type="primary"
                  icon={<SearchOutlined style={{ fontSize: "20px" }} />}
                />
              </div>
              <div
                style={{ display: "flex" }}
                onClick={() =>
                  navigate("/notification", {
                    state: { notificationObjCount },
                  })
                }
              >
                {" "}
                <Badge
                  count={notificationCount || ""}
                  color="white"
                  style={{ fontSize: "15px", color: "black" }}
                >
                  <Button
                    className="menu"
                    type="primary"
                    icon={<BellOutlined style={{ fontSize: "20px" }} />}
                  />
                </Badge>
              </div>
            </>
          )}
        </div>
      </div>

      <Drawer
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            {userDetails?._id && (
              <img
                src={getProfilePicAsURL(
                  userDetails?._id,
                  userDetails?.profilePic
                )}
                onError={(e) => {
                  e.target.src = getErrorImgForProfile(userDetails);
                }}
                alt=""
                height={80}
                width={80}
                style={{
                  borderRadius: 50,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            )}
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Text>{userDetails?.name}</Text>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => navigate("/myprofile")}
              >
                Edit Profile
              </Button>
            </div>
          </div>
        }
        placement="left"
        onClose={() => setVisible(false)}
        open={visible}
        style={{ width: "80%" }}
        closable={false}
        // extra={
        //   <Space>
        //     <Button type="primary" onClick={() => navigate("/myprofile")}>
        //       Edit Profile
        //     </Button>
        //   </Space>
        // }
      >
        <Menu
          theme="white"
          mode="inline"
          defaultSelectedKeys={[""]}
          items={items}
          onClick={onItemClick}
          style={{ width: "100%" }}
        />
      </Drawer>

      {location.pathname === "/dashboard" ? (
        <Button
          className="menu-icon"
          type="primary"
          icon={<MenuOutlined style={{ fontSize: "20px" }} />}
          onClick={() => setVisible(!visible)}
          style={{ position: "absolute", top: "1rem" }}
        />
      ) : (
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
          style={{
            position: "absolute",
            top: "1rem",
            display: location.pathname === "/dashboard" ? "none" : "block",
            color: "white",
          }}
        />
      )}
    </nav>
  );
};

export default NavBar;
