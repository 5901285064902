export const LifeStyleFormFields = [
  {
    name: "dietaryHabits",
    label: "Dietary Habits",
    placeHolder: "Dietary Habits",

    fieldType: "singleSelect",
    lookups: "dietaryHabits",
  },
  {
    name: "drinkingHabits",
    label: "Drinking Habits",
    placeHolder: "Drinking Habits",

    fieldType: "singleSelect",
    lookups: "drinkingHabits",
  },
  {
    name: "smokingHabits",
    label: "Smoking Habits",
    placeHolder: "Smoking Habits",
    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "OwnAHouse",
    label: "Own  a House?",
    placeHolder: "Own House?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "OwnACar",
    label: "Own A Car?",
    placeHolder: "Own Car?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "OpenToPets",
    label: "Open To Pets?",
    placeHolder: "Open To Pets?",

    fieldType: "singleSelect",
    lookups: "smokingHabits",
  },
  {
    name: "LanguageKnown",
    label: "Language Known",
    placeHolder: " Language Known",

    fieldType: "multiSelect",
    lookups: "motherTongue",
  },
  {
    name: "Hobbies",
    label: "Hobbies",
    placeHolder: "Hobbies",

    fieldType: "multiSelect",
    lookups: "hobbies",
  },
  {
    name: "interests",
    label: "Interests",
    placeHolder: "interests",

    fieldType: "multiSelect",
    lookups: "interests",
  },

  {
    name: "foodICook",
    label: "Food I Cook",
    fieldType: "input",
    placeHolder: "Food I Cook",
  },
  {
    name: "FavouriteMusic",
    label: "Favourite Music",
    placeHolder: "Favourite Music",

    fieldType: "multiSelect",
    lookups: "music",
  },
  {
    name: "favouriteRead",
    label: "Favourite Read",
    fieldType: "input",
    placeHolder: "Favourite Read",
  },
  {
    name: "dressStyle",
    label: "Dress Style",
    placeHolder: "Dress Style",

    fieldType: "multiSelect",
    lookups: "dressStyle",
  },

  {
    name: "Sports",
    label: "Sports",
    placeHolder: "Sports",

    fieldType: "multiSelect",
    lookups: "sports",
  },
  {
    name: "FavouriteBooks",
    label: "Favourite Books",
    placeHolder: "Favourite Books",

    fieldType: "multiSelect",
    lookups: "books",
  },
  {
    name: "favouriteCuisine",
    label: "Favourite Cuisine",
    placeHolder: "Favourite Cuisine",

    fieldType: "multiSelect",
    lookups: "cuisines",
  },
  {
    name: "favouriteMovies",
    label: "Favourite Movies",
    fieldType: "input",
    placeHolder: "Favourite Movies",
  },
  {
    name: "favouriteTVShow",
    label: " Favourite TV Show",
    fieldType: "input",
    placeHolder: "Favourite TV Show",
  },
  {
    name: "vacationDestination",
    label: "Vacation Destination",
    fieldType: "input",
    placeHolder: "Vacation Destination",
  },
];
