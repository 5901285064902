import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Layout,
  Rate,
  Typography,
  Modal,
  message,
  theme,
} from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
import { submitReview } from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const Feedback = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const { TextArea } = Input;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    setIsModalVisible(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmitFeedback = async (values) => {
    try {
      let response = await submitReview(values);

      toast.success(response?.message);
      navigate("/dashboard");
      if (response?.navigateToPlayStore) {
        const packageName = "com.connectinghearts";
        const playStoreLink = ` https://play.google.com/store/apps/details?id=${packageName}`;
        window.open(playStoreLink, "_blank");
      }
      form.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <div>
            <Text className="midtitle">{state?.title || "Feedback"}</Text>
          </div>

          <div
            style={{
              display: "flex",
              fontSize: 18,
              justifyContent: "center",
              marginTop: 50,
            }}
          >
            <b>Rate Your Experience</b>

            <br />
          </div>
          <div
            style={{
              display: "flex",
              fontSize: 14,
              justifyContent: "center",
            }}
          >
            <p>Your Voice, Our Growth</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Rate style={{ color: "#fadb14" }} />
          </div>
          <br />
          <br />
          <br />
          <br />
          <Card>
            <b>Comment</b>

            <Form
              layout="vertical"
              name="comment"
              form={form}
              onFinish={handleSubmitFeedback}
              onFinishFailed={onFinishFailed}
            >
              <TextArea
                rows={4}
                placeholder="Comment"
                size="default"
                className="border-Radius-10px"
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Please input your comment!",
                  },
                ]}
              />

              <Form.Item>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{
                      width: "auto",
                    }}
                    type="primary"
                    htmlType="submit"
                    block
                    className="border-Radius-5px block-button-text height-100p"
                  >
                    Submit Your Rating
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};
