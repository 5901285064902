import React, { useEffect } from "react";
import { Button, Layout, Menu, Modal, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import Title from "antd/es/typography/Title";
import Logo from "../images/logo.jpeg";
import { useLocation } from "react-router-dom";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  HeartOutlined,
  HomeOutlined,
  KeyOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SearchOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  CrownFilled,
  ExclamationCircleFilled,
  BellOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Typography from "antd/es/typography/Typography";

export const CustomSider = ({ navigate }) => {
  const { Text } = Typography;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    {
      key: "/dashboard",
      icon: React.createElement(HomeOutlined),
      label: "Home",
    },
    {
      key: "/myprofile",
      icon: React.createElement(EditOutlined),
      label: "Edit Profile",
    },
    {
      key: "/search",
      icon: React.createElement(SearchOutlined),
      label: "Search",
    },
    // {
    //   key: "/searchbyprofileid",
    //   icon: React.createElement(FileSearchOutlined),
    //   label: "Search By Profile ID",
    // },
    {
      key: "/allprofiles",
      icon: React.createElement(UsergroupAddOutlined),
      label: "All Profiles",
    },

    {
      key: "/dailyrecommendation",
      icon: React.createElement(SettingOutlined),
      label: "Daily Recommendation",
    },
    {
      key: "/profilevisitors",
      icon: React.createElement(FileProtectOutlined),
      label: "Profile Visitors",
    },
    {
      key: "/membership",
      icon: React.createElement(CrownFilled),
      label: "Membership",
    },
    getItem(
      "Change Password",
      "/changepassword",
      React.createElement(KeyOutlined)
    ),
    getItem(
      "Delete Profile",
      "/deleteProfile",
      React.createElement(DeleteOutlined)
    ),
    {
      type: "divider",
    },
    getItem("Feedback", "/feedback", React.createElement(ReadOutlined)),
    getItem(
      "Help Center",
      "/helpcenter",
      React.createElement(QuestionCircleOutlined)
    ),

    getItem(
      "Terms & Conditions",
      "/termsconditions",
      React.createElement(ExclamationCircleOutlined)
    ),
    getItem(
      "Privacy Policy",
      "/privacypolicy",
      React.createElement(LockOutlined)
    ),
    {
      key: "/donatenow",
      icon: React.createElement(HeartOutlined),
      label: "Donate Now",
      onClick: () => {
        window.open(
          "https://donations.heartfulness.org/donation-general-fund/",
          "_blank"
        );
      },
    },
    {
      key: "logout",
      icon: React.createElement(LogoutOutlined),
      label: "Logout",
      children: null,
      type: "item",
      onClick: async () => {
        Modal.confirm({
          title: "Are you sure you want to logout?",
          icon: <ExclamationCircleFilled />,

          onOk() {
            console.log("OK");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userDetails");
            window.location.href = "/";
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      },
    },
  ];
  const onItemClick = (item) => {
    if (item.key === "logout") return true;
    if (item.key === "/dailyrecommendation") {
      navigate("/profileslist/dailyrecommendations");
      return true;
    }
    if (item.key === "/profilevisitors") {
      navigate("/profileslist/profilevisitors");
      return true;
    }
    if (item.key === "/allprofiles") {
      navigate("/profileslist/all");
      return true;
    }
    navigate(item.key);
    return true;
  };

  return (
    <Sider
      style={{ minHeight: "100vh", marginTop: "64px" }}
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
    >
      <Menu
        theme="dark"
        mode="vertical"
        defaultSelectedKeys={[""]}
        items={items}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        onClick={onItemClick}
      />
    </Sider>
  );
};
