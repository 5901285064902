import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import Title from "antd/es/typography/Title";
import Logo from "../images/logo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { loginFormFields } from "../constants/loginFormFields";
import { formFieldRenderer } from "../helperFunctions/formFieldRenderer";
import AjaXpress from "../helperFunctions/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCountries, setLookup } from "../store/slices/lookupSlice";
import {
  setDescription,
  setProfilePicDetails,
  setUserId,
} from "../store/slices/userSlice";
import { countryCodesWithIcons } from "../constants/countrycodes";
import MobileNumberInput from "../genericComponents/MobileNumberInput";
import { setUserDetailsInLocalStorage } from "../helperFunctions/endpoints";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleLogin = (values) => {
    login(values);
  };

  const login = async (values) => {
    try {
      let response = await AjaXpress({
        method: "POST",
        body: {
          phoneNumber: values.phoneNumber,
          password: values.password,
        },
        slug: "auth/login",
      });
      if (response?.response?.status === 200) {
        localStorage.setItem("access_token", response?.data?.token);
        toast.success(response?.data?.message);
        await getUserDetails();
        await getLookups();
        await getCountryLookups();
        navigate(`/${response?.data?.screenName}`);
      }
      if (response.status === 400) {
        if (response?.data?.code === "CH_Unverified")
          toast.error(response?.data?.err);
        else toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserDetails = async () => {
    try {
      let response = await AjaXpress({
        method: "GET",
        body: {},
        slug: "auth/getUser",
      });
      if (response?.response?.status == 200) {
        dispatch(setUserId(response?.data?.data?._id));
        setUserDetailsInLocalStorage(response?.data?.data);
        dispatch(
          setProfilePicDetails({
            id: response?.data?.data?.profilePic[0]?.id,
            s3Link: response?.data?.data?.profilePic[0]?.s3Link,
          })
        );

        dispatch(setDescription(response?.data?.data?.description));
        return response?.data?.data;
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLookups = async () => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: "lookup",
      });
      if (response?.response?.status == 200) {
        dispatch(setLookup(response?.data?.lookupData[0]));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryLookups = async () => {
    try {
      let response = await AjaXpress({
        method: "GET",
        slug: "lookup/getCountryLookup",
      });
      if (response?.response?.status == 200) {
        dispatch(setCountries(response?.data));
      }
      if (response.status === 400) {
        toast.error(response?.data?.err);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChanges = (name, value) => {
    form.setFieldValue(name, value);
  };

  useEffect(() => {
    checkAccessToken();
  }, []);

  const checkAccessToken = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const userObj = await getUserDetails();
      console.log("userObj", userObj);
      if (token) {
        await getLookups();
        await getCountryLookups();
        navigate(userObj?.screenName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row type="flex" align="middle">
      <Col xs={24} sm={24} md={24} lg={14} xl={12}>
        <div
          className="login-image"
          style={{
            display: "block",
            backgroundImage:
              "url(https://d3nn873nee648n.cloudfront.net/1200x1800-new/20354/SM1015626.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={10} xl={12}>
        <div style={{ margin: "3rem" }}>
          <Title level={3} className="title">
            <img
              key="rootloader"
              src={Logo}
              alt="no_image"
              className="login-logo"
            />
            Connecting Hearts
          </Title>
          <div>
            <Title level={4} className="title">
              Sign In
            </Title>
            <div className="sub-title">
              Meet like-minded individuals <br />
              who share your values and Beliefs
            </div>
          </div>
          <Form
            layout="vertical"
            name="loginForm"
            form={form}
            onFinish={handleLogin}
            initialValues={{
              countryCode: "+91",
            }}
          >
            <Form.Item
              label={"Mobile Number"}
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile Number!",
                },
              ]}
            >
              <MobileNumberInput
                name={"phoneNumber"}
                countryCodeOptions={countryCodesWithIcons?.map((i) => {
                  return {
                    label: `${i.emoji} ${i.value}`,
                    value: i.value,
                  };
                })}
                onChangeHandler={handleFormChanges}
              />
            </Form.Item>
            {loginFormFields.map((Field, index) => {
              return (
                <React.Fragment key={index}>
                  <Form.Item
                    label={Field.label}
                    name={Field.name}
                    rules={Field.rules}
                  >
                    {formFieldRenderer(Field, handleFormChanges)}
                  </Form.Item>
                </React.Fragment>
              );
            })}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
              >
                Log in
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                htmlType="button"
                block
                className="border-Radius-5px block-button-text height-100p"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password
              </Button>
            </Form.Item>
            <div className="signup-text">
              Don't Have an Account?{" "}
              <Link to="/registration" className="main-text">
                Register Now
              </Link>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
