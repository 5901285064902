import React, { useEffect, useState } from "react";
import Typography from "antd/es/typography";
import { Card, Button, Tooltip } from "antd";
import {
  findLabelByValueAndKey,
  getErrorImgForOtherProfile,
  getProfilePicAsURL,
  getStateAndCityLabels,
} from "../utils/utils";
import Logo from "../images/logo.jpeg";
import NoProfileImage from "../images/no-profile.png";
import {
  HeartOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  SendOutlined,
  TeamOutlined,
  CloseCircleOutlined,
  BlockOutlined,
  StopOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import ButtonWithIcon from "./ButtonWithIcon";
import { BiChat } from "react-icons/bi";
import { toast } from "react-toastify";

export const UserProfileCard = ({
  profileItem,
  isViewAllCard,
  navigate,
  shortlistClick,
  interestClick,
  ignoreClick,
}) => {
  const { Text } = Typography;

  return (
    <div>
      {!isViewAllCard ? (
        <Card hoverable className="user-profile-card">
          <div>
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                profileItem?.clientID,
                profileItem?.profilePic
              )}
              onError={(e) => {
                e.target.src = Logo;
              }}
              onClick={() => navigate("/profileview/" + profileItem?.clientID)}
            />
            <div style={{ position: "absolute", top: 10, right: 10 }}>
              {!profileItem?.isShortlisted && (
                <Tooltip
                  title="Shortlist"
                  onClick={() => shortlistClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<HeartOutlined />}
                  />
                </Tooltip>
              )}
            </div>
            <div style={{ position: "absolute", top: 10, left: 10 }}>
              <Tooltip title="Ignore" onClick={() => ignoreClick(profileItem)}>
                <Button
                  type="default"
                  shape="circle"
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            </div>
          </div>
          <Text>{"HEARTS-" + profileItem?.heartsId}</Text>
          <br />
          <Text>{findLabelByValueAndKey(profileItem?.height, "height")}</Text>
          <br />
          <Text>{findLabelByValueAndKey(profileItem?.income, "income")}</Text>
          <br />
          <Text>{findLabelByValueAndKey(profileItem?.cast, "casts")}</Text>
          <Button
            type="primary"
            block
            className="border-Radius-5px block-button-text height-100p"
            onClick={() => interestClick(profileItem)}
          >
            Send Interest
          </Button>
        </Card>
      ) : (
        <Card
          hoverable
          className="user-profile-card-view-all"
          onClick={() => navigate("/allprofiles")}
        >
          View All
        </Card>
      )}
    </div>
  );
};

export const NewUserProfileCard = ({
  profileItem,
  navigate,
  buttonText,
  screenName,
}) => {
  const { Text } = Typography;
  const [cityLabels, setCityLabels] = useState();

  React.useEffect(() => {
    getCityLabels();
  }, [profileItem]);

  const getCityLabels = async () => {
    if (profileItem?.city) {
      const labels = await getStateAndCityLabels(
        profileItem?.country,
        profileItem?.state,
        profileItem?.city
      );
      setCityLabels(labels);
    }
  };

  return (
    <div>
      <Card className="user-profile-card">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          onClick={() =>
            navigate("/profileview/" + profileItem?.clientID, {
              state: { screenName: screenName || "default" },
            })
          }
        >
          <div>
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                profileItem?.clientID,
                profileItem?.profilePic
              )}
              errorImage={getErrorImgForOtherProfile()}
              width={140}
              height={200}
              onClick={() => navigate("/profileview/" + profileItem?.clientID)}
            />
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <Text>{"HEARTS-" + profileItem?.heartsId}</Text>
            <br />
            <Text>{findLabelByValueAndKey(profileItem?.height, "height")}</Text>
            <br />
            <Text>{findLabelByValueAndKey(profileItem?.income, "income")}</Text>
            <br />
            <Text>{findLabelByValueAndKey(profileItem?.cast, "casts")}</Text>
            <br />
            <Text>
              {cityLabels?.city ? cityLabels?.city + ", " : ""}
              {cityLabels?.state ? cityLabels?.state + ", " : ""}
              {cityLabels?.country ? cityLabels?.country + ", " : ""}
            </Text>
            <Button
              type="primary"
              icon={<EyeOutlined />}
              className="border-Radius-5px block-button-text height-50p"
              style={{ position: "absolute", bottom: "20px", right: "20px" }}
            >
              {buttonText || "View Profile"}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export const AllProfileUserCard = ({
  profileItem,
  navigate,
  shortlistClick,
  interestClick,
  blockClick,
  ignoreClick,
  acceptInterestClick,
  declineInterestClick,
  screenName,
}) => {
  const { Text } = Typography;

  const [isCancelRequest, setIsCancelRequest] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isIgnored, setIsIgnored] = useState(false);
  const [isInterestRecv, setIsInterestRecv] = useState(false);
  const [isIDeclined, setIsIDeclined] = useState(false);
  const [isTheyDeclined, setIsTheyDeclined] = useState(false);
  const [isAcceptedByMe, setIsAcceptedByMe] = useState(false);
  const [isAcceptedMe, setIsAcceptedMe] = useState(false);

  useEffect(() => {
    setIsCancelRequest(profileItem?.isInterestSent);
    setIsBlocked(profileItem?.isBlocked);
    setIsIgnored(profileItem?.isIgnored);
    setIsInterestRecv(profileItem?.isInterestReceived);
    setIsIDeclined(profileItem?.isIDeclined);
    setIsTheyDeclined(profileItem?.isTheyDeclined);
    setIsAcceptedByMe(screenName === "acceptedByMe" ? true : false);
    setIsAcceptedMe(screenName === "acceptedMe" ? true : false);
    if (screenName === "acceptedByMe") {
      profileItem.isInterestAcceptedByMe = true;
    } else if (screenName === "acceptedMe") {
      profileItem.isInterestAcceptedMe = true;
    }
  }, [profileItem, screenName]);

  const getButtonUI = (action) => {
    switch (action) {
      case "interest":
        return (
          <>
            {profileItem?.isInterestSent ? (
              <ButtonWithIcon
                label={"Cancel Interest"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={interestClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Send Interest"}
                icon={
                  <SendOutlined
                    className="button-size"
                    onClick={interestClick}
                  />
                }
              />
            )}
          </>
        );
      case "block":
        return (
          <>
            {profileItem?.isBlocked ? (
              <ButtonWithIcon
                label={"UNBLOCKED"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={blockClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Block"}
                icon={
                  <SendOutlined className="button-size" onClick={blockClick} />
                }
              />
            )}
          </>
        );
      case "ignore":
        return (
          <>
            {profileItem?.isIgnored ? (
              <ButtonWithIcon
                label={"Remove"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={ignoreClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Ignore"}
                icon={
                  <SendOutlined className="button-size" onClick={ignoreClick} />
                }
              />
            )}
          </>
        );

      case "interestreceived":
        return (
          <>
            {profileItem?.isInterestReceived ? (
              <>
                <ButtonWithIcon
                  label={"Accept Interest"}
                  icon={<SendOutlined className="button-size" />}
                  onClick={() => acceptInterestClick(profileItem, "accept")}
                />
                <ButtonWithIcon
                  label={"Decline"}
                  icon={<StopOutlined className="button-size" />}
                  onClick={() => declineInterestClick(profileItem, "reject")}
                />
              </>
            ) : (
              <></>
            )}
          </>
        );
      case "ideclined":
        return (
          <>
            {profileItem?.isIDeclined ? (
              <ButtonWithIcon
                label={"Accept Again"}
                icon={<SendOutlined className="button-size-selected" />}
                onClick={() => acceptInterestClick(profileItem, "accept")}
              />
            ) : (
              <ButtonWithIcon
                label={"Decline"}
                icon={
                  <SendOutlined className="button-size" onClick={blockClick} />
                }
                onClick={() => declineInterestClick(profileItem, "reject")}
              />
            )}
          </>
        );
      case "theydeclined":
        return null;
      case "acceptedByMe":
        return (
          <>
            {profileItem?.isInterestAcceptedByMe ? (
              <ButtonWithIcon
                label={"Decline"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={() => acceptInterestClick(profileItem, "reject")}
              />
            ) : (
              <ButtonWithIcon
                label={"Accept"}
                icon={
                  <SendOutlined
                    className="button-size"
                    onClick={() => declineInterestClick(profileItem, "reject")}
                  />
                }
              />
            )}
            <ButtonWithIcon
              label={"Chat"}
              icon={<BiChat className="button-size" />}
              onClick={() => {
                toast.success("Chat feature coming soon!");
              }}
            />
            {profileItem?.isBlocked ? (
              <ButtonWithIcon
                label={"Unblock"}
                icon={<CloseCircleOutlined className="button-size-selected" />}
                onClick={blockClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Block"}
                icon={<CloseCircleOutlined className="button-size" />}
                onClick={blockClick}
              />
            )}
          </>
        );
      case "acceptedMe":
        return (
          <>
            {profileItem?.isInterestAcceptedMe ? (
              <ButtonWithIcon
                label={"Decline"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={() => declineInterestClick(profileItem, "reject")}
              />
            ) : (
              <ButtonWithIcon
                label={"Accept"}
                icon={
                  <SendOutlined
                    className="button-size"
                    onClick={() => acceptInterestClick(profileItem, "accept")}
                  />
                }
              />
            )}
            {profileItem?.isIgnored ? (
              <ButtonWithIcon
                label={"Remove"}
                icon={<StopOutlined className="button-size-selected" />}
                onClick={ignoreClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Ignore"}
                icon={
                  <DislikeOutlined
                    className="button-size"
                    onClick={ignoreClick}
                  />
                }
              />
            )}
            {profileItem?.isBlocked ? (
              <ButtonWithIcon
                label={"Unblock"}
                icon={<CloseCircleOutlined className="button-size-selected" />}
                onClick={blockClick}
              />
            ) : (
              <ButtonWithIcon
                label={"Block"}
                icon={<CloseCircleOutlined className="button-size" />}
                onClick={blockClick}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      style={{
        width: "92vw",
        maxWidth: 390,
        borderRadius: "10px",
        backgroundColor: "#f8f8f8",
        margin: "1rem",
        height: 490,
      }}
      bodyStyle={{ padding: 0 }}
      cover={
        <div>
          <ProfileImage
            imgSrc={getProfilePicAsURL(
              profileItem?.clientID,
              profileItem?.profilePic
            )}
            errorImage={getErrorImgForOtherProfile()}
            width={"92vw"}
            height={430}
            onClick={() =>
              navigate("/profileview/" + profileItem?.clientID, {
                state: { screenName: screenName || "default" },
              })
            }
          />
          <div className="all-profile-card-meta">
            <Text className="text-white">
              {"HEARTS-" + profileItem?.heartsId}
            </Text>
            <br />
            <Text className="text-white">
              {findLabelByValueAndKey(profileItem?.height, "height")} |{" "}
              {findLabelByValueAndKey(profileItem?.income, "income")} |{" "}
              {findLabelByValueAndKey(profileItem?.cast, "casts")}
            </Text>
          </div>
          <div className="all-profile-card-buttons">
            {isCancelRequest ||
            isBlocked ||
            isIgnored ||
            isInterestRecv ||
            isIDeclined ||
            isTheyDeclined ||
            isAcceptedByMe ||
            isAcceptedMe ? (
              getButtonUI(
                isCancelRequest
                  ? "interest"
                  : isBlocked
                  ? "block"
                  : isIgnored
                  ? "ignore"
                  : isInterestRecv
                  ? "interestreceived"
                  : isIDeclined
                  ? "ideclined"
                  : isTheyDeclined
                  ? "theydeclined"
                  : isAcceptedByMe
                  ? "acceptedByMe"
                  : isAcceptedMe
                  ? "acceptedMe"
                  : ""
              )
            ) : (
              <>
                {profileItem?.isInterestSent ? (
                  <ButtonWithIcon
                    label={"Interest Sent"}
                    icon={<SendOutlined className="button-size-selected" />}
                    onClick={interestClick}
                  />
                ) : (
                  <ButtonWithIcon
                    label={"Send Interest"}
                    icon={
                      <SendOutlined
                        className="button-size"
                        onClick={interestClick}
                      />
                    }
                  />
                )}
                {profileItem?.isShortlisted ? (
                  <ButtonWithIcon
                    label={"Shortlisted"}
                    icon={<TeamOutlined className="button-size-selected" />}
                    onClick={shortlistClick}
                  />
                ) : (
                  <ButtonWithIcon
                    label={"Shortlist"}
                    icon={<TeamOutlined className="button-size" />}
                    onClick={shortlistClick}
                  />
                )}
                {profileItem?.isIgnored ? (
                  <ButtonWithIcon
                    label={"Ignored"}
                    icon={
                      <CloseCircleOutlined className="button-size-selected" />
                    }
                    onClick={ignoreClick}
                  />
                ) : (
                  <ButtonWithIcon
                    label={"Ignore"}
                    icon={<CloseCircleOutlined className="button-size" />}
                    onClick={ignoreClick}
                  />
                )}
              </>
            )}
          </div>
        </div>
      }
    ></Card>
  );
};

export const UserLargeListProfileCard = ({
  profileItem,
  isViewAllCard,
  shortlistClick,
  interestClick,
  ignoreClick,
  unInterestClick,
  unShortlistClick,
  unIgnoreClick,
  acceptInterestClick,
  declineInterestClick,
}) => {
  const { Text } = Typography;
  return (
    <div>
      {!isViewAllCard ? (
        <Card
          hoverable
          style={{
            height: 100,
            width: "40rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                profileItem?.clientID,
                profileItem?.profilePic
              )}
              onError={(e) => {
                e.target.src = Logo;
              }}
              width={60}
              height={60}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <Text>{"HEARTS-" + profileItem?.heartsId}</Text>
              <Text>
                {findLabelByValueAndKey(profileItem?.height, "height")}
              </Text>
              <Text>
                {findLabelByValueAndKey(profileItem?.income, "income")}
              </Text>
            </div>
            <div
              style={{
                position: "absolute",
                right: 10,
              }}
            >
              {interestClick && (
                <Tooltip
                  title="Interest"
                  onClick={() => interestClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />{" "}
                  Interest
                </Tooltip>
              )}
              {acceptInterestClick && (
                <Tooltip
                  title="Accept Interest"
                  onClick={() => acceptInterestClick(profileItem, "accept")}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />{" "}
                  Accept Interest
                </Tooltip>
              )}
              {declineInterestClick && (
                <Tooltip
                  title="Decline Interest"
                  onClick={() => declineInterestClick(profileItem, "reject")}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />{" "}
                  Decline Interest
                </Tooltip>
              )}
              {unInterestClick && (
                <Tooltip
                  title="Interest"
                  onClick={() => unInterestClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />{" "}
                  Unsend Interest
                </Tooltip>
              )}
              {shortlistClick && !profileItem?.isShortlisted && (
                <Tooltip
                  title="Shortlist"
                  onClick={() => shortlistClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<HeartOutlined />}
                  />{" "}
                  Shortlist
                </Tooltip>
              )}
              {unShortlistClick && (
                <Tooltip
                  title="Remove Shortlist"
                  onClick={() => unShortlistClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<HeartOutlined />}
                  />
                  Unshortlist
                </Tooltip>
              )}
              {ignoreClick && (
                <Tooltip
                  title="Ignore"
                  onClick={() => ignoreClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />{" "}
                  Ignore
                </Tooltip>
              )}
              {unIgnoreClick && (
                <Tooltip
                  title="Unsend Ignore"
                  onClick={() => unIgnoreClick(profileItem)}
                >
                  <Button
                    type="default"
                    shape="circle"
                    icon={<CheckOutlined />}
                  />
                  Unsend Ignore
                </Tooltip>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Card hoverable className="user-profile-card-view-all">
          View All
        </Card>
      )}
    </div>
  );
};

export const UserSmallListProfileCard = ({
  profileItem,
  isViewAllCard,
  shortlistClick,
  interestClick,
  navigate,
  ignoreClick,
}) => {
  const { Text } = Typography;
  return (
    <div>
      {!isViewAllCard ? (
        <Card
          hoverable
          style={{
            height: "7rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ProfileImage
              imgSrc={getProfilePicAsURL(
                profileItem?.clientID,
                profileItem?.profilePic
              )}
              width={60}
              height={60}
              onClick={() => navigate("/profileview/" + profileItem?.clientID)}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>{"HEARTS-" + profileItem?.heartsId}</Text>
              <Text>
                {findLabelByValueAndKey(profileItem?.height, "height")}
              </Text>
              <Text>
                {findLabelByValueAndKey(profileItem?.income, "income")}
              </Text>
            </div>
            <Tooltip title="Interest">
              <Button
                type="default"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => interestClick(profileItem)}
              />
            </Tooltip>
            {!profileItem?.isShortlisted && (
              <Tooltip title="Shortlist">
                <Button
                  type="default"
                  shape="circle"
                  icon={<HeartOutlined />}
                  onClick={() => shortlistClick(profileItem)}
                />
              </Tooltip>
            )}
            <Tooltip title="Ignore">
              <Button
                type="default"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => ignoreClick(profileItem)}
              />
            </Tooltip>
          </div>
        </Card>
      ) : (
        <Card
          hoverable
          style={{
            height: "5rem",
            fontSize: "large",
            justifyContent: "center",
            color: "red",
            alignItems: "center",
            display: "flex",
          }}
        >
          View All
        </Card>
      )}
    </div>
  );
};

export const ProfileImage = ({
  imgSrc,
  width,
  maxWidth,
  height,
  onClick,
  objectFit,
  errorImage,
  style,
}) => {
  return (
    <img
      alt="no-img"
      src={imgSrc}
      onError={(e) => {
        e.target.src = errorImage;
      }}
      style={{
        ...style,
        width: width || 120,
        maxWidth: maxWidth || 390,
        height: height || 120,
        objectFit: objectFit || "cover",
        objectPosition: "top",
      }}
      onClick={onClick}
    />
  );
};
