import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gender: null,
  dob: null,
  height: null,
  country: null,
  state: null,
  city: null,
  residentialStatus:null,

  education: null,
  otherPgDegree: null,
  employedIn: null,
  occupation: null,
  income: null,

  maritalStatus: null,
  haveChildren: null,
  motherTongue: null,
  religion: null,
  caste: null,
  castNoBar: null,
  horoscope: null,
  manglik: null,
};
export const personalDetailSlice = createSlice({
  name: "personalData",
  initialState,
  reducers: {
    updatePersonalData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
export const { updatePersonalData } = personalDetailSlice.actions;
export default personalDetailSlice.reducer;
