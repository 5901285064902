import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  Cascader,
  TreeSelect,
  DatePicker,
  Button,
  Layout,
} from "antd";
import { CustomHeader } from "../genericComponents/CustomHeader";
import { useNavigate } from "react-router-dom";
import { RxWidth } from "react-icons/rx";
import { PiPlaceholder } from "react-icons/pi";
import NavBar from "../genericComponents/CustomNav";
import { CustomSider } from "../genericComponents/CustomSider";
const { Content } = Layout;

export const MyVisitors = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
    },
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content
          style={{
            justifyContent: "center",
            marginRight: "25%",
            margin: "24px 16px 0",
          }}
        >
          <Form
            {...formItemLayout}
            variant="filled"
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Age"
              name="Age"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  alignContent: "space-between",
                }}
              >
                <Select
                  style={{
                    width: 200,
                    marginRight: 20,
                  }}
                  placeholder={"Min Age"}
                />

                <Select
                  style={{ width: 200, marginRight: 20 }}
                  placeholder={"Max Age"}
                />
              </div>
            </Form.Item>

            <Form.Item
              label="Height"
              name="Height"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "space-between",
                }}
              >
                <Select
                  style={{ width: 200, marginRight: 20 }}
                  placeholder={"Min Height"}
                />
                <Select
                  style={{ width: 200, marginRight: 20 }}
                  placeholder={"Max Height"}
                />
              </div>
            </Form.Item>

            <Form.Item
              label="Income"
              name="Income"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "space-between",
                }}
              >
                <Select
                  style={{ width: 200, marginRight: 20 }}
                  placeholder={"Min Income"}
                />
                <Select
                  style={{ width: 200, marginRight: 20 }}
                  placeholder={"Max Income"}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Country"
              name="Country"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Country"} />
            </Form.Item>
            <Form.Item
              label="Residential Status"
              name="Residential Status"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Residential Status"} />
            </Form.Item>
            <Form.Item
              label="Occupation"
              name="Occupation"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Occupation"} />
            </Form.Item>

            <Form.Item
              label="Religion"
              name="Religion"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Religion"} />
            </Form.Item>

            <Form.Item
              label="Caste"
              name="Caste"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Caste"} />
            </Form.Item>

            <Form.Item
              label="Marital Status"
              name="Marital Status"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Marital Status"} />
            </Form.Item>

            <Form.Item
              label="Mother Tongue"
              name="Mother Tongue"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Mother Tongue"} />
            </Form.Item>

            <Form.Item
              label="Education"
              name=" Education"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Education"} />
            </Form.Item>

            <Form.Item
              label="Horoscope"
              name="Horoscope"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Horoscope"} />
            </Form.Item>
            <Form.Item
              label="Manglik"
              name="Manglik"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <TreeSelect placeholder={"Manglik"} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 5,
                span: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button type="primary" htmlType=" Clear All">
                  Clear All
                </Button>

                <Button type="primary" htmlType="Save">
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};
