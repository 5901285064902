import React from "react";
import { Carousel, Layout, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const ProfileVisitors = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div>
            <Text className="midtitle">
              {state?.title || "Profile Visitors"}
            </Text>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
