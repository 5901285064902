import React from "react";
import { Card, Carousel, Layout, Typography, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
const { Header, Content, Footer } = Layout;

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <div>
            <Text className="midtitle">{state?.title || "Privacy Policy"}</Text>
          </div>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <p>
              <b> Privacy Policy of Connecting Hearts</b>
              <br />
              <br /> Member's Privacy is important to us, and so is being
              transparent about how we collect, use, and share information about
              you. This policy is intende to help you understand:
              <br />
              <br /> What information does Connecting hearts collect from you?
              <br />
              <br />
              Connecting hearts is an advertising platform providing targeted
              especially for members to advertise services for the purpose of
              matchmaking (.Service.). In order to do provide the services we
              ask for certain personal information which is displayed on the
              Application on behalf of you to find the perfect life partner. You
              hereby provide your consent to collect, process, and share of your
              personal information in order to provide the service.
              <br />
              <br />
              Connecting hearts gathers following types of information:
              <br />
              <br />
              i. Information you submit and <br />
              ii. Information not directly submitted by you <br />
              iii. Information we receive from others
              <br />
              <br />
              <b>
                In order to avail the service you provide the following
                information:-{" "}
              </b>
              <br />
              <br />
              • While registering for our service, you share with us your
              personal details, such as name, your gender, date of birth,
              contact details, educational qualification, employment details,
              photos, marital status and your interests etc...
              <br /> <br />
              • When you avail for a paid service, you provide us or our payment
              service provider with information, such as your debit or credit
              card number or UPI.
              <br /> <br />• Testimonials submitted by you including your
              success story and photos.
              <br />
              <br /> • Information submitted by you voluntarily while
              participating in surveys contest, promotions or events.
              <br />
              <br /> • Details shared with our customer care team. (This
              information used to monitor or for training purposes and to ensure
              a better service).
              <br />
              <br /> • Your chats and messages with other users as well as the
              content you publish will be processed as a part of the service.{" "}
              <br />
              <br />
              • Immediate legal action will be taken on reported ids, if found
              guilty. <br />
              <br />
              ii. Information not directly submitted by you <br />
              <br />
              • User activity: <br />
              <br />
              We collect information about your activity on our services, such
              as date and time you logged in, features you.ve been using,
              searches, clicks and pages visited by you, your interaction with
              other users including messages exchanged.
              <br />
              <br /> • Device Information :
              <br />
              <br /> We collect information from and about the device(s) such as
              IP address, device ID and type, device-specification and apps
              settings, app error reports, browser type and version, operating
              system, identifiers associated with cookies or othe technologies
              that may uniquely identify your devic or browser. <br />
              <br />
              • SMS permission We need SMS permission for authenticating :
              transactions via OTP, sent by the Payment Gateway. Information we
              receive from others In addition to the information you provide us
              directly, we receive information about you from others. How we use
              information we collect? We use the information collected in the
              following ways: <br />
              <br />
              • We use the information submitted by you to provide the Service.
              <br />
              <br /> • Manage your account •Provide you with customer support
              <br />
              <br /> • Conduct research and analysis about your use of our
              services for providing better services
              <br />
              <br /> • Communicate with you by email, phone about services,
              promotions or offers that may be of your interest.
              <br />
              <br /> • Recommend relevant matches to you and display your
              profile to other users.
              <br />
              <br />
              <b> With whom does we share your information? </b>
              <br />
              <br />
              <br /> Except where you are expressly informed on the Application
              or as described in this privacy policy we do not sell, rent,
              share, trade or give away any of your personal information.
              <br />
              <br /> • With other users we publish the information shared by you
              with other users to provide the services. The information so
              published are provide by you and be cautious as what you share
              with other users. <br />
              <br />
              • With our service providers and partners
              <br />
              <br />
              We may use third party service providers to provide application
              development, maintenance, backup, storage, payment processing,
              analysis and other services for us, which may require them to
              access or use information about you. If a service provider needs
              to access information about you to perform services on our behalf,
              they do so under close instruction from us, including policies and
              procedures designed to protect your information. All of our
              service providers and partners agree to strict confidentiality
              obligations. <br />
              <br />
              • With law enforcement agencies
              <br />
              <br /> we will disclose your personally identifiable information
              as required by law and when we believ that disclosure is necessary
              to protect our rights, other members interest and protection
              and/or comply with a judicial proceeding, court order, or legal
              process served on our Application.
              <br />
              <br />
              <b> How to access and/or control your information?</b>
              <br />
              <br /> You have been provided with tools to manage your
              information. You have the rights to access, rectify, update
              information that you provided to us and that.s associated with
              your Connecting hearts account directly by logging into your
              account.
              <br />
              <br /> In addition to aforesaid privacy control, a member from EU
              have the following rights
              <br />
              <br />
              • Reviewing your information. Applicable privacy laws may give you
              the right to review the personal information we keep about you
              (depending on the jurisdiction, this may be called right of
              access, righ of portability or variations of those terms).
              <br />
              <br /> • If you believe that the information we hold about you,
              are no longer entitled to use it and you want to delete or object
              to its processing, delete your profile. Please note, however, we
              may need to retai certain information for record keeping purposes,
              tc complete transactions or to comply with our legal obligations
              and applicable territorial laws.
              <br />
              <br /> • If you wish to receive information relating to another
              user, such as a copy of any messages you received from him or her
              through our service, the other user will have to contact us and
              provide their written consent before the information is released.
              <br />
              <br /> • You have the right to withdraw your consent from
              processing your information. Please note that by withdrawing your
              consent means, its deletion of your profile and we won.t be able
              to provide furthe service to you.
              <br />
              <br />
              You have the right to withdraw your consent from processing your
              information. Please note that by withdrawing your consent means,
              its deletion of your profile and we won.t be able to provide
              furthe service to you. <br />
              <br />
              For your protection and the protection of all our users, we may
              ask you to provide proof of identity to accommodate your requests
              <br />
              <br />
              <b>How we secure your information?</b>
              <br />
              <br /> While we implement safeguards designed to protect your
              information, no security system is impenetrable and due to the
              inherent nature of the Internet, we cannot guarantee that data,
              during transmission through the Internet or while stored on our
              systems or otherwise in our care, is absolutely safe from
              intrusion by others. When our registration/order process asks you
              to enter sensitive information (such as a credit card number),
              such information is encrypted and is protected with one of the
              best encryption software in the industry. We follow generally
              accepted industry standards to protect the personal information
              submitted to us. All your information, not just the sensitive
              information mentioned above, is restricted in our offices. Only
              employees who need the information to perform a specific job are
              granted access to personally identifiable information.
              <br />
              <br /> If you have any questions about the security at our
              Application, please mail us at - connectinghearts .info@gmail.com
              <br />
              <br />
              <b>How long we keep your information?</b>
              <br />
              <br /> We keep your personal information only as long as you use
              our service and also as permitted/ required by applicable law. In
              practice, we delete or anonymize your information upon deletion of
              your account, unless the same is required for to comply with legal
              obligations, fraud prevention, take actions we deem necessary to
              protect the integrity of our website or our users, to resolve
              disputes, to enforce our agreements, to support business
              operations, and to continue to develop and improve our Services.
              We retain information for better services, and we only use the
              informatior to analyse about the use of our Services, not to
              specifically analyse personal characteristics about you.
            </p>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
