import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  srcmIdNumber: null,
  satsungCenter: null,
  preceptorName: null,
  preceptorPhoneNumber: null,
  preceptorEmail: null,
};
export const srcmSlice = createSlice({
  name: "srcmData",
  initialState,
  reducers: {
    updateSrcmlData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
export const { updateSrcmlData } = srcmSlice.actions;
export default srcmSlice.reducer;
