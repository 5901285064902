import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Layout,
  Radio,
  Space,
  Typography,
  theme,
  Form,
} from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import { DeleteOutlined, RightCircleOutlined } from "@ant-design/icons";
import Checkbox from "antd/es/checkbox/Checkbox";
import NavBar from "../genericComponents/CustomNav";
import { deleteProfile } from "../helperFunctions/endpoints";
import { toast } from "react-toastify";
const { Header, Content, Footer } = Layout;

export const DeleteProfile = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [lookupData, setLookupData] = useState({});
  const { Text } = Typography;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const lookupData = localStorage.getItem("lookupData");
    setLookupData(JSON.parse(lookupData));
  }, []);

  const handleDelete = async (values) => {
    const res = await deleteProfile(values);
    if (res?.code === "CH200") {
      toast.success(res?.message);
      localStorage.removeItem("access_token");
      localStorage.removeItem("userDetails");
      navigate("/");
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />
        <Content className="content-wrapper">
          <Card
            bordered={false}
            style={{
              fontSize: 14,
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Text className="midtitle">
              This will delete profile permanently. Let us know why you wish to
              delete your profile.
            </Text>
            <p>
              <Form onFinish={handleDelete}>
                <Radio.Group>
                  <Form.Item
                    name="reasonForDeletion"
                    rules={[{ required: true }]}
                  >
                    <Space direction="vertical">
                      {lookupData?.deletionReason?.map((item) => {
                        return <Radio value={item?.value}>{item?.label}</Radio>;
                      })}
                    </Space>
                  </Form.Item>
                </Radio.Group>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    bordered
                  >
                    Delete Profile
                  </Button>
                </Form.Item>
              </Form>
            </p>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};
