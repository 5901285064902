import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lookupData: {},
  hashedLookups: {},
  countries: [],
  states: [],
  cities: [],
};
export const lookupSlice = createSlice({
  name: "lookupData",
  initialState,
  reducers: {
    setLookup: (state, action) => {
      state.lookupData = { ...action.payload };
    },
    setHashedLookups: (state, action) => {
      state.lookupData = { ...action.payload };
    },
    setCountries: (state, action) => {
      state.countries = [...action.payload];
    },
    setStates: (state, action) => {
      state.states = [...action.payload];
    },
    setCities: (state, action) => {
      state.cities = [...action.payload];
    },
  },
});
export const {
  setLookup,
  setHashedLookups,
  setCountries,
  setStates,
  setCities,
} = lookupSlice.actions;
export default lookupSlice.reducer;
