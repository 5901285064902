export const countryCodesWithIcons = [
  {
    label: "Andorra",
    code: "AD",
    emoji: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
    image: "AD.svg",
    value: "+376",
  },
  {
    label: "United Arab Emirates",
    code: "AE",
    emoji: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
    image: "AE.svg",
    value: "+971",
  },
  {
    label: "Afghanistan",
    code: "AF",
    emoji: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
    image: "AF.svg",
    value: "+93",
  },
  {
    label: "Antigua & Barbuda",
    code: "AG",
    emoji: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
    image: "AG.svg",
    value: "+1268",
  },
  {
    label: "Anguilla",
    code: "AI",
    emoji: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
    image: "AI.svg",
    value: "+1264",
  },
  {
    label: "Albania",
    code: "AL",
    emoji: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
    image: "AL.svg",
    value: "+355",
  },
  {
    label: "Armenia",
    code: "AM",
    emoji: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
    image: "AM.svg",
    value: "+374",
  },
  {
    label: "Angola",
    code: "AO",
    emoji: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
    image: "AO.svg",
    value: "+244",
  },
  {
    label: "Antarctica",
    code: "AQ",
    emoji: "🇦🇶",
    unicode: "U+1F1E6 U+1F1F6",
    image: "AQ.svg",
    value: "+672",
  },
  {
    label: "Argentina",
    code: "AR",
    emoji: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    image: "AR.svg",
    value: "+54",
  },
  {
    label: "American Samoa",
    code: "AS",
    emoji: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
    image: "AS.svg",
    value: "+1684",
  },
  {
    label: "Austria",
    code: "AT",
    emoji: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
    image: "AT.svg",
    value: "+43",
  },
  {
    label: "Australia",
    code: "AU",
    emoji: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
    image: "AU.svg",
    value: "+61",
  },
  {
    label: "Aruba",
    code: "AW",
    emoji: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
    image: "AW.svg",
    value: "+297",
  },
  {
    label: "Åland Islands",
    code: "AX",
    emoji: "🇦🇽",
    unicode: "U+1F1E6 U+1F1FD",
    image: "AX.svg",
    value: "+358",
  },
  {
    label: "Azerbaijan",
    code: "AZ",
    emoji: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
    image: "AZ.svg",
    value: "+994",
  },
  {
    label: "Bosnia & Herzegovina",
    code: "BA",
    emoji: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
    image: "BA.svg",
    value: "+387",
  },
  {
    label: "Barbados",
    code: "BB",
    emoji: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
    image: "BB.svg",
    value: "+1246",
  },
  {
    label: "Bangladesh",
    code: "BD",
    emoji: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
    image: "BD.svg",
    value: "+880",
  },
  {
    label: "Belgium",
    code: "BE",
    emoji: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
    image: "BE.svg",
    value: "+32",
  },
  {
    label: "Burkina Faso",
    code: "BF",
    emoji: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
    image: "BF.svg",
    value: "+226",
  },
  {
    label: "Bulgaria",
    code: "BG",
    emoji: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
    image: "BG.svg",
    value: "+359",
  },
  {
    label: "Bahrain",
    code: "BH",
    emoji: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
    image: "BH.svg",
    value: "+973",
  },
  {
    label: "Burundi",
    code: "BI",
    emoji: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
    image: "BI.svg",
    value: "+257",
  },
  {
    label: "Benin",
    code: "BJ",
    emoji: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
    image: "BJ.svg",
    value: "+229",
  },
  {
    label: "St. Barthélemy",
    code: "BL",
    emoji: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
    image: "BL.svg",
    value: "+590",
  },
  {
    label: "Bermuda",
    code: "BM",
    emoji: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
    image: "BM.svg",
    value: "+1441",
  },
  {
    label: "Brunei",
    code: "BN",
    emoji: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
    image: "BN.svg",
    value: "+673",
  },
  {
    label: "Bolivia",
    code: "BO",
    emoji: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    image: "BO.svg",
    value: "+591",
  },
  {
    label: "Brazil",
    code: "BR",
    emoji: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    image: "BR.svg",
    value: "+55",
  },
  {
    label: "Bahamas",
    code: "BS",
    emoji: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
    image: "BS.svg",
    value: "+1242",
  },
  {
    label: "Bhutan",
    code: "BT",
    emoji: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
    image: "BT.svg",
    value: "+975",
  },
  {
    label: "Botswana",
    code: "BW",
    emoji: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
    image: "BW.svg",
    value: "+267",
  },
  {
    label: "Belarus",
    code: "BY",
    emoji: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
    image: "BY.svg",
    value: "+375",
  },
  {
    label: "Belize",
    code: "BZ",
    emoji: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
    image: "BZ.svg",
    value: "+501",
  },
  {
    label: "Canada",
    code: "CA",
    emoji: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
    image: "CA.svg",
    value: "+1",
  },
  {
    label: "Cocos (Keeling) Islands",
    code: "CC",
    emoji: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
    image: "CC.svg",
    value: "+61",
  },
  {
    label: "Congo - Kinshasa",
    code: "CD",
    emoji: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
    image: "CD.svg",
    value: "+243",
  },
  {
    label: "Central African Republic",
    code: "CF",
    emoji: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
    image: "CF.svg",
    value: "+236",
  },
  {
    label: "Congo - Brazzaville",
    code: "CG",
    emoji: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
    image: "CG.svg",
    value: "+242",
  },
  {
    label: "Switzerland",
    code: "CH",
    emoji: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
    image: "CH.svg",
    value: "+41",
  },
  {
    label: "Côte d’Ivoire",
    code: "CI",
    emoji: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
    image: "CI.svg",
    value: "+225",
  },
  {
    label: "Cook Islands",
    code: "CK",
    emoji: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
    image: "CK.svg",
    value: "+682",
  },
  {
    label: "Chile",
    code: "CL",
    emoji: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    image: "CL.svg",
    value: "+56",
  },
  {
    label: "Cameroon",
    code: "CM",
    emoji: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
    image: "CM.svg",
    value: "+237",
  },
  {
    label: "China",
    code: "CN",
    emoji: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
    image: "CN.svg",
    value: "+86",
  },
  {
    label: "Colombia",
    code: "CO",
    emoji: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    image: "CO.svg",
    value: "+57",
  },
  {
    label: "Costa Rica",
    code: "CR",
    emoji: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    image: "CR.svg",
    value: "+506",
  },
  {
    label: "Cuba",
    code: "CU",
    emoji: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    image: "CU.svg",
    value: "+53",
  },
  {
    label: "Cape Verde",
    code: "CV",
    emoji: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
    image: "CV.svg",
    value: "+238",
  },
  {
    label: "Christmas Island",
    code: "CX",
    emoji: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
    image: "CX.svg",
    value: "+61",
  },
  {
    label: "Cyprus",
    code: "CY",
    emoji: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
    image: "CY.svg",
    value: "+357",
  },
  {
    label: "Czechia",
    code: "CZ",
    emoji: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
    image: "CZ.svg",
    value: "+420",
  },
  {
    label: "Germany",
    code: "DE",
    emoji: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
    image: "DE.svg",
    value: "+49",
  },
  {
    label: "Djibouti",
    code: "DJ",
    emoji: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
    image: "DJ.svg",
    value: "+253",
  },
  {
    label: "Denmark",
    code: "DK",
    emoji: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
    image: "DK.svg",
    value: "+45",
  },
  {
    label: "Dominica",
    code: "DM",
    emoji: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    image: "DM.svg",
    value: "+1767",
  },
  {
    label: "Dominican Republic",
    code: "DO",
    emoji: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    image: "DO.svg",
    value: "+1849",
  },
  {
    label: "Algeria",
    code: "DZ",
    emoji: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
    image: "DZ.svg",
    value: "+213",
  },
  {
    label: "Ecuador",
    code: "EC",
    emoji: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    image: "EC.svg",
    value: "+593",
  },
  {
    label: "Estonia",
    code: "EE",
    emoji: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
    image: "EE.svg",
    value: "+372",
  },
  {
    label: "Egypt",
    code: "EG",
    emoji: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
    image: "EG.svg",
    value: "+20",
  },
  {
    label: "Eritrea",
    code: "ER",
    emoji: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
    image: "ER.svg",
    value: "+291",
  },
  {
    label: "Spain",
    code: "ES",
    emoji: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
    image: "ES.svg",
    value: "+34",
  },
  {
    label: "Ethiopia",
    code: "ET",
    emoji: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
    image: "ET.svg",
    value: "+251",
  },
  {
    label: "Finland",
    code: "FI",
    emoji: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
    image: "FI.svg",
    value: "+358",
  },
  {
    label: "Fiji",
    code: "FJ",
    emoji: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
    image: "FJ.svg",
    value: "+679",
  },
  {
    label: "Falkland Islands",
    code: "FK",
    emoji: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
    image: "FK.svg",
    value: "+500",
  },
  {
    label: "Micronesia",
    code: "FM",
    emoji: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
    image: "FM.svg",
    value: "+691",
  },
  {
    label: "Faroe Islands",
    code: "FO",
    emoji: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
    image: "FO.svg",
    value: "+298",
  },
  {
    label: "France",
    code: "FR",
    emoji: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
    image: "FR.svg",
    value: "+33",
  },
  {
    label: "Gabon",
    code: "GA",
    emoji: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
    image: "GA.svg",
    value: "+241",
  },
  {
    label: "United Kingdom",
    code: "GB",
    emoji: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
    image: "GB.svg",
    value: "+44",
  },
  {
    label: "Grenada",
    code: "GD",
    emoji: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
    image: "GD.svg",
    value: "+1473",
  },
  {
    label: "Georgia",
    code: "GE",
    emoji: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
    image: "GE.svg",
    value: "+995",
  },
  {
    label: "French Guiana",
    code: "GF",
    emoji: "🇬🇫",
    unicode: "U+1F1EC U+1F1EB",
    image: "GF.svg",
    value: "+594",
  },
  {
    label: "Guernsey",
    code: "GG",
    emoji: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
    image: "GG.svg",
    value: "+44",
  },
  {
    label: "Ghana",
    code: "GH",
    emoji: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
    image: "GH.svg",
    value: "+233",
  },
  {
    label: "Gibraltar",
    code: "GI",
    emoji: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
    image: "GI.svg",
    value: "+350",
  },
  {
    label: "Greenland",
    code: "GL",
    emoji: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
    image: "GL.svg",
    value: "+299",
  },
  {
    label: "Gambia",
    code: "GM",
    emoji: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
    image: "GM.svg",
    value: "+220",
  },
  {
    label: "Guinea",
    code: "GN",
    emoji: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
    image: "GN.svg",
    value: "+224",
  },
  {
    label: "Guadeloupe",
    code: "GP",
    emoji: "🇬🇵",
    unicode: "U+1F1EC U+1F1F5",
    image: "GP.svg",
    value: "+590",
  },
  {
    label: "Equatorial Guinea",
    code: "GQ",
    emoji: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
    image: "GQ.svg",
    value: "+240",
  },
  {
    label: "Greece",
    code: "GR",
    emoji: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
    image: "GR.svg",
    value: "+30",
  },
  {
    label: "South Georgia & South Sandwich Islands",
    code: "GS",
    emoji: "🇬🇸",
    unicode: "U+1F1EC U+1F1F8",
    image: "GS.svg",
    value: "+500",
  },
  {
    label: "Guatemala",
    code: "GT",
    emoji: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    image: "GT.svg",
    value: "+502",
  },
  {
    label: "Guam",
    code: "GU",
    emoji: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
    image: "GU.svg",
    value: "+1671",
  },
  {
    label: "Guinea-Bissau",
    code: "GW",
    emoji: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
    image: "GW.svg",
    value: "+245",
  },
  {
    label: "Guyana",
    code: "GY",
    emoji: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
    image: "GY.svg",
    value: "+595",
  },
  {
    label: "Hong Kong SAR China",
    code: "HK",
    emoji: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
    image: "HK.svg",
    value: "+852",
  },
  {
    label: "Honduras",
    code: "HN",
    emoji: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    image: "HN.svg",
    value: "+504",
  },
  {
    label: "Croatia",
    code: "HR",
    emoji: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
    image: "HR.svg",
    value: "+385",
  },
  {
    label: "Haiti",
    code: "HT",
    emoji: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
    image: "HT.svg",
    value: "+509",
  },
  {
    label: "Hungary",
    code: "HU",
    emoji: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
    image: "HU.svg",
    value: "+36",
  },
  {
    label: "Indonesia",
    code: "ID",
    emoji: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
    image: "ID.svg",
    value: "+62",
  },
  {
    label: "Ireland",
    code: "IE",
    emoji: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
    image: "IE.svg",
    value: "+353",
  },
  {
    label: "Israel",
    code: "IL",
    emoji: "🇮🇱",
    unicode: "U+1F1EE U+1F1F1",
    image: "IL.svg",
    value: "+972",
  },
  {
    label: "Isle of Man",
    code: "IM",
    emoji: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
    image: "IM.svg",
    value: "+44",
  },
  {
    label: "India",
    code: "IN",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    image: "IN.svg",
    value: "+91",
  },
  {
    label: "British Indian Ocean Territory",
    code: "IO",
    emoji: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
    image: "IO.svg",
    value: "+246",
  },
  {
    label: "Iraq",
    code: "IQ",
    emoji: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
    image: "IQ.svg",
    value: "+964",
  },
  {
    label: "Iran",
    code: "IR",
    emoji: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
    image: "IR.svg",
    value: "+98",
  },
  {
    label: "Iceland",
    code: "IS",
    emoji: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
    image: "IS.svg",
    value: "+354",
  },
  {
    label: "Italy",
    code: "IT",
    emoji: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
    image: "IT.svg",
    value: "+39",
  },
  {
    label: "Jersey",
    code: "JE",
    emoji: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
    image: "JE.svg",
    value: "+44",
  },
  {
    label: "Jamaica",
    code: "JM",
    emoji: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
    image: "JM.svg",
    value: "+1876",
  },
  {
    label: "Jordan",
    code: "JO",
    emoji: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
    image: "JO.svg",
    value: "+962",
  },
  {
    label: "Japan",
    code: "JP",
    emoji: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
    image: "JP.svg",
    value: "+81",
  },
  {
    label: "Kenya",
    code: "KE",
    emoji: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
    image: "KE.svg",
    value: "+254",
  },
  {
    label: "Kyrgyzstan",
    code: "KG",
    emoji: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
    image: "KG.svg",
    value: "+996",
  },
  {
    label: "Cambodia",
    code: "KH",
    emoji: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
    image: "KH.svg",
    value: "+855",
  },
  {
    label: "Kiribati",
    code: "KI",
    emoji: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
    image: "KI.svg",
    value: "+686",
  },
  {
    label: "Comoros",
    code: "KM",
    emoji: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
    image: "KM.svg",
    value: "+269",
  },
  {
    label: "St. Kitts & Nevis",
    code: "KN",
    emoji: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
    image: "KN.svg",
    value: "+1869",
  },
  {
    label: "North Korea",
    code: "KP",
    emoji: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
    image: "KP.svg",
    value: "+850",
  },
  {
    label: "South Korea",
    code: "KR",
    emoji: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
    image: "KR.svg",
    value: "+82",
  },
  {
    label: "Kuwait",
    code: "KW",
    emoji: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
    image: "KW.svg",
    value: "+965",
  },
  {
    label: "Cayman Islands",
    code: "KY",
    emoji: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
    image: "KY.svg",
    value: "+ 345",
  },
  {
    label: "Kazakhstan",
    code: "KZ",
    emoji: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
    image: "KZ.svg",
    value: "+77",
  },
  {
    label: "Laos",
    code: "LA",
    emoji: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
    image: "LA.svg",
    value: "+856",
  },
  {
    label: "Lebanon",
    code: "LB",
    emoji: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
    image: "LB.svg",
    value: "+961",
  },
  {
    label: "St. Lucia",
    code: "LC",
    emoji: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
    image: "LC.svg",
    value: "+1758",
  },
  {
    label: "Liechtenstein",
    code: "LI",
    emoji: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
    image: "LI.svg",
    value: "+423",
  },
  {
    label: "Sri Lanka",
    code: "LK",
    emoji: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
    image: "LK.svg",
    value: "+94",
  },
  {
    label: "Liberia",
    code: "LR",
    emoji: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
    image: "LR.svg",
    value: "+231",
  },
  {
    label: "Lesotho",
    code: "LS",
    emoji: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
    image: "LS.svg",
    value: "+266",
  },
  {
    label: "Lithuania",
    code: "LT",
    emoji: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
    image: "LT.svg",
    value: "+370",
  },
  {
    label: "Luxembourg",
    code: "LU",
    emoji: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
    image: "LU.svg",
    value: "+352",
  },
  {
    label: "Latvia",
    code: "LV",
    emoji: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
    image: "LV.svg",
    value: "+371",
  },
  {
    label: "Libya",
    code: "LY",
    emoji: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
    image: "LY.svg",
    value: "+218",
  },
  {
    label: "Morocco",
    code: "MA",
    emoji: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
    image: "MA.svg",
    value: "+212",
  },
  {
    label: "Monaco",
    code: "MC",
    emoji: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
    image: "MC.svg",
    value: "+377",
  },
  {
    label: "Moldova",
    code: "MD",
    emoji: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
    image: "MD.svg",
    value: "+373",
  },
  {
    label: "Montenegro",
    code: "ME",
    emoji: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
    image: "ME.svg",
    value: "+382",
  },
  {
    label: "St. Martin",
    code: "MF",
    emoji: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
    image: "MF.svg",
    value: "+590",
  },
  {
    label: "Madagascar",
    code: "MG",
    emoji: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
    image: "MG.svg",
    value: "+261",
  },
  {
    label: "Marshall Islands",
    code: "MH",
    emoji: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
    image: "MH.svg",
    value: "+692",
  },
  {
    label: "North Macedonia",
    code: "MK",
    emoji: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
    image: "MK.svg",
    value: "+389",
  },
  {
    label: "Mali",
    code: "ML",
    emoji: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
    image: "ML.svg",
    value: "+223",
  },
  {
    label: "Myanmar (Burma)",
    code: "MM",
    emoji: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
    image: "MM.svg",
    value: "+95",
  },
  {
    label: "Mongolia",
    code: "MN",
    emoji: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
    image: "MN.svg",
    value: "+976",
  },
  {
    label: "Macao SAR China",
    code: "MO",
    emoji: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
    image: "MO.svg",
    value: "+853",
  },
  {
    label: "Northern Mariana Islands",
    code: "MP",
    emoji: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
    image: "MP.svg",
    value: "+1670",
  },
  {
    label: "Martinique",
    code: "MQ",
    emoji: "🇲🇶",
    unicode: "U+1F1F2 U+1F1F6",
    image: "MQ.svg",
    value: "+596",
  },
  {
    label: "Mauritania",
    code: "MR",
    emoji: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
    image: "MR.svg",
    value: "+222",
  },
  {
    label: "Montserrat",
    code: "MS",
    emoji: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
    image: "MS.svg",
    value: "+1664",
  },
  {
    label: "Malta",
    code: "MT",
    emoji: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
    image: "MT.svg",
    value: "+356",
  },
  {
    label: "Mauritius",
    code: "MU",
    emoji: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
    image: "MU.svg",
    value: "+230",
  },
  {
    label: "Maldives",
    code: "MV",
    emoji: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
    image: "MV.svg",
    value: "+960",
  },
  {
    label: "Malawi",
    code: "MW",
    emoji: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
    image: "MW.svg",
    value: "+265",
  },
  {
    label: "Mexico",
    code: "MX",
    emoji: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    image: "MX.svg",
    value: "+52",
  },
  {
    label: "Malaysia",
    code: "MY",
    emoji: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
    image: "MY.svg",
    value: "+60",
  },
  {
    label: "Mozambique",
    code: "MZ",
    emoji: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
    image: "MZ.svg",
    value: "+258",
  },
  {
    label: "Namibia",
    code: "NA",
    emoji: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
    image: "NA.svg",
    value: "+264",
  },
  {
    label: "New Caledonia",
    code: "NC",
    emoji: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
    image: "NC.svg",
    value: "+687",
  },
  {
    label: "Niger",
    code: "NE",
    emoji: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
    image: "NE.svg",
    value: "+227",
  },
  {
    label: "Norfolk Island",
    code: "NF",
    emoji: "🇳🇫",
    unicode: "U+1F1F3 U+1F1EB",
    image: "NF.svg",
    value: "+672",
  },
  {
    label: "Nigeria",
    code: "NG",
    emoji: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
    image: "NG.svg",
    value: "+234",
  },
  {
    label: "Nicaragua",
    code: "NI",
    emoji: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    image: "NI.svg",
    value: "+505",
  },
  {
    label: "Netherlands",
    code: "NL",
    emoji: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
    image: "NL.svg",
    value: "+31",
  },
  {
    label: "Norway",
    code: "NO",
    emoji: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
    image: "NO.svg",
    value: "+47",
  },
  {
    label: "Nepal",
    code: "NP",
    emoji: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
    image: "NP.svg",
    value: "+977",
  },
  {
    label: "Nauru",
    code: "NR",
    emoji: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
    image: "NR.svg",
    value: "+674",
  },
  {
    label: "Niue",
    code: "NU",
    emoji: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
    image: "NU.svg",
    value: "+683",
  },
  {
    label: "New Zealand",
    code: "NZ",
    emoji: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
    image: "NZ.svg",
    value: "+64",
  },
  {
    label: "Oman",
    code: "OM",
    emoji: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
    image: "OM.svg",
    value: "+968",
  },
  {
    label: "Panama",
    code: "PA",
    emoji: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    image: "PA.svg",
    value: "+507",
  },
  {
    label: "Peru",
    code: "PE",
    emoji: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    image: "PE.svg",
    value: "+51",
  },
  {
    label: "French Polynesia",
    code: "PF",
    emoji: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
    image: "PF.svg",
    value: "+689",
  },
  {
    label: "Papua New Guinea",
    code: "PG",
    emoji: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
    image: "PG.svg",
    value: "+675",
  },
  {
    label: "Philippines",
    code: "PH",
    emoji: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
    image: "PH.svg",
    value: "+63",
  },
  {
    label: "Pakistan",
    code: "PK",
    emoji: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
    image: "PK.svg",
    value: "+92",
  },
  {
    label: "Poland",
    code: "PL",
    emoji: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
    image: "PL.svg",
    value: "+48",
  },
  {
    label: "St. Pierre & Miquelon",
    code: "PM",
    emoji: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
    image: "PM.svg",
    value: "+508",
  },
  {
    label: "Pitcairn Islands",
    code: "PN",
    emoji: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
    image: "PN.svg",
    value: "+872",
  },
  {
    label: "Puerto Rico",
    code: "PR",
    emoji: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
    image: "PR.svg",
    value: "+1939",
  },
  {
    label: "Palestinian Territories",
    code: "PS",
    emoji: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
    image: "PS.svg",
    value: "+970",
  },
  {
    label: "Portugal",
    code: "PT",
    emoji: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
    image: "PT.svg",
    value: "+351",
  },
  {
    label: "Palau",
    code: "PW",
    emoji: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
    image: "PW.svg",
    value: "+680",
  },
  {
    label: "Paraguay",
    code: "PY",
    emoji: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    image: "PY.svg",
    value: "+595",
  },
  {
    label: "Qatar",
    code: "QA",
    emoji: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
    image: "QA.svg",
    value: "+974",
  },
  {
    label: "Réunion",
    code: "RE",
    emoji: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
    image: "RE.svg",
    value: "+262",
  },
  {
    label: "Romania",
    code: "RO",
    emoji: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
    image: "RO.svg",
    value: "+40",
  },
  {
    label: "Serbia",
    code: "RS",
    emoji: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
    image: "RS.svg",
    value: "+381",
  },
  {
    label: "Russia",
    code: "RU",
    emoji: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
    image: "RU.svg",
    value: "+7",
  },
  {
    label: "Rwanda",
    code: "RW",
    emoji: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
    image: "RW.svg",
    value: "+250",
  },
  {
    label: "Saudi Arabia",
    code: "SA",
    emoji: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
    image: "SA.svg",
    value: "+966",
  },
  {
    label: "Solomon Islands",
    code: "SB",
    emoji: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
    image: "SB.svg",
    value: "+677",
  },
  {
    label: "Seychelles",
    code: "SC",
    emoji: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
    image: "SC.svg",
    value: "+248",
  },
  {
    label: "Sudan",
    code: "SD",
    emoji: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
    image: "SD.svg",
    value: "+249",
  },
  {
    label: "Sweden",
    code: "SE",
    emoji: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
    image: "SE.svg",
    value: "+46",
  },
  {
    label: "Singapore",
    code: "SG",
    emoji: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
    image: "SG.svg",
    value: "+65",
  },
  {
    label: "St. Helena",
    code: "SH",
    emoji: "🇸🇭",
    unicode: "U+1F1F8 U+1F1ED",
    image: "SH.svg",
    value: "+290",
  },
  {
    label: "Slovenia",
    code: "SI",
    emoji: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
    image: "SI.svg",
    value: "+386",
  },
  {
    label: "Svalbard & Jan Mayen",
    code: "SJ",
    emoji: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
    image: "SJ.svg",
    value: "+47",
  },
  {
    label: "Slovakia",
    code: "SK",
    emoji: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
    image: "SK.svg",
    value: "+421",
  },
  {
    label: "Sierra Leone",
    code: "SL",
    emoji: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
    image: "SL.svg",
    value: "+232",
  },
  {
    label: "San Marino",
    code: "SM",
    emoji: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
    image: "SM.svg",
    value: "+378",
  },
  {
    label: "Senegal",
    code: "SN",
    emoji: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
    image: "SN.svg",
    value: "+221",
  },
  {
    label: "Somalia",
    code: "SO",
    emoji: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
    image: "SO.svg",
    value: "+252",
  },
  {
    label: "Surilabel",
    code: "SR",
    emoji: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
    image: "SR.svg",
    value: "+597",
  },
  {
    label: "South Sudan",
    code: "SS",
    emoji: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
    image: "SS.svg",
    value: "+211",
  },
  {
    label: "São Tomé & Príncipe",
    code: "ST",
    emoji: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
    image: "ST.svg",
    value: "+239",
  },
  {
    label: "El Salvador",
    code: "SV",
    emoji: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    image: "SV.svg",
    value: "+503",
  },
  {
    label: "Syria",
    code: "SY",
    emoji: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
    image: "SY.svg",
    value: "+963",
  },
  {
    label: "Eswatini",
    code: "SZ",
    emoji: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
    image: "SZ.svg",
    value: "+268",
  },
  {
    label: "Turks & Caicos Islands",
    code: "TC",
    emoji: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
    image: "TC.svg",
    value: "+1649",
  },
  {
    label: "Chad",
    code: "TD",
    emoji: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
    image: "TD.svg",
    value: "+235",
  },
  {
    label: "Togo",
    code: "TG",
    emoji: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
    image: "TG.svg",
    value: "+228",
  },
  {
    label: "Thailand",
    code: "TH",
    emoji: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
    image: "TH.svg",
    value: "+66",
  },
  {
    label: "Tajikistan",
    code: "TJ",
    emoji: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
    image: "TJ.svg",
    value: "+992",
  },
  {
    label: "Tokelau",
    code: "TK",
    emoji: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
    image: "TK.svg",
    value: "+690",
  },
  {
    label: "Timor-Leste",
    code: "TL",
    emoji: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
    image: "TL.svg",
    value: "+670",
  },
  {
    label: "Turkmenistan",
    code: "TM",
    emoji: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
    image: "TM.svg",
    value: "+993",
  },
  {
    label: "Tunisia",
    code: "TN",
    emoji: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
    image: "TN.svg",
    value: "+216",
  },
  {
    label: "Tonga",
    code: "TO",
    emoji: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
    image: "TO.svg",
    value: "+676",
  },
  {
    label: "Turkey",
    code: "TR",
    emoji: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
    image: "TR.svg",
    value: "+90",
  },
  {
    label: "Trinidad & Tobago",
    code: "TT",
    emoji: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
    image: "TT.svg",
    value: "+1868",
  },
  {
    label: "Tuvalu",
    code: "TV",
    emoji: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
    image: "TV.svg",
    value: "+688",
  },
  {
    label: "Taiwan",
    code: "TW",
    emoji: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
    image: "TW.svg",
    value: "+886",
  },
  {
    label: "Tanzania",
    code: "TZ",
    emoji: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
    image: "TZ.svg",
    value: "+255",
  },
  {
    label: "Ukraine",
    code: "UA",
    emoji: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
    image: "UA.svg",
    value: "+380",
  },
  {
    label: "Uganda",
    code: "UG",
    emoji: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
    image: "UG.svg",
    value: "+256",
  },
  {
    label: "United States",
    code: "US",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    image: "US.svg",
    value: "+1",
  },
  {
    label: "Uruguay",
    code: "UY",
    emoji: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    image: "UY.svg",
    value: "+598",
  },
  {
    label: "Uzbekistan",
    code: "UZ",
    emoji: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
    image: "UZ.svg",
    value: "+998",
  },
  {
    label: "Vatican City",
    code: "VA",
    emoji: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
    image: "VA.svg",
    value: "+379",
  },
  {
    label: "St. Vincent & Grenadines",
    code: "VC",
    emoji: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
    image: "VC.svg",
    value: "+1784",
  },
  {
    label: "Venezuela",
    code: "VE",
    emoji: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    image: "VE.svg",
    value: "+58",
  },
  {
    label: "British Virgin Islands",
    code: "VG",
    emoji: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
    image: "VG.svg",
    value: "+1284",
  },
  {
    label: "U.S. Virgin Islands",
    code: "VI",
    emoji: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
    image: "VI.svg",
    value: "+1340",
  },
  {
    label: "Vietnam",
    code: "VN",
    emoji: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
    image: "VN.svg",
    value: "+84",
  },
  {
    label: "Vanuatu",
    code: "VU",
    emoji: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
    image: "VU.svg",
    value: "+678",
  },
  {
    label: "Wallis & Futuna",
    code: "WF",
    emoji: "🇼🇫",
    unicode: "U+1F1FC U+1F1EB",
    image: "WF.svg",
    value: "+681",
  },
  {
    label: "Samoa",
    code: "WS",
    emoji: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
    image: "WS.svg",
    value: "+685",
  },
  {
    label: "Yemen",
    code: "YE",
    emoji: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
    image: "YE.svg",
    value: "+967",
  },
  {
    label: "Mayotte",
    code: "YT",
    emoji: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
    image: "YT.svg",
    value: "+262",
  },
  {
    label: "South Africa",
    code: "ZA",
    emoji: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
    image: "ZA.svg",
    value: "+27",
  },
  {
    label: "Zambia",
    code: "ZM",
    emoji: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
    image: "ZM.svg",
    value: "+260",
  },
  {
    label: "Zimbabwe",
    code: "ZW",
    emoji: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
    image: "ZW.svg",
    value: "+263",
  },
];
