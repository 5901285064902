import React from "react";
import { Layout, theme } from "antd";
import { CustomSider } from "../genericComponents/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "../genericComponents/CustomHeader";
import NavBar from "../genericComponents/CustomNav";
const { Header, Content, Footer } = Layout;

export const SearchByProfileIDPage = () => {
  const navigate = useNavigate();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content
          style={{
            margin: "24px 16px 0",
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          ></div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};
