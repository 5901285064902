import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  familyStatus: null,
  familyValues: null,
  familyType: null,
  familyIncome: null,
  fatherOccupation: null,
  motherOccupation: null,
  brothers: null,
  marriedBrothers: null,
  sisters: null,
  marriedSisters: null,
  gothra: null,
  livingWith: null,
  familyBased: null,
};
export const familyDetailSlice = createSlice({
  name: "familyData",
  initialState,
  reducers: {
    updateFamilyDetails: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
export const { updateFamilyDetails } = familyDetailSlice.actions;
export default familyDetailSlice.reducer;
