import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  minAge: null,
  maxAge: null,
  minHeight: null,
  maxHeight: null,
  minIncome: null,
  maxIncome: null,
  country: null,
  residentialStatus: null,
  maritalStatus: null,
  occupation: null,
  religion: null,
  caste: null,
  motherTongue: null,
  education: null,
  horoscope: null,
  manglik: null,
};
export const partnerPreferenceSlice = createSlice({
  name: "personalData",
  initialState,
  reducers: {
    updatePartnerPreferences: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});
export const { updatePartnerPreferences } = partnerPreferenceSlice.actions;
export default partnerPreferenceSlice.reducer;
