import React from "react";
import { Button, Carousel, Form, Input, Layout, Typography, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSider } from "../genericComponents/CustomSider";
import Password from "antd/es/input/Password";
import { CustomHeader } from "../genericComponents/CustomHeader";
import axios from "axios";
import NavBar from "../genericComponents/CustomNav";
import { patchchangePassword } from "../helperFunctions/endpoints";
import { toast } from "react-toastify";

const { Header, Content, Footer } = Layout;

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const ChangePassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Text } = Typography;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    delete values.confirmPassword;
    const response = await patchchangePassword(values);
    if (response?.code === "CH200") {
      toast.success(response?.message);
    } else {
      toast.error(response?.err);
    }
  };

  return (
    <Layout>
      <NavBar navigate={navigate} />
      <Layout>
        <CustomSider navigate={navigate} />

        <Content className="content-wrapper">
          <Text className="midtitle">{state?.title || "Change Password"}</Text>
          <Form
            layout="vertical"
            name="changepasswordform"
            style={{ padding: 24 }}
            form={form}
            onFinish={(values) => handleSubmit(values)}
          >
            <Form.Item
              name="current_password"
              label="Current Password"
              rules={[
                { required: true, message: "Please input your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    var re =
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                    if (!value || re.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        " At least One capital letter, one small letter one number one special character and minimum 8 characters"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="default"
                className="border-Radius-10px"
                placeholder={" Current Password"}
              />
            </Form.Item>
            <Form.Item
              name="new_password"
              label="New Password"
              rules={[
                { required: true, message: "Please input your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    var re =
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                    if (!value || re.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "At least One capital letter, one small letter one number one special character and minimum 8 characters"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="default"
                className="border-Radius-10px"
                placeholder={"New Password"}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") !== value) {
                      return Promise.reject(
                        new Error("Passwords don't match!")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                size="medium"
                className="border-Radius-10px"
                placeholder={"Confirm Password"}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="border-Radius-5px block-button-text height-100p"
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};
